import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getWebConfig } from '../../api/webConfigIndex';
import { mobile, tablet } from '../../styles/responsive';
import { sidebarAdminData, sidebarClientData, sidebarUserData } from './menuData';
import { SubMenu } from './SubMenu';


const Container = styled.div`
    display: flex;
    position : sticky;
    top : 0;
    min-width: 270px;
    height: 100vh;
    flex-direction: column;
    transition: all 0.5s ease-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow-y: auto;

    ${(props) => mobile({
    // display: props.isOpen ? 'flex' : 'none',
    transform: props.isOpen ? 'translateX(0)' : 'translateX(-100%)',
    backgroundColor: 'white',
    height: 'calc(100vh - 4rem)',
    minWidth: '0',
    maxWidth: '80%',
    zIndex: '50',
    position: 'fixed',
    top: '4rem',
    left: '0',
})};

${(props) => tablet({
    minWidth: '0',
    width: '21rem',
})};
    

`


const MenusWrapper = styled.div`
    width: 100%;

    
`

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing : border-box;
    padding : 0.5rem;
    /* border-right : 2px solid lightgrey; */
    border-bottom : 2px solid lightgrey;

    ${(props) => mobile({
    // display: props.isOpen ? 'none' : 'flex',
    display: 'none',
})};

`


const Logo = styled.img`
    object-fit : cover;
    max-width : 100%;
    max-height: 90%;

    ${(props) => mobile({
    maxWidth: '5rem',
})};
   

`
const UserType = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    color : grey;
    font-size: 0.7em;

`

export const NavMenu = () => {

    const { currentUser } = useSelector((state) => state.user)


    const location = useLocation().pathname.split('/');
    const slugLocation = location[location.length - 3]
    const firstLoc = location[1]
    const currentEventId = location[2]

    const [landingPageLogo, setLandingPageLogo] = useState('')
    const isOpen = useSelector((state) => state.mobileMenu.isOpen)


    const typeUser = () => {
        if (!currentUser) {
            return 'Not Logged In'
        }
        return currentUser?.userType === 0 ? 'Admin' : currentUser.userType === 1 ? 'Client' : 'User'
    }

    const getLogoImg = () => {
        getWebConfig(currentEventId).then((data) => {
            const filteredLogo = data.images?.filter((el) => el.imageType === 0)
            if (filteredLogo.length > 0) {
                setLandingPageLogo(filteredLogo[0].url)
            }

        })
    }

    useEffect(() => {
        if (location[1] === 'event-landing-page') {
            getLogoImg()
        }
    }, [])


    return (
        <Container isOpen={isOpen}>
            <LogoContainer isOpen={isOpen}>
                {location[1] === 'event-landing-page' && landingPageLogo ?
                    <Logo src={landingPageLogo} />
                    :
                    <Logo src="/images/company_logo.png" />
                }
            </LogoContainer>
            <UserType>{`${typeUser()}`}</UserType>
            {firstLoc === 'event-landing-page'
                ||
                // slugLocation === 'event-landing-page' || 
                firstLoc === 'auction-gallery'
                ||
                firstLoc === 'auction-item'

                ?
                <MenusWrapper>
                    {sidebarUserData().map((item, index) => {
                        return <SubMenu


                            item={item} key={index} />;
                    })}
                </MenusWrapper> :


                currentUser?.userType === 0 ?
                    <MenusWrapper>
                        {sidebarAdminData().map((item, index) => {
                            return <SubMenu item={item} key={index} />;
                        })}
                    </MenusWrapper>
                    :
                    currentUser?.userType === 1 ?
                        <MenusWrapper>
                            {sidebarClientData().map((item, index) => {
                                return <SubMenu item={item} key={index} />;
                            })}
                        </MenusWrapper>
                        :
                        <MenusWrapper>
                            {sidebarUserData().map((item, index) => {
                                return <SubMenu item={item} key={index} />;
                            })}
                        </MenusWrapper>

            }

        </Container>
    );
}

