import { css } from 'styled-components'

export const mobile = (props) => {
  return css`
  @media only screen and (max-width:600px) {
    ${props}
  }
  `;
};

export const tablet = (props) => {
  return css`
  @media only screen and (min-width:601px) {
    ${props}
  }
  `;
};

export const mdTablet = (props) => {
  return css`
  @media only screen and (min-width:826px) {
    ${props}
  }
  `;
};

export const smDesktop = (props) => {
  return css`
  @media only screen and (min-width:1024px) {
    ${props}
  }
  `;
};

export const lgDesktop = (props) => {
  return css`
  @media only screen and (min-width:1337px) {
    ${props}
  }
  `;
};