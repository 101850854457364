import styled from 'styled-components';
import { mdTablet, mobile, tablet } from '../styles/responsive';


const WidgetContainer = styled.div`
    width :${(props) => props.widgetType === "landingPage" ? "95%" :
        props.widgetType === "fullWidth" ? "100%"
            : "90%"};
    padding: 0 2rem;
    background : white;
    box-sizing: border-box;
    /* border-radius : 1em; */
    border-radius : 8px;
    margin-bottom : 3rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* margin-top: 1rem; */
`

const WidgetTitleCont = styled.div`
    color : ${props => props.primaryColour || (({ theme: { colors } }) => colors.primary)};
    /* background-color: #755c4e; */
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    font-size : 2rem;
    /* font-weight : 800; */
    font-weight : 700;
    border-bottom : 2px solid lightgrey;
    ${(props) => mobile({
    fontSize: '1.5rem',
    flexDirection: 'column'
})};

${(props) => tablet({
    fontSize: '1.5rem',
    flexDirection: 'column'
})};

${(props) => mdTablet({
    fontSize: '2rem',
    flexDirection: 'row'
})};

`
const SaveBtn = styled.button`
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : 8px;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }

    ${(props) => mobile({
    fontSize: '.8rem',
    marginTop: '1rem'
})};

${(props) => tablet({
    marginTop: '1rem'
})};

${(props) => mdTablet({
    fontSize: '1rem',
    marginTop: '0'
})};

`

export const Widget = ({
    title,
    widgetType,
    content,
    saveOption,
    createOption,
    primaryColour
}) => {

    return (
        <WidgetContainer widgetType={widgetType} >
            <WidgetTitleCont primaryColour={primaryColour} >
                {title}
                {saveOption &&
                    <SaveBtn
                        type="submit"
                    >Save</SaveBtn>}

                {createOption &&
                    <SaveBtn
                        type="submit"
                    >Create</SaveBtn>}

            </WidgetTitleCont>
            {content}
        </WidgetContainer>

    );
}