import { differenceInSeconds } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { lgDesktop, mobile, smDesktop, tablet } from "../styles/responsive";

import { AccessTime } from '@mui/icons-material/';

const Wrapper = styled.div`
  align-items: center;
  width : 100%;
    display: grid;
    gap : 0.5rem;
    grid-template-columns: repeat(4, 1fr);

    ${(props) => mobile({
  gridTemplateColumns: '1fr 1fr',
})};

${(props) => tablet({
  gridTemplateColumns: 'repeat(2, 1fr)',
})};

${(props) => smDesktop({
  gridTemplateColumns: 'repeat(2, 1fr)',
})};

    ${(props) => lgDesktop({
  gridTemplateColumns: 'repeat(4, 1fr)',
})};


`
const DigitsContainer = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  border: ${({ theme: { colors } }) => `1px solid ${colors.lightGray}`};
  border-radius: 4px;
  ${(props) => smDesktop({
  padding: '.5rem',
})};

`
const DigitsTitle = styled.span`
  font-weight: 800;
  color :  ${({ theme: { colors } }) => colors.darkText};

`
const Digits = styled.span`
font-size: 1.5rem;
font-weight: 300;
color :  ${({ theme: { colors } }) => colors.darkText};
  
`
const TimeText = styled.span`
color: ${({ theme: { colors } }) => colors.darkText};
  
`
const SallTimerContainer = styled.div`
  display: flex;
  align-items: center;
  
`

export const Timer = ({ deadline, timerType }) => {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const diffInSeconds = differenceInSeconds(deadline, currentTime);

  const getCoundown = () => {
    if (diffInSeconds <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
    );
    const seconds =
      diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const countdown = useMemo(getCoundown, [currentTime]);

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);
  }, []);

  return (
    <>
      {
        timerType === 'big' ?
          <Wrapper>
            <DigitsContainer>
              <DigitsTitle>Days</DigitsTitle>
              <Digits>{countdown.days}</Digits>
            </DigitsContainer>
            <DigitsContainer>
              <DigitsTitle>Hours</DigitsTitle>
              <Digits>{countdown.hours}</Digits>

            </DigitsContainer>
            <DigitsContainer>
              <DigitsTitle>Minutes</DigitsTitle>
              <Digits>{countdown.minutes}</Digits>

            </DigitsContainer>
            <DigitsContainer>
              <DigitsTitle>Seconds</DigitsTitle>
              <Digits>{countdown.seconds}</Digits>

            </DigitsContainer>
          </Wrapper>

          :
          <SallTimerContainer>
            <AccessTime style={{ fontSize: '1.1rem', marginRight: '0.3rem' }} />
            <TimeText>{`${countdown.days}d \xa0`}</TimeText>
            <TimeText>{`${countdown.hours}h \xa0`}</TimeText>
            <TimeText>{`${countdown.minutes}m \xa0`}</TimeText>
            <TimeText>{`${countdown.seconds}s `}</TimeText>
          </SallTimerContainer>

      }
    </>
  );
};