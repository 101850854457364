import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logOut } from '../redux/userSlice';
import { lgDesktop, mobile, smDesktop, tablet } from '../styles/responsive';
import { PageContainer } from "../styles/ThemeStyles";


const Wrapper = styled.div`
    max-width : 90%;
    min-width: 50%;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadow};
    box-sizing: border-box;
    padding: 5rem 6rem ;
    border-radius: 2em;

    ${(props) => mobile({
    minWidth: '50%',
    padding: '2rem 3rem'

})};

${(props) => tablet({
    minWidth: '65%',
    maxWidth: '90%',
    padding: '3rem 4rem'
})};

${(props) => smDesktop({
    // minWidth: '50%',
    maxWidth: '60%',
    padding: '5rem 6rem'
})};

${(props) => lgDesktop({
    minWidth: '50%',
    maxWidth: '60%',
    padding: '5rem 6rem'
})};
`


const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing : border-box;
    padding : 1.5rem 0;
    margin-bottom: 5rem;
    /* background : #476068; */
`
const Logo = styled.img`
    object-fit : cover;
    max-width : 300px;
    /* background: #221e1e; */
`

const SucessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
`
const SuccessTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 800;
  margin: 5% 0 10% 0;
  color: ${({ theme: { colors } }) => colors.complementary};
`



export const Logout = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname.split('/')
    const currentPath = path.slice(0, 4).join('/')
    const { currentUser } = useSelector((state) => state.user);


    useEffect(() => {
        handleLogOut()
        console.log(currentPath)

        if (currentUser) {
            setTimeout(() => {
                if (currentUser.userType === 2 && path.length > 2) {
                    // navigate(location.state.homeURL)
                    navigate(currentPath)
                }
                else {
                    navigate("/login")
                }
            }, 3000)
        }

    }, [])


    const handleLogOut = () => {
        dispatch(logOut());
    }

    return (
        <PageContainer style={{ justifyContent: "center" }} >
            <Wrapper>
                <LogoContainer>
                    <Logo src="/images/company_logo.png" />
                </LogoContainer>
                <SucessDiv>
                    <SuccessTitle>Logging Out ...</SuccessTitle>
                    You will now be redirected to the <b>Login Page</b>
                </SucessDiv>

            </Wrapper>
        </PageContainer>
    );
}

