import { Chat, LiveHelp, SettingsApplications } from '@mui/icons-material/';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { Widget } from "../components/Widget";
import { ElementsContainer, PageContainer } from "../styles/ThemeStyles";


const GridContainer = styled.div`
    display: grid;
    gap : 1.8rem;
    grid-template-columns: repeat(3, 1fr);

`
const GridItem = styled.div`
    width: 100%;
    border : 2px solid ${({ theme: { colors } }) => colors.bg};
    box-shadow: ${({ theme }) => theme.boxShadow} ;
    box-sizing: border-box;
    padding: 1em;
    border-radius: 0.5rem;
    overflow: hidden;
`
const Item = styled.div`
    /* background-color: #7e6363; */
    /* width : 100% ; */
    display: flex;
    flex-direction: column;
    align-items: center;
`

const TitleItem = styled.span`
    /* align-self : center; */
    /* padding: 1rem; */
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const IdItem = styled.span`
    /* color : ${({ theme: { colors } }) => colors.bg} ; */
    color: grey;
    padding: 1rem 0;
    font-size : 1.3rem;
`

const StyledIcon = styled.div`
    color: ${({ theme: { colors } }) => colors.complementaryLight};
`



export const HelpPage = () => {

    const [helpIcons, setHelpIcons] = useState('');
    const location = useLocation().pathname.split('/');
    const { currentUser } = useSelector((state) => state.user)
    const currentEventId = location[2]
    const currentSlug = location[3]
    const placeholderImg = 'https://images.pexels.com/photos/4992652/pexels-photo-4992652.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'

    return (

        <PageContainer>

            <Widget title={"How Can We Help?"} content={
                <ElementsContainer>
                    <GridContainer>
                        <GridItem  >
                            <Item>
                                <StyledIcon>
                                    <LiveHelp style={{ fontSize: '5rem' }} />
                                </StyledIcon>
                                <IdItem>F.A.Q</IdItem>
                            </Item>
                        </GridItem>


                        <GridItem  >
                            <Item>
                                <StyledIcon>
                                    <SettingsApplications style={{ fontSize: '5rem' }} />
                                </StyledIcon>
                                <IdItem>Technical Issues </IdItem>
                            </Item>
                        </GridItem>


                        <GridItem  >
                            <Item>
                                <StyledIcon>
                                    <Chat style={{ fontSize: '5rem' }} />
                                </StyledIcon>
                                <IdItem>Live Chat Support</IdItem>
                            </Item>
                        </GridItem>


                    </GridContainer>
                </ElementsContainer>
            } >
            </Widget>

        </PageContainer>
    );
}

