import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';




const getPage = async (eventId) => {

  try {
    const res = await axios.get(`${apiURL}/api/Events/Page/${eventId}`,

    )
    console.log(res.data)

    return res.data

  } catch (err) { console.log(err) }
}

const editPage = async (pageId, pageData) => {
  const state = store.getState();

  try {
    const res = await axios.put(`${apiURL}/api/Events/Page/${pageId}`, pageData,
      {
        params: { clientId: state.user.currentUser.clientId },
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    console.log(res.data)

    return res.data

  } catch (err) { console.log(err) }
}





export { getPage, editPage };
