import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import { mobile } from '../../styles/responsive';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../redux/mobileMenuSlice';
import jwt_decode from "jwt-decode";
import { getWebConfig } from '../../api/webConfigIndex';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    position : sticky;
    top : 0;
    justify-content: space-between;
    background :  ${({ theme: { colors } }) => colors.bg};
    width: 100%;
    height: 4rem;
    z-index: ${(props) => props.isOpen ? '50' : '3'};
    transition: all 0.25s ease-in-out;
    
    ${mobile({
    position: "stycky",
    justifyContent: 'space-between'
})}

`

const ProfileContainer = styled.div`
    display : flex;
    align-items : center;
    padding : 1rem;
    color: ${({ theme: { colors } }) => colors.text};
    overflow: hidden;

`
const MenuIconContainer = styled.div`
    display : none;
    align-items : center;
    padding : 1rem;
    font-size: 2rem;
    color: ${(props) => ({ theme: { colors } }) => props.isOpen ? colors.text : colors.text};
    z-index: ${(props) => props.isOpen ? '150' : '3'};
    transition: all 0.25s ease-in-out;
    ${(props) => mobile({
    display: 'flex',
})};

`

const ProfileName = styled.span`
    font-size: 1.1rem;
    font-weight : 800;
    color : ${({ theme: { colors } }) => colors.text};
    cursor : pointer;
`

const LogoContainer = styled.div`
  display: none;
  /* max-width: 100%; */

  ${mobile({
    display: 'block',
})}

`;
const LogoImg = styled.img`
    max-height: 100%;
    max-width: 100%;
`;

export const TopBar = () => {

    const isOpen = useSelector((state) => state.mobileMenu.isOpen)
    const location = useLocation().pathname.split('/')
    const dispatch = useDispatch()
    const token = useSelector((state) => state.user?.currentUser?.token)
    const [landingPageLogo, setLandingPageLogo] = useState('')
    // const decoded = jwt_decode(token);
    const currentEventId = location[2]

    const getLogoImg = () => {
        getWebConfig(currentEventId).then((data) => {
            const filteredLogo = data?.images?.filter((el) => el.imageType === 0)
            if (filteredLogo && filteredLogo.length > 0) {
                setLandingPageLogo(filteredLogo[0].url)
            }

        })
    }

    useEffect(() => {
        if (location[1] === 'event-landing-page') {
            getLogoImg()
        }
    }, [])

    return (
        <Container
            isOpen={isOpen}
        >
            {landingPageLogo &&
                <LogoContainer>
                    <LogoImg src={landingPageLogo} />
                </LogoContainer>
            }

            <ProfileContainer>
                {token &&
                    <>
                        <ProfileName>
                            {jwt_decode(token)?.unique_name}
                        </ProfileName>
                        {/* <IconContainer>
                            <MdIcons.MdPerson />
                        </IconContainer> */}
                    </>
                }

            </ProfileContainer>
            <MenuIconContainer
                isOpen={isOpen}
                onClick={() => dispatch(toggleMenu())} >
                {isOpen ?
                    <MdIcons.MdMenuOpen />
                    :
                    <MdIcons.MdMenu />
                }
            </MenuIconContainer>
        </Container>
    );
}

