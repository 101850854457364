import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import { store } from '../../redux/store';


// const events = JSON.parse(localStorage.getItem("persist:root"))?.events;
// const currentEventId = events && JSON.parse(events).currentEventId;
// const userToken = currentUser?.token;
// const clientId = currentUser?.clientId;

const location = window.location.pathname.split('/')


export const sidebarAdminData = () => {
  const state = store.getState();


  return (

    [
      {
        title: 'HOME',
        path: '/',
        icon: <MdIcons.MdHome />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

      },
      {
        title: 'CLIENTS',
        path: '/clients',
        icon: <MdIcons.MdAssignmentInd />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

      },
      {
        title: 'EVENTS',
        path: '/events',
        icon: <MdIcons.MdEvent />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        eventSubNav: [
          {
            title: 'WEB CONFIGURATION',
            path: `/events/${state.events.currentEventId}/webconfig`,
            icon: <MdIcons.MdWeb />,
          },
          {
            title: 'AUCTION ITEMS',
            path: `/events/${state.events.currentEventId}/auction-items`,
            icon: <MdIcons.MdListAlt />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            // subSubNav: [
            //   {
            //     title: 'CREATE ITEM',
            //     path: '/create-auction-item',
            //     icon: <MdIcons.MdAddCircle />,
            //   },

            // ]
          },
          
          {
            title: 'BIDS',
            path: `/events/${state.events.currentEventId}/bids-feed`,
            icon: <MdIcons.MdListAlt />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,

          },
        ]
          },
          {
              title: 'CATALOG ITEMS',
              path: `/catalog/catalog-items`,
              icon: <MdIcons.MdListAlt />,
              iconClosed: <RiIcons.RiArrowDownSFill />,
              iconOpened: <RiIcons.RiArrowUpSFill />,

          },
      {
        title: 'PROFILE',
        path: '/profile',
        icon: <MdIcons.MdPerson />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
          {
            title: 'USER',
            path: '/profile',
            icon: <MdIcons.MdAssignmentInd />,
          },
          {
            title: 'BANK DETAILS',
            path: '/webconfig',
            icon: <MdIcons.MdAccountBalance />,
          },
          {
            title: 'RECEPIT DETAILS',
            path: '/webconfig',
            icon: <MdIcons.MdReceipt />,
          },
        ]
      },
      {
        title: 'HELP',
        path: '/help',
        icon: <MdIcons.MdHelpCenter />
      },
      {
        title: 'LOGOUT',
        path: '/logout',
        icon: <MdIcons.MdLogout />
      }
    ]
  )

}



export const sidebarClientData = () => {
  const state = store.getState();

  return [
    {
      title: 'HOME',
      path: '/',
      icon: <MdIcons.MdHome />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

    },
    {
      title: 'EVENTS',
      path: '/events',
      icon: <MdIcons.MdEvent />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      eventSubNav: [
        {
          title: 'WEB CONFIGURATION',
          path: `/events/${state.events.currentEventId}/webconfig`,
          icon: <MdIcons.MdWeb />,
        },
        {
          title: 'AUCTION ITEMS',
          path: `/events/${state.events.currentEventId}/auction-items`,
          icon: <MdIcons.MdListAlt />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,
        },
        {
          title: 'BIDS',
          path: `/events/${state.events.currentEventId}/bids-feed`,
          icon: <MdIcons.MdListAlt />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

        },
      ]
    },
    {
      title: 'PROFILE',
      path: '/profile',
      icon: <MdIcons.MdPerson />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          title: 'USER',
          path: '/profile',
          icon: <MdIcons.MdAssignmentInd />,
        },
        {
          title: 'BANK DETAILS',
          path: '/webconfig',
          icon: <MdIcons.MdAccountBalance />,
        },
        {
          title: 'RECEPIT DETAILS',
          path: '/webconfig',
          icon: <MdIcons.MdReceipt />,
        },
      ]
    },
    {
      title: 'HELP',
      path: '/help',
      icon: <MdIcons.MdHelpCenter />
    },
    {
      title: 'LOGOUT',
      path: '/logout',
      icon: <MdIcons.MdLogout />
    }
  ];
}



export const sidebarUserData = () => {

  const currentEventId = location[2]
  const slug = location[3]
  const { currentUser } = store.getState().user

  return (

    slug ?

      [
        {
          title: 'HOME',
          path: `/event-landing-page/${currentEventId}/${slug}`,
          icon: <MdIcons.MdHome />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,
        },
        {
          title: 'AUCTION ITEMS',
          path: `/event-landing-page/${currentEventId}/${slug}/auction-gallery`,
          icon: <MdIcons.MdViewList />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,
        }, 
         

        {
          title: 'HELP ',
          path: `/event-landing-page/${currentEventId}/${slug}/help`,
          icon: <MdIcons.MdHelpCenter />
        },
        {
          title: currentUser ? 'PROFILE' : 'REGISTER',
          path: currentUser ? `/event-landing-page/${currentEventId}/${slug}/profile` : `/event-landing-page/${currentEventId}/${slug}/register`,
          icon: currentUser ? <MdIcons.MdPerson /> : <MdIcons.MdAppRegistration />
        },
        {
          title: currentUser ? 'LOGOUT' : 'SIGN IN',
          path: currentUser ? `/event-landing-page/${currentEventId}/${slug}/logout` :
            `/event-landing-page/${currentEventId}/${slug}/login`,
          icon: currentUser ? <MdIcons.MdLogout /> : <MdIcons.MdLogin />
        }
      ]

      :

      // IF they are not on an event landing page
      [
        {
          title: 'HOME',
          path: `/`,
          icon: <MdIcons.MdHome />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,
        },
        {
          title: 'PROFILE',
          path: '/profile',
          icon: <MdIcons.MdPerson />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,
          subNav: [
            {
              title: 'USER',
              path: '/profile',
              icon: <MdIcons.MdAssignmentInd />,
            },
            {
              title: 'BANK DETAILS',
              path: '/webconfig',
              icon: <MdIcons.MdAccountBalance />,
            },
            {
              title: 'RECEPIT DETAILS',
              path: '/webconfig',
              icon: <MdIcons.MdReceipt />,
            },
          ]
        },
        {
          title: 'HELP ',
          path: `/event-landing-page/${currentEventId}/${slug}/help`,

          icon: <MdIcons.MdHelpCenter />
        },

        {
          title: currentUser ? 'LOGOUT' : 'SIGN IN',
          path: currentUser ? '/logout' : '/login',
          icon: currentUser ? <MdIcons.MdLogout /> : <MdIcons.MdLogin />
        },
      ]


  )

}
