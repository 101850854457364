import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


// IMAGES

const postImage = async (image) => {
  const state = store.getState();

  try {
    const res = await axios.post(`${apiURL}/api/Images/Upload`, image,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })

    console.log("POSTED IMAGE" + res.data.url)
    return res.data.url


  } catch (err) { console.log(err) }
}



export { postImage };