import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Modal } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
// import { CircularProgress, Box } from '@mui/material/';
import { getClients } from '../api/clientIndex';
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { boxStyle, ButtonsContainer, ModalBtn, ModalText, theme } from "../styles/ThemeStyles";


const EditBtn = styled.button`
    text-decoration: none;
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }

    ${(props) => mobile({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};

${(props) => tablet({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};
${(props) => smDesktop({
  fontSize: '1rem',
  padding: '0.6rem 2rem'
})};

`

const AssignedBtn = styled.button`
    text-decoration: none;
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : #d20c0c;
    color : white;
    ${(props) => mobile({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};

${(props) => tablet({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};

${(props) => smDesktop({
  fontSize: '1rem',
  padding: '0.6rem 2rem'
})};
    
`



const NewTabLink = styled.a`
    text-decoration: none;
    border : none;
    font-size : 1rem;
    padding : .5rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }

    ${(props) => mobile({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};
${(props) => tablet({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};
${(props) => smDesktop({
  fontSize: '1rem',
  padding: '0.6rem 2rem'
})};

`


const ClientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};



export const AssignClientGrid = ({ eventInfo, setEventInfo }) => {

  const [clientSelected, setClientSelected] = useState('')
  const [viewOpenModal, setViewOpenModal] = useState(false)
  const [assignOpenModal, setAssignOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const dispatch = useDispatch()

  const columns = [
    {
      field: '_id',
      headerName: '#',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Client Name',
      flex: 3,
      editable: false,
    },

    {
      field: 'view',
      headerName: 'View',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (param) => {
        return (

          <NewTabLink
            href={`/clients/${param.row._id}/view`}
            target="_blank"
          >
            View
          </NewTabLink>
        )
      }

    },
    {
      field: 'assign',
      headerName: 'Assign',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (param) => {
        return (
          <>
            {
              eventInfo?.clientId === param.row._id ?
                <AssignedBtn
                  type='button'
                >Assigned</AssignedBtn>

                :

                <EditBtn
                  type='button'
                  onClick={() => { setClientSelected(param.row._id); setAssignOpenModal(true) }}
                >Assign</EditBtn>
            }
          </>
        )
      }

    },


  ];

  const [rows, setRows] = useState([]);
  const [rowsInfo, setRowsInfo] = useState([]);
  //redux
  // const events = useSelector((state) => state.events)
  const [clients, setClients] = useState('');

  useEffect(() => {

    getClients().then((data) => setClients(data))
    console.log(clients)

  }, [])

  useEffect(() => {
    if (clients) {
      const info = clients?.map((el) => ({ _id: el.id, name: el.name }))
      setRows(info)
      setRowsInfo(info)
    }
  }, [clients])

  const [searchText, setSearchText] = useState('');

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rowsInfo.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    // const filteredRows = rowsInfo.filter((d) => d.name.toLowerCase().includes(searchValue));
    setRows(filteredRows);
  };

  const handleModalClose = () => {
    setViewOpenModal(false)
    setAssignOpenModal(false)
    setModalMessage('')
    // window.location.reload(false)
  }

  const handleClientAssignment = () => {
    setEventInfo((prev) => ({
      ...prev,
      clientId: clientSelected
    }))
    setAssignOpenModal(false)
  }

  return (
    <>

      <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {clients ?
          <DataGrid
            rows={rows}
            getRowId={(row) => row?._id}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnCliclk={true}
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: 'assign', sort: 'desc' }],
              },
            }}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
            sx={{
              color: theme.colors.text,
              border: 'none',


              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'transparent',
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: 'transparent',
              },
              '& .MuiDataGrid-row.Mui-selected:hover': {
                backgroundColor: 'transparent',
              },

              '& .MuiDataGrid-columnHeader': {

              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '700',
              },
            }}
          />
          :
          <CircularProgress size={80} />
        }
      </div>

      <Modal
        open={assignOpenModal}
        onClose={() => handleModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            modalMessage ?
              <>
                <ModalText>
                  {modalMessage}
                </ModalText>
                <ButtonsContainer style={{ justifyContent: 'center' }} >
                  <ModalBtn
                    color='complementary'
                    onClick={() => handleModalClose()}
                  >Close</ModalBtn>
                </ButtonsContainer>
              </>
              :
              <>
                <ClientInfoContainer>
                  Are you sure you want to assign this client?
                </ClientInfoContainer>
                <ButtonsContainer>
                  <ModalBtn
                    onClick={() => handleClientAssignment()}
                  >Assign</ModalBtn>
                  <ModalBtn
                    color='complementary'
                    onClick={() => handleModalClose()}
                  >Close</ModalBtn>
                </ButtonsContainer>
              </>
          }
        </Box>
      </Modal>

    </>
  )
}
