import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios';
import { apiURL } from '../../api';
import { store } from '../../redux/store';

export const PaymentForm = () => {
    const elements = useElements();
    const stripe = useStripe();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardElement);
        console.log('card', cardElement)
        console.log('stripe', stripe)
    }
    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="card-element">Card</label>
            <CardElement id="card-element" />
            <button>Complete</button>
        </form>
    )
}



const confirmPurchase = async (bidId) => {
    const state = store.getState();

    try {
        const res = await axios.post(`${apiURL}/api/Stripe/Purchase/${bidId}`,
            {
                headers: { authorization: `Bearer ${state.user.currentUser.token}` }
            })
        return res.data

    } catch (err) { console.log(err) }
}



export { confirmPurchase }