import { Box, Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { getAuctionItemBid } from '../api/bidIndex';
import { postSendPaymentLink, postTakePayment } from '../api/stripeIndex';
import { getUser } from '../api/usersIndex';
import { mobile, tablet } from '../styles/responsive';
import { theme } from '../styles/ThemeStyles';


const EditBtn = styled.button`
    width: 90%;
    border : none;
    font-size : 1rem;
    padding : .6rem 1rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`


const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 7,
  borderRadius: '10px'
};

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const ModalBtn = styled.button`
  width: 45%;
  margin : 3rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border : none;
  font-size : 1.3rem;
  padding : 1rem 3rem;
  border-radius : 9999px;
  box-sizing : border-box;
  background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementary : colors.primary};
  color : white;
  cursor: pointer;
  &:hover {
    background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementaryLight : colors.primaryLight};
  }
`
const ModalText = styled.span`
  font-size: 1.5rem;
  color :  ${({ theme: { colors } }) => colors.darkText};
  font-weight : 500;
`
const GridContainer = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
    ${(props) => mobile({
  gridTemplateColumns: '1fr',
})};

${(props) => tablet({
  gridTemplateColumns: '1fr',
})};
`
const Item = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr 1.5fr;
    ${(props) => mobile({
  gridTemplateColumns: '1fr 1fr',
})};
`
const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`



export const BidHistoryGrid = () => {

  const columns = [
    {
      field: '_id',
      headerName: '#',
      flex: 1,
    },
    {
      field: 'bidderName',
      headerName: 'Bidder Name',
      flex: 3,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 2,
      editable: false,
    },
    {
      field: 'bidDate',
      headerName: 'Bid Date',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 5,
      // sortable: true,
      editable: false,
    },
    {
      field: 'bidStatus',
      headerName: 'Status',
      flex: 2,
      editable: false,
    },
    {
      field: 'SendPaymentLink',
      headerName: 'Payment Link',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (param) => {
        const onClick = (e) => {
          setOpenLinkModal(true);
          setSelectedBidId(param.id);
        }
        const bidStatus = param.row.bidStatus;

        return (
          <div>
            {bidStatus != 'Paid' ?
              <EditBtn
                type='button'
                onClick={onClick}
              >
                <MdIcons.MdSendToMobile /></EditBtn>
              : <p>-</p>
            }
          </div>
        )
      }

    },
    {
      field: 'takePayment',
      headerName: 'Take Payment',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (param) => {
        const onClick = (e) => {
          setOpenModal(true);
          setStripeKey(uuidv4());
          setSelectedBidId(param.id);
        }
        const bidStatus = param.row.bidStatus;

        return (
          <div>
            {bidStatus != 'Paid' ?
              <EditBtn
                type='button'
                onClick={onClick}
              >
                <FaIcons.FaMoneyCheck /></EditBtn>
              : <p>-</p>
            }
          </div>
        )
      }

    },
  ];

  const [rows, setRows] = useState([]);
  const [itemBids, setItemBids] = useState('');
  const location = useLocation().pathname.split('/');
  const auctionItemId = location[location.length - 1]
  const [stripeKey, setStripeKey] = useState('');
  const [openModal, setOpenModal] = useState(false)
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [selectedBidId, setSelectedBidId] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const [openUserInfoModal, setOpenUserInfoModal] = useState(false)
  const [userInfo, setUserInfo] = useState('')

  useEffect(() => {

    getAuctionItemBid(auctionItemId).then((data) => setItemBids(data))
    console.log(itemBids)

  }, [])
  function getStatus(status) {
    switch (status) {
      case 0:
        return "Bid"
      case 1:
        return "Payment Failed"
      case 2:
        return "Paid"
      case 3:
        return "Processing"
      case 4:
        return "Link Sent"
      default:
    }
  }

  useEffect(() => {
    if (itemBids) {
      setRows(
        itemBids?.map((el) => ({
          _id: el.id,
          bidderName: el.bidderName,
          amount: `£ ${el.amount}`,
          bidDate: format(parseISO(el.bidDate), 'dd/MM/yyyy hh:mm a'),
          bidStatus: getStatus(el.bidStatus),
          userId: el.userId,
        }))
      )
    }
  }, [itemBids])

  const handleProcessPayment = async () => {
    setLoading(true)
    const res = await postTakePayment(selectedBidId, stripeKey)
    if (!res) {
      setModalMessage('Something went wrong...')
    }
    else if (res.response?.data) {
      setModalMessage(res.response.data)
    }
    else {
      setModalMessage("Bid has been processed successfuly!")
    }
    setLoading(false)


  }
  const handlePaymentLink = async () => {
    setLoading(true)
    const res = await postSendPaymentLink(selectedBidId)
    if (!res) {
      setModalMessage('Something went wrong...')
    }
    else if (res.response?.data) {
      setModalMessage(res.response.data)
    }
    else {
      setModalMessage("Payment Link has been sent!")
    }
    setLoading(false)


  }

  const handleModalClose = () => {
    setOpenModal(false)
    setModalMessage('')
    window.location.reload(false)
  }
  const handleLinkModalClose = () => {
    setOpenLinkModal(false)
    setModalMessage('')
    window.location.reload(false)
  }

  const handleOnCellClick = async (param) => {
    if (param.field === 'bidderName') {
      setLoading(true)
      setOpenUserInfoModal(true)
      const user = await getUser(param.row.userId)
      setUserInfo(user)
      setLoading(false)
    }

  }

  return (
    <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {itemBids ?
        <DataGrid
          rows={rows}
          getRowId={(row) => row?._id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnCliclk={true}
          disableColumnMenu
          onCellClick={handleOnCellClick}
          sortModel={[
            {
              field: 'amount',
              sort: 'desc',
            },
          ]}
          sx={{
            color: theme.colors.text,
            border: 'none',


            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
              cursor: 'pointer',
              background: '#f0f0f0'
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'transparent',
              background: '#f0f0f0'
            },

            '& .MuiDataGrid-columnHeader': {

            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
            },
            '& .MuiDataGrid-cell:hover': {
              // backgroundColor: 'aliceblue',

            }
          }}
        />
        :
        <CircularProgress size={80} />
      }


      <Modal
        open={openModal}
        onClose={() => handleModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            modalMessage ?
              <>
                <ModalText>
                  {modalMessage}
                </ModalText>
                <ButtonsContainer
                  style={{ justifyContent: 'center' }}
                >
                  <ModalBtn
                    color='complementary'
                    onClick={() => handleModalClose()}
                  >Close</ModalBtn>
                </ButtonsContainer>

              </>
              :
              <>
                <ModalText>
                  Are you sure you want to process this bid payment?
                </ModalText>
                <ButtonsContainer>
                  <ModalBtn
                    onClick={() => handleProcessPayment()}
                  >Yes</ModalBtn>
                  <ModalBtn
                    color='complementary'
                    onClick={() => setOpenModal(false)}
                  >No</ModalBtn>
                </ButtonsContainer>
              </>
          }
        </Box>
      </Modal>


      <Modal
        open={openLinkModal}
        onClose={() => handleLinkModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            modalMessage ?
              <>
                <ModalText>
                  {modalMessage}
                </ModalText>
                <ButtonsContainer
                  style={{ justifyContent: 'center' }}
                >
                  <ModalBtn
                    color='complementary'
                    onClick={() => handleLinkModalClose()}
                  >Close</ModalBtn>
                </ButtonsContainer>

              </>
              :

              <>
                <ModalText>
                  Are you sure you want to send a payment link?

                </ModalText>
                <ButtonsContainer>
                  <ModalBtn
                    onClick={() => handlePaymentLink()}
                  >Yes</ModalBtn>
                  <ModalBtn
                    color='complementary'
                    onClick={() => setOpenLinkModal(false)}
                  >No</ModalBtn>
                </ButtonsContainer>
              </>
          }

        </Box>
      </Modal>


      {/* USER INFO MODAL */}
      <Modal
        open={openUserInfoModal}
        onClose={() => { setOpenUserInfoModal(false); setModalMessage('') }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            userInfo &&
            <>
              <ModalText>

                <GridContainer>
                  <Item>
                    <TitleInput> First Name:</TitleInput>
                    <TitleInput>{userInfo?.firstName}</TitleInput>
                  </Item>
                  <Item>
                    <TitleInput>Last Name :</TitleInput>
                    <TitleInput>{userInfo?.lastName}</TitleInput>

                  </Item>
                  <Item>
                    <TitleInput>Email :</TitleInput>
                    <TitleInput>{userInfo?.email}</TitleInput>
                  </Item>
                  <Item>
                    <TitleInput>Mobile :</TitleInput>
                    <TitleInput>{userInfo?.mobile}</TitleInput>
                  </Item>
                  <Item>
                    <TitleInput>Address 1 :</TitleInput>
                    <TitleInput>{userInfo?.address?.address1}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>Address 2 :</TitleInput>
                    <TitleInput>{userInfo?.address?.address2}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>City:</TitleInput>
                    <TitleInput>{userInfo?.address?.city}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>County:</TitleInput>
                    <TitleInput>{userInfo?.address?.county}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>Postcode:</TitleInput>
                    <TitleInput>{userInfo?.address?.postcode}</TitleInput>
                  </Item>

                </GridContainer>
              </ModalText>

              <ButtonsContainer style={{ justifyContent: 'center' }} >
                <ModalBtn
                  single
                  color='complementary'
                  onClick={() => { setOpenUserInfoModal(false); setModalMessage('') }}
                >Close</ModalBtn>
              </ButtonsContainer>
            </>
          }
        </Box>
      </Modal>
    </div>
  )
}
