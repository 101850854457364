import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


// CLIENT

const getClients = async () => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Client`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) { console.log(err) }
}

const getClient = async (clientId) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Client/${clientId}`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) { console.log(err) }
}

const createClient = async (clientInfo) => {
  const state = store.getState();

  try {
    const res = await axios.post(`${apiURL}/api/Client/`, clientInfo,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })

    return res.data

  } catch (err) {
    console.log(err)
    return err
  }
}

export { getClients, getClient, createClient };
