import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentEventId: '',
  events: [],
  currentClientId: '',
}

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setCurrentEventId: (state, action) => {
      state.currentEventId = action.payload
    },
    setEvents: (state, action) => {
      state.events = action.payload
    },
    setCurrentClientId: (state, action) => {
      state.currentClientId = action.payload
    },

  },

})

// Action creators are generated for each case reducer function
export const { setEvents, setCurrentEventId, setCurrentClientId } = eventsSlice.actions

export default eventsSlice.reducer