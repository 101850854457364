import { Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import * as Yup from 'yup';
import { getUserDetails, updateUser } from '../api/usersIndex';
import { UserBidHistoryGrid } from '../components/UserBidHistoryGrid';
import { Widget } from "../components/Widget";
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { ElementsContainer, PageContainer } from "../styles/ThemeStyles";


const GridContainer = styled.div`
    display: grid;
    gap : 2rem;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};
${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => smDesktop({
    gridTemplateColumns: 'repeat(2, 1fr)',
})};

`
const GridHalf = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
`
const Item = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 0.7fr 1fr;
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};
`

const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const SectionTitle = styled.div`
    font-size: 1.2rem;
    box-sizing: border-box;
    padding : 1rem 0 1.5rem 0;
    font-weight: 800;
    color : ${({ theme: { colors } }) => colors.text};
`
const UnderlinedSectionTitle = styled.div`
    font-size: 1.2rem;
    box-sizing: border-box;
    padding : 3rem 0 1.5rem 0;
    font-weight: 800;
    color : ${({ theme: { colors } }) => colors.text};
    border-bottom : 2px solid lightgrey;
    margin-bottom: 2rem;

`

const PasswordReqContainer = styled.div`
    display: flex;
    flex-direction: column ;
    color : ${({ theme: { colors } }) => colors.text};
    
`
const PassReqSpan = styled.span`
    color : ${({ theme: { colors } }) => colors.text};
    padding : .1em 0;

`
const DarkerText = styled.span`
    font-weight : 800;
    color : ${({ theme: { colors } }) => colors.darkText};

`

const TextInputError = styled.div`
    color : red;
`
const InputContainer = styled.div`
    /* background: green; */
    align-self : center;
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`
const SaveBtn = styled.button`
    display: none;
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : 99999px;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
    ${(props) => mobile({
    display: 'block',
})};


`

//FORM INPUTS

const FormTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <InputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <TextInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </InputContainer>
    );
};



export const Profile = () => {

    let date = new Date()
    let day = date.getDate();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    let hour = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let seconds = String(date.getSeconds()).padStart(2, "0");
    let milliseconds = date.getMilliseconds();


    let fullDate = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}.${milliseconds}Z`;

    const schema = {
        email: "",
        firstName: "",
        lastName: "",
        mobile: "",
        password: "",
        address: {
            address1: "",
            address2: "",
            city: "",
            county: "",
            postcode: "",
        }
    }

    const [userInfo, setUserInfo] = useState(schema)
    const [mobile, setMobile] = useState("")
    const location = useLocation().pathname.split('/');
    const eventId = location[location.length - 1]
    const [disableBtn, setDisableBtn] = useState(false)
    const [warning, setWarning] = useState('')

    // const handleChange = (e) => {
    //     // Using 1 handle change for multiple inputs, the name field of each input has to be the same as the store object.
    //     setUserInfo((prev) => ({
    //         ...prev,
    //         [e.target.name]: e.target.value
    //     }))
    // }

    useEffect(() => {

        getUserDetails().then((data) => { setUserInfo(data); console.log(data) })

    }, [])




    return (
        <Formik
            enableReinitialize
            initialValues={{
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                email: userInfo?.email,
                mobile: userInfo?.mobile,
                password: userInfo?.password,
                confirmPassword: userInfo?.confirmPassword,
                address: {
                    address1: userInfo?.address?.address1,
                    address2: userInfo?.address?.address2,
                    city: userInfo?.address?.city,
                    county: userInfo?.address?.county,
                    postcode: userInfo?.address?.postcode
                }

            }}
            validationSchema={Yup.object({
                firstName: Yup.string()
                    .max(100, 'Must be 50 characters or less')
                    .required('Required'),
                lastName: Yup.string()
                    .max(100, 'Must be 50 characters or less')
                    .required('Required'),
                email: Yup.string()
                    .email()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                mobile: Yup.number()
                    // .max(11, 'Must be 10 characters or less')
                    .required('Required'),
                password: Yup.string()
                    .max(50, 'Must be 50 characters or less'),
                confirmPassword: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .oneOf([Yup.ref('password')], 'Passwords do not match'),
                address: Yup.object({
                    address1: Yup.string()
                        .max(300, 'Must be 300 characters or less')
                        .required('Required'),
                    address2: Yup.string()
                        .max(300, 'Must be 300 characters or less'),
                    city: Yup.string()
                        .max(300, 'Must be 300 characters or less'),
                    // .required('Required'),
                    county: Yup.string()
                        .max(300, 'Must be 300 characters or less'),
                    // .required('Required'),
                    postcode: Yup.string()
                        .max(300, 'Must be 300 characters or less'),
                    // .required('Required'),
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
                console.log("values are " + values)
                console.log("SUBMITTING")
                if (disableBtn) {
                    return
                }
                else {
                    console.log("SUBMITTING")
                    // handleSubmit()
                    const res = updateUser(values)
                    console.log(res)
                }
            }}
        >

            <Form style={{ width: "100%" }} >
                <PageContainer>
                    {/* <TopBar /> */}

                    <Widget title={"Your Profile"} saveSubmitOption type="registration" content={
                        <ElementsContainer>
                            <SectionTitle>PERSONAL DETAILS</SectionTitle>
                            <GridContainer>
                                <Item>
                                    <TitleInput>First Name:</TitleInput>
                                    <FormTextInput
                                        name="firstName"
                                    />
                                </Item>

                                <Item>
                                    <TitleInput>Last Name:</TitleInput>
                                    <FormTextInput
                                        name="lastName"
                                    />
                                </Item>
                                <Item>
                                    <TitleInput>Email:</TitleInput>
                                    <FormTextInput
                                        name="email"
                                    />
                                </Item>
                                <Item>
                                    <TitleInput>Mobile:</TitleInput>
                                    <FormTextInput
                                        name="mobile"
                                    />
                                </Item>

                            </GridContainer>


                            {/*<UnderlinedSectionTitle>CHANGE PASSWORD</UnderlinedSectionTitle>*/}
                            {/*<GridContainer>*/}
                            {/*    <GridHalf>*/}

                            {/*        <Item>*/}
                            {/*            <TitleInput>Old Password:</TitleInput>*/}
                            {/*            <FormTextInput*/}
                            {/*                name="oldPassword"*/}
                            {/*            />*/}
                            {/*        </Item>*/}
                            {/*        <Item>*/}
                            {/*            <TitleInput>New Password:</TitleInput>*/}
                            {/*            <FormTextInput*/}
                            {/*                name="newPassword"*/}
                            {/*            />*/}
                            {/*        </Item>*/}

                            {/*    </GridHalf>*/}
                            {/*    <GridHalf>*/}
                            {/*        <PasswordReqContainer>*/}
                            {/*            <PassReqSpan style={{ marginBottom: "1rem" }} >Your new password must include:</PassReqSpan>*/}

                            {/*            <PassReqSpan>✓ At Least <DarkerText>12</DarkerText> Characters</PassReqSpan>*/}
                            {/*            <PassReqSpan>✓ At Least <DarkerText>1 lowercase letter</DarkerText></PassReqSpan>*/}
                            {/*            <PassReqSpan>✓ At Least <DarkerText>1 capital letter</DarkerText></PassReqSpan>*/}
                            {/*            <PassReqSpan>✓ At Least <DarkerText>1 special character</DarkerText></PassReqSpan>*/}
                            {/*        </PasswordReqContainer>*/}

                            {/*    </GridHalf>*/}
                            {/*    <SaveBtn*/}
                            {/*        type='submit'*/}
                            {/*    >Save</SaveBtn>*/}
                            {/*</GridContainer>*/}
                        </ElementsContainer>
                    } >
                    </Widget>


                    <Widget title={"Bid History"} content={
                        <ElementsContainer>
                            <UserBidHistoryGrid />

                        </ElementsContainer>
                    } >
                    </Widget>

                </PageContainer>
            </Form>
        </Formik >
    );
}

