import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { PageContainer } from "../styles/ThemeStyles";





const Wrapper = styled.div`
    max-width : 90%;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadow};
    box-sizing: border-box;
    padding: 5rem 6rem ;
    border-radius: 2em;
    ${(props) => mobile({
    padding: '2.5rem 3rem',
})};

${(props) => tablet({
    padding: '2.5rem 3rem',
})};

${(props) => smDesktop({
    padding: '5rem 6rem',
})};

`
const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing : border-box;
    padding : 1.5rem 0;
    margin-bottom: 5rem;
    /* background : #476068; */
`
const Logo = styled.img`
    object-fit : cover;
    max-width : 300px;
    min-width : 50%;
    /* background: #221e1e; */
`

const SucessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
  margin-bottom: 3rem;
  text-align: center;
`
const ButtonsContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};

    
`
const NavLink = styled(Link)`
    text-decoration: none;
    width:100%;
`

const ModalBtn = styled.button`
    width: ${(props) => props.btnType === 'login' ? '45%' : '100%'};
    /* margin : 3rem 0 1rem 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1.3rem;
    padding : 1rem 3rem;
    border-radius : 9999px;
    box-sizing : border-box;
    background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementary : colors.primary};
    color : white;
    cursor: pointer;
    &:hover {
      background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementaryLight : colors.primaryLight};
    }
`


export const UnauthorizedPage = () => {

    const location = useLocation().pathname.split('/');

    const currentEventId = location[2]
    const currentSlug = location[3]

    return (
        <PageContainer style={{ justifyContent: "center" }} >
            <Wrapper>
                <LogoContainer>
                    <Logo src="/images/company_logo.png" />
                </LogoContainer>
                <SucessDiv>
                    You have not logged in yet. Register a new account or Sign In.
                </SucessDiv>
                <ButtonsContainer>
                    <NavLink
                        to={`/event-landing-page/${currentEventId}/${currentSlug}/register`}
                    >
                        <ModalBtn>Register</ModalBtn>

                    </NavLink>
                    <NavLink
                        to={`/event-landing-page/${currentEventId}/${currentSlug}/login`}
                    >
                        <ModalBtn>Login</ModalBtn>

                    </NavLink>

                </ButtonsContainer>

            </Wrapper>
        </PageContainer>
    );
}

