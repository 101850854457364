import { Box, Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import styled from 'styled-components';
import { Widget } from "../components/Widget";
import { ElementsContainer, PageContainer } from "../styles/ThemeStyles";



const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GridItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ItemImg = styled.img`
    object-fit: cover;
    width: 50%;
    max-height: 50vh;
    /* box-shadow: ${({ theme }) => theme.boxShadow}; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
`


const PlaceBidBtn = styled.button`
    max-width: 60%;
    margin : 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1.3rem;
    padding : 1rem 3rem;
    border-radius : 9999px;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.primary};
    color : white;
    /* box-shadow: ${({ theme }) => theme.boxShadow}; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    cursor: pointer;
    &:hover {
      background : ${({ theme: { colors } }) => colors.primaryLight};
    }
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* background-color: aliceblue; */
    /* margin-top: 1rem; */
`

const InfoTitle = styled.div`
    /* width: 100%; */
    color : ${({ theme: { colors } }) => colors.primary} ;
    /* background-color: #755c4e; */
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1rem 0;
    font-size : 1.2rem;
    font-weight : 800;
`
const Border = styled.div`
    width: 100%;
    height: 2px;
    background-color: lightgrey;
`


const InfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const PlaceBidContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
`
const BidInputContainer = styled.div`

`

const BidInput = styled.input`
    width : 100%;
    text-align: center;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.bg};
    border-radius: 0.3em;
    font-weight: 800;
    font-size: 1.3rem;
    background-color: ${({ theme: { colors } }) => colors.bg};
    color: ${({ theme: { colors } }) => colors.darkText};

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`
const WarningText = styled.span`
    color: red;
`


const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 7,
    borderRadius: '10px'


};
const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    /* background-color: aliceblue; */
    justify-content: space-between;
    /* flex-direction: column; */
    /* margin-top: 1rem; */
`
const ModalBtn = styled.button`
    width: 45%;
    margin : 3rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1.3rem;
    padding : 1rem 3rem;
    border-radius : 9999px;
    box-sizing : border-box;
    background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementary : colors.primary};
    color : white;
    cursor: pointer;
    &:hover {
      background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementaryLight : colors.primaryLight};
    }
`
const ModalText = styled.span`
    font-size: 1.5rem;
    color :  ${({ theme: { colors } }) => colors.darkText};
    /* background-color: #755c4e; */
    font-weight : 500;
`




export const MakeADonationPage = () => {

    const [openModal, setOpenModal] = useState(false)

    const [loading, setLoading] = useState(false)
    const [warning, setWarning] = useState('')

    return (
        <PageContainer>

            <Widget title='Make a donation!' content={
                <ElementsContainer>
                    <MainContainer>
                        <ItemImg src='/images/girl-saving-money.svg' />
                        <GridItem>
                            <InfoContainer>
                                <InfoTitle>Contribute with a one off donation</InfoTitle>
                                <Border />
                                <InfoTextContainer>
                                    Help the cause with on time donation! Select the amount and approve the payment.
                                </InfoTextContainer>
                            </InfoContainer>
                        </GridItem>
                        <GridItem>
                            <PlaceBidContainer>
                                <BidInputContainer>
                                    <BidInput
                                        type="number"
                                        name='amount'
                                        placeholder={`£5`}
                                    />

                                </BidInputContainer>
                                <WarningText>{warning}</WarningText>
                                <PlaceBidBtn
                                    onClick={() => setOpenModal(true)}
                                >Donate</PlaceBidBtn>

                            </PlaceBidContainer>

                        </GridItem>
                        <Modal
                            open={openModal}
                            onClose={() => setOpenModal(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={boxStyle}>
                                {loading ?
                                    <CircularProgress size={80} />
                                    :
                                    <>
                                        <ModalText>
                                            Press continue to go through with the donation.
                                        </ModalText>
                                        <ButtonsContainer>
                                            <ModalBtn
                                            >Continue</ModalBtn>
                                            <ModalBtn
                                                color='complementary'
                                                onClick={() => setOpenModal(false)}
                                            >Cancel</ModalBtn>
                                        </ButtonsContainer>
                                    </>
                                }

                            </Box>

                        </Modal>


                    </MainContainer>
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>
    );
}