import { createSlice } from '@reduxjs/toolkit'

const mobileMenu = createSlice({
  name: "mobileMenu",
  initialState: {
    isOpen: false,
  },

  reducers: {
    openMenu: (state) => {
      state.isOpen = true
    },
    closeMenu: (state) => {
      state.isOpen = false;
    },
    toggleMenu: (state) => {
      state.isOpen = !state.isOpen;
    },
  }
})

export const { openMenu, closeMenu, toggleMenu } = mobileMenu.actions;
export default mobileMenu.reducer;