import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';




const getAuctionItemBid = async (auctionItemId) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/AuctionItem/${auctionItemId}`, {

      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    console.log(res.data)
    return res.data

  } catch (err) { console.log(err) }
}



const placeAuctionItemBid = async (bidInfo) => {
  const state = store.getState();

  try {
    const res = await axios.post(`${apiURL}/api/Bid`, bidInfo, {

      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    console.log(res.data)
    return res.data

  } catch (err) {
    console.log(err)
    return err
  }
}

const getUserBidHistory = async (userId) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/Users/${userId}`, {

      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    console.log(res.data)
    return res.data

  } catch (err) { console.log(err) }
}

const getBidsReport = async (eventId, filter) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/bid/report/${eventId}?filter=${filter}`, {

        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    console.log(res.data)
    return res.data

  } catch (err) { console.log(err) }
}

const placeCustomBid = async (bidInfo) => {
  const state = store.getState();

  try {
    const res = await axios.post(`${apiURL}/api/Bid/QuickBid`, bidInfo, {

      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    console.log(res)
    return res.data

  } catch (err) {
    console.log(err)
    return err
  }
}




export { getBidsReport, getAuctionItemBid, placeAuctionItemBid, getUserBidHistory, placeCustomBid };