import React from 'react'
import { ButtonsContainer, ModalBtn, ModalText, ModalBox } from "../styles/ThemeStyles";
import { Modal, CircularProgress } from '@mui/material/';



export default function ModalPopUp({ openModal, setOpenModal, modalMessage, setModalMessage, modalLoading, reload }) {

  const handleModalClose = () => {
    setOpenModal(false)
    setModalMessage('')
    if (reload) {
      window.location.reload()
    }
  }


  return (
    <Modal
      open={openModal}
      onClose={() => handleModalClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        {modalLoading ?
          <CircularProgress size={80} />
          :
          modalMessage &&
          <>
            <ModalText>
              {modalMessage}
            </ModalText>
            <ButtonsContainer style={{ justifyContent: 'center' }} >
              <ModalBtn
                single
                color='complementary'
                onClick={() => handleModalClose()}
              >Close</ModalBtn>
            </ButtonsContainer>
          </>
        }
      </ModalBox>
    </Modal>
  )
}
