import { useEffect, useState } from 'react';
import * as FaIcons from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { loginCall } from '../api/authIndex';
import { lgDesktop, mobile, smDesktop, tablet } from '../styles/responsive';
import { PageContainer } from "../styles/ThemeStyles";




const Wrapper = styled.div`
    max-width : 90%;
    min-width: 50%;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadow};
    box-sizing: border-box;
    padding: 5rem 6rem ;
    border-radius: 2em;

    ${(props) => mobile({
    minWidth: '50%',
    padding: '2rem 3rem'

})};

${(props) => tablet({
    minWidth: '65%',
    maxWidth: '90%',
    padding: '3rem 4rem'
})};

${(props) => smDesktop({
    // minWidth: '50%',
    maxWidth: '60%',
    padding: '5rem 6rem'
})};

${(props) => lgDesktop({
    minWidth: '50%',
    maxWidth: '60%',
    padding: '5rem 6rem'
})};


`
const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing : border-box;
    padding : 1.5rem 0;
    margin-bottom: 5rem;
    /* background : #476068; */
`
const Logo = styled.img`
    object-fit : cover;
    max-width : 300px;
    min-width : 50%;
`


const GridContainer = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
    /* background-color: aliceblue; */
`

const Label = styled.label`
    /* background-color: #7e6363; */
    display: flex;
    align-items: center;
    width : 100%;
    box-sizing: border-box;
    padding: 1rem;
    border : 2px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;

    &:focus-within {
        border : 2px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color : ${({ theme: { colors } }) => colors.text};
`

const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: 0 .7em;
    border : none;
    outline : none;
    font-size: 1.2em;

    &:focus {
        border : none;
        outline: none;
    }
`


const LoginBtn = styled.button`
    margin-top : 3rem;
    border : none;
    width: 100%;
    font-size : 1.2rem;
    font-weight: 800;
    padding : 1rem 2rem;
    cursor : pointer;
    border-radius : 2em;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`
const Error = styled.span`
  color: red;
`
const SucessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
`
const SuccessTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 800;
  margin: 5% 0 10% 0;
  color: ${({ theme: { colors } }) => colors.complementary};
`


const placeHolderClinet = {
    email: 'clientuser@email.com',
    pass: 'clientpass'
}


export const Login = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch()

    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    const currentPath = path.slice(0, 4).join('/')
    const [errorMessage, setErrorMessage] = useState('')

    const { isFetching, error, currentUser } = useSelector((state) => state.user);

    const handleLogin = (e) => {
        e.preventDefault();
        loginCall(dispatch, { email: email, password: password })
        // if (res.response?.data) {
        //     alert(res.response.data)
        // }
    }

    useEffect(() => {
        if (currentUser) {
            setTimeout(() => {
                if (currentUser.userType === 2 && path.length > 2) {
                    navigate(currentPath)
                }
                else {
                    navigate("/")
                }
            }, 3000)
        }
    }, [currentUser])



    // useEffect(() => {
    //     window.location.reload(false)
    // }, [])


    return (
        <PageContainer style={{ justifyContent: "center" }} >
            <Wrapper>
                <LogoContainer>
                    <Logo src="/images/company_logo.png" />
                </LogoContainer>
                {currentUser ? <SucessDiv>
                    <SuccessTitle>LOGIN SUCCESSFUL!</SuccessTitle>
                    You will now be redirected to the <b>Home Page</b></SucessDiv> :
                    <>
                        <GridContainer>
                            <Label>
                                <IconContainer>
                                    <FaIcons.FaUserCircle />
                                </IconContainer>
                                <TextInput placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                            </Label>
                            <Label>
                                <IconContainer>
                                    <FaIcons.FaLock />
                                </IconContainer>
                                <TextInput type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                            </Label>
                        </GridContainer>

                        <LoginBtn onClick={handleLogin}
                        // disabled={isFetching} 
                        >LOGIN</LoginBtn>
                    </>
                }
                {error && <Error>Wrong username or password...</Error>}
            </Wrapper>
        </PageContainer>
    );
}

