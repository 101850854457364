import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { getClients } from '../api/clientIndex';
import { setCurrentClientId } from '../redux/eventsSlice';
import { theme } from '../styles/ThemeStyles';


const EditBtn = styled.button`
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`


export const ClientsGrid = () => {

  const dispatch = useDispatch()

  const columns = [
    {
      field: '_id',
      headerName: '#',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Client Name',
      flex: 3,
      editable: false,
    },

    {
      field: 'view',
      headerName: 'View',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (param) => {
        return (

          <Link
            to={`/clients/${param.row._id}/view`}
          >
            <EditBtn
              onClick={() => dispatch(setCurrentClientId(param.row._id))}
            >View</EditBtn>
          </Link>
        )
      }

    },
  ];

  const [rows, setRows] = useState([]);
  const [clients, setClients] = useState('');

  useEffect(() => {

    getClients().then((data) => setClients(data))
    console.log(clients)

  }, [])

  useEffect(() => {
    if (clients) {
      setRows(
        clients?.map((el) => ({ _id: el.id, name: el.name }))
      )
    }
  }, [clients])

  return (
    <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {clients ?
        <DataGrid
          rows={rows}
          getRowId={(row) => row?._id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnCliclk={true}
          disableColumnMenu
          sx={{
            color: theme.colors.text,
            border: 'none',


            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },

            '& .MuiDataGrid-columnHeader': {

            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
            },
          }}
        />
        :
        <CircularProgress size={80} />
      }
    </div>
  )
}
