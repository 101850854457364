import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Modal } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { getAdminAuctionItems, getAuctionItem } from '../api/auctionItemsIndex';
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { boxStyle, ButtonsContainer, ModalBtn, ModalText, theme } from "../styles/ThemeStyles";


const AssignedBtn = styled.button`
    text-decoration: none;
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : #d20c0c;
    color : white;
    ${(props) => mobile({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};

${(props) => tablet({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};

${(props) => smDesktop({
  fontSize: '1rem',
  padding: '0.6rem 2rem'
})};
    
`
const AssignBtn = styled.button`
    text-decoration: none;
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }

    ${(props) => mobile({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};

${(props) => tablet({
  fontSize: '0.8rem',
  padding: '0.4rem 1rem'
})};
${(props) => smDesktop({
  fontSize: '1rem',
  padding: '0.6rem 2rem'
})};

`
const ClientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};


export const AuctionItemsGridSelection = ({ customBidInfo, setCustomBidInfo, setAuctionitem }) => {

  const [auctionItems, setAuctionItems] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);

  const columns = [
    {
      field: '_id',
      headerName: '#',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 4,
      editable: false,
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 1,
      editable: false,
    },
    {
      field: 'startPrice',
      headerName: 'Start Price',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },
    {
      field: 'supplyPrice',
      headerName: 'Supply Price',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },

    {
      field: 'bidType',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },

    {
      field: 'select',
      headerName: 'Select',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (param) => {
        return (
          <>
            {
              customBidInfo?.bid.auctionItemId === param.row._id ?
                <AssignedBtn
                  type='button'
                >Selected</AssignedBtn>

                :

                <AssignBtn
                  type='button'
                  onClick={() => { setItemSelected(param.row._id); setAssignOpenModal(true) }}
                >Select</AssignBtn>
            }
          </>
        )
      }
    },

  ];





  const [rows, setRows] = useState([]);
  const [rowsInfo, setRowsInfo] = useState([]);


  const location = useLocation().pathname.split('/')
  const currentEventId = location[2]

  const [assignOpenModal, setAssignOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [warning, setWarning] = useState('')

  const eventId = 10;

  useEffect(() => {
    getAdminAuctionItems(currentEventId).then((data) => setAuctionItems(data))
  }, [])


  useEffect(() => {
    if (auctionItems) {
      const info = auctionItems?.map((el) => ({
        _id: el.id,
        title: el.title,
        qty: el.qty,
        startPrice: `£${el.startPrice}`,
        supplyPrice: `£${el.supplyPrice}`,
        bidType: el.bidType === 0 ? 'Auction' : 'Sealed',
        catalogue: el.catalogue ? 'Yes' : 'No',
        status: el.status === 0 ? 'Draft' : 'Active',
      }))
      setRows(info)
      setRowsInfo(info)

    }
  }, [auctionItems])

  const handleAuctionItemAssignment = async () => {
    setLoading(true)
    const itemInfo = await getAuctionItem(itemSelected)
    setAuctionitem(itemInfo)

    setCustomBidInfo((prev) => ({
      ...prev,
      bid: {
        ...prev.bid,
        auctionItemId: itemSelected,
        auctionItemTitle: itemInfo?.title,
      }
    }))

    setLoading(false)
    setAssignOpenModal(false)

  }

  const handleModalClose = () => {
    setAssignOpenModal(false)
    setModalMessage('')
  }

  // const callAuctionItem = (id) => {

  //   getAuctionItem(id).then((data) => {
  //     setAuctionitem(data);
  //     setBidInfo((prev) => ({
  //       ...prev,
  //       bidType: data.bidType
  //     }))
  //     console.log(data);
  //   })
  // }


  const [searchText, setSearchText] = useState('');



  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    // const filteredRows = rowsInfo.filter((row) => {
    //   return Object.keys(row).some((field) => {
    //     return searchRegex.test(row[field].toString());
    //   });
    // });
    const filteredRows = rowsInfo.filter((d) => d.title.toLowerCase().includes(searchValue));
    setRows(filteredRows);
  };


  return (
    <div style={{ height: 450, width: '100%' }}>
      <DataGrid
        currentEventId={currentEventId}
        rows={rows}
        getRowId={(row) => row?._id}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnCliclk={true}
        disableColumnMenu
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
        sx={{
          color: theme.colors.text,
          border: 'none',


          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'transparent',
          },

          '& .MuiDataGrid-columnHeader': {

          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '700',
          },
        }}
      />


      <Modal
        open={assignOpenModal}
        onClose={() => handleModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            modalMessage ?
              <>
                <ModalText>
                  {modalMessage}
                </ModalText>
                <ButtonsContainer style={{ justifyContent: 'center' }} >
                  <ModalBtn
                    color='complementary'
                    onClick={() => handleModalClose()}
                  >Close</ModalBtn>
                </ButtonsContainer>
              </>
              :
              <>
                <ClientInfoContainer>
                  Are you sure you want to select this item?
                </ClientInfoContainer>
                <ButtonsContainer>
                  <ModalBtn
                    onClick={() => handleAuctionItemAssignment()}
                  >Assign</ModalBtn>
                  <ModalBtn
                    color='complementary'
                    onClick={() => handleModalClose()}
                  >Close</ModalBtn>
                </ButtonsContainer>
              </>
          }
        </Box>
      </Modal>



    </div>
  )
}
