import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';
import { loginFailed, loginStart, loginSuccess } from '../redux/userSlice';



// REGISTER USER

const registerUser = async (userInfo) => {

  try {
    const res = await axios.post(`${apiURL}/api/Users/register`, userInfo)

    console.log("REGISTERED " + res.data)
    return res.data

  } catch (err) {
    console.log(err)

  }
}

// LOGIN

const loginCall = async (dispatch, user) => {

  dispatch(loginStart());
  try {
    const res = await axios.post(`${apiURL}/api/Users/login`,
      user
    );
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailed());
    return err
  }
}
const getSetupIntent = async () => {
  try {
    const res = await axios.post(`${apiURL}/api/Stripe/Setup`);
    return res.data.client_secret;
  }
  catch (err) {
    console.log(err);
  }
}
const register = async (user) => {
  try {
    const res = await axios.post(`${apiURL}/api/users/register/`, user)
    return res.data

  } catch (err) {
    console.log(err)
    return err
  }
}
export { registerUser, loginCall, getSetupIntent, register };
