import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserBidHistory } from '../api/bidIndex';
import { theme } from '../styles/ThemeStyles';



export const UserBidHistoryGrid = () => {

  const columns = [
    {
      field: '_id',
      headerName: '#',
      flex: 1,
    },
    {
      field: 'item',
      headerName: 'Item',
      flex: 3,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 2,
      editable: false,
    },
    {
      field: 'bidDate',
      headerName: 'Bid Date',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 5,
      // sortable: true,
      editable: false,
    },

  ];

  const [rows, setRows] = useState([]);
  const [userBids, setUserBids] = useState('');
  const currentUserId = useSelector((state) => state.user.currentUser.id)

  useEffect(() => {

    getUserBidHistory(currentUserId).then((data) => setUserBids(data))
    console.log(userBids)

  }, [])

  useEffect(() => {
    if (userBids) {
      setRows(
        userBids?.map((el) => ({
          _id: el.id,
          item: el.auctionItemId,
          amount: `£ ${el.amount}`,
          bidDate: format(parseISO(el.bidDate), 'dd/MM/yyyy hh:mm a')
        }))
      )
    }
  }, [userBids])

  return (
    <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {userBids ?
        <DataGrid
          rows={rows}
          getRowId={(row) => row?._id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnCliclk={true}
          disableColumnMenu
          sx={{
            color: theme.colors.text,
            border: 'none',


            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },

            '& .MuiDataGrid-columnHeader': {

            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
            },
          }}
        />
        :
        <CircularProgress size={80} />
      }
    </div>
  )
}
