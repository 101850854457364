import { Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { createClient } from '../../api/clientIndex';
import ModalPopUp from '../../components/ModalPopUp';
import { Widget } from "../../components/Widget";
import { mobile, smDesktop, tablet } from "../../styles/responsive";
import { ElementsContainer, PageContainer, TextSpan } from "../../styles/ThemeStyles";


const GridContainer = styled.div`
    display: grid;
    gap : 1.5rem 3rem;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};


`
const GridItem = styled.div`
    
`
const Item = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 0.7fr 1fr;
`

const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const TextInputContainer = styled.div`
    /* background: green; */
`
const TextInputError = styled.div`
    color : red;
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`

//FORM INPUTS

const FormTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <TextInputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <TextInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </TextInputContainer>
    );
};



export const CreateClient = () => {

    const schema = {
        name: "",
        address: {
            address1: "",
            address2: "",
            city: "",
            county: "",
            postcode: ""
        },
        leadUser: {
            firstName: "",
            lastName: "",
            mobile: "",
            email: "",
            password: "",
            confirmPassword: "",
            userType: 1,
            address: {
                address1: "",
                address2: "",
                city: "",
                county: "",
                postcode: ""
            },

        }
    }

    const [clientInfo, setClientInfo] = useState(schema)

    // MODAL STATES
    const [openModal, setOpenModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    useEffect(() => {
        console.log(clientInfo)
    }, [clientInfo])

    return (
        <>

            <Formik
                enableReinitialize
                initialValues={{
                    name: clientInfo?.name,
                    address: {
                        address1: clientInfo?.address.address1,
                        address2: clientInfo?.address.address2,
                        city: clientInfo?.address.city,
                        county: clientInfo?.address.county,
                        postcode: clientInfo?.address.postcode
                    },

                    leadUser: {
                        firstName: clientInfo?.leadUser.firstName,
                        lastName: clientInfo?.leadUser.lastName,
                        mobile: clientInfo?.leadUser.mobile,
                        email: clientInfo?.leadUser.email,
                        password: clientInfo?.leadUser.password,
                        confirmPassword: clientInfo?.leadUser.confirmPassword,
                        address: {
                            address1: clientInfo?.leadUser.address.address1,
                            address2: clientInfo?.leadUser.address.address2,
                            city: clientInfo?.leadUser.address.city,
                            county: clientInfo?.leadUser.address.county,
                            postcode: clientInfo?.leadUser.address.postcode
                        },
                    }

                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .max(50, 'Must be 50 characters or less')
                        .required('Required'),
                    address: Yup.object({
                        address1: Yup.string()
                            .max(300, 'Must be 300 characters or less')
                            .required('Required'),
                        address2: Yup.string()
                            .max(300, 'Must be 300 characters or less'),
                        city: Yup.string()
                            .max(300, 'Must be 300 characters or less'),
                        // .required('Required'),
                        county: Yup.string()
                            .max(300, 'Must be 300 characters or less'),
                        // .required('Required'),
                        postcode: Yup.string()
                            .max(300, 'Must be 300 characters or less'),
                        // .required('Required'),
                    }),

                    leadUser: Yup.object({

                        firstName: Yup.string()
                            .max(50, 'Must be 50 characters or less')
                            .required('Required'),
                        lastName: Yup.string()
                            .max(50, 'Must be 50 characters or less')
                            .required('Required'),
                        mobile: Yup.string()
                            .max(11, 'Must be 11 characters or less'),
                        // .required('Required'),
                        email: Yup.string()
                            .email()
                            .max(50, 'Must be 50 characters or less')
                            .required('Required'),
                        password: Yup.string()
                            .max(50, 'Must be 50 characters or less')
                            .required('Required'),
                        confirmPassword: Yup.string()
                            .max(50, 'Must be 50 characters or less')
                            .oneOf([Yup.ref('password')], 'Passwords do not match')
                            .required('Required'),
                        address: Yup.object().shape({
                            address1: Yup.string()
                                .max(300, 'Must be 300 characters or less'),
                            // .required('Required'),
                            address2: Yup.string()
                                .max(300, 'Must be 300 characters or less'),
                            city: Yup.string()
                                .max(300, 'Must be 300 characters or less'),
                            // .required('Required'),
                            county: Yup.string()
                                .max(300, 'Must be 300 characters or less'),
                            // .required('Required'),
                            postcode: Yup.string()
                                .max(300, 'Must be 300 characters or less')
                            // .required('Required'),
                        }),
                    })


                })}
                onSubmit={async (values) => {

                    setOpenModal(true)
                    setModalLoading(true)

                    try {

                        const res = await createClient(values)

                        if (!res) {
                            setModalMessage('Something went wrong...')
                        }
                        else if (res.response?.data) {
                            setModalMessage(res.response?.data)
                        }
                        else {
                            setModalMessage('Client Created!')
                        }
                        setModalLoading(false)

                    } catch (error) {
                        console.log(error)
                    }
                }}
            >

                <Form style={{ width: "100%" }} >
                    <PageContainer>
                        <Widget title={"Create Client"} content={
                            <ElementsContainer>
                                <TextSpan>
                                    Below is a list of your Client details.
                                </TextSpan>

                            </ElementsContainer>
                        } >
                        </Widget>


                        <Widget title={"Client Details"} createOption content={
                            <ElementsContainer>
                                <GridContainer>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Client Name:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="name"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Address 1: </TitleInput>
                                            <FormTextInput
                                                name="address.address1"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Address 2: </TitleInput>
                                            <FormTextInput
                                                name="address.address2"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>County:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="address.county"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>City:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="address.city"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>Postcode:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="address.postcode"
                                            />
                                        </Item>
                                    </GridItem>
                                </GridContainer>
                            </ElementsContainer>
                        } >
                        </Widget>

                        {/* LEAD USER DETAILS */}
                        <Widget title={"Lead User Details"} content={
                            <ElementsContainer>
                                <GridContainer>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>First Name:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.firstName"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Last Name:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.lastName"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Email:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.email"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Mobile:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.mobile"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>Password:</TitleInput>
                                            <FormTextInput
                                                type="password"
                                                name="leadUser.password"
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <TitleInput>Confirm Password:</TitleInput>
                                            <FormTextInput
                                                type="password"
                                                name="leadUser.confirmPassword"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>Address 1:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.address.address1"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>Address 2:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.address.address2"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>County:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.address.county"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>City:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.address.city"
                                            />
                                        </Item>
                                    </GridItem>

                                    <GridItem>
                                        <Item>
                                            <TitleInput>Postcode:</TitleInput>
                                            <FormTextInput
                                                type="text"
                                                name="leadUser.address.postcode"
                                            />
                                        </Item>
                                    </GridItem>

                                </GridContainer>
                            </ElementsContainer>
                        } >
                        </Widget>


                    </PageContainer>
                </Form>
            </Formik >
            <ModalPopUp
                openModal={openModal}
                setOpenModal={setOpenModal}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                modalLoading={modalLoading}
            />
        </>

    );
}

