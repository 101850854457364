import { Box, Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { getAuctionItem } from "../api/auctionItemsIndex";
import { placeAuctionItemBid } from '../api/bidIndex';
import { Timer } from '../components/Timer';
import { Widget } from "../components/Widget";
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { boxStyle, ButtonsContainer, ElementsContainer, ModalBtn, ModalText, PageContainer } from "../styles/ThemeStyles";
import { getWebConfig } from "../api/webConfigIndex";


const MainContainer = styled.div`
    display: grid;
    gap: 1.5rem 5rem;
    grid-template-columns: 1fr 1fr;
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};


`

const GridItem = styled.div`
    width: 100%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
`

const ItemImg = styled.img`
    object-fit: contain;
    width: 100%;
    max-height: 50vh;
    border-radius: 8px;
    /* box-shadow: ${({ theme }) => theme.boxShadow}; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`

const RaisedAmount = styled.div`
    max-width: 100%;
    margin : 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1.3rem;
    padding : 1rem 1rem;
    border-radius : 8px;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    /* box-shadow: ${({ theme }) => theme.boxShadow}; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    color : white;
`
const RaisedText = styled.span`
    font-size: 1rem;
    color :  ${({ theme: { colors } }) => colors.complementary};
    /* background-color: #755c4e; */
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    font-size : 1.2rem;
    font-weight : 800;
`
const TimeTitle = styled.span`
    font-size: 1.5rem;
    color :  ${({ theme: { colors } }) => colors.darkText};
    /* background-color: #755c4e; */
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    font-weight : 800;
`

const PlaceBidBtn = styled.button`
    max-width: 100%;
    margin : 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1.3rem;
    padding : 1rem 3rem;
    border-radius : 8px;
    box-sizing : border-box;
    background : ${props => props.primaryColour || (({ theme: { colors } }) => colors.primary)};
    color : white;
    /* box-shadow: ${({ theme }) => theme.boxShadow}; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    cursor: pointer;
    transform: translateY(0);
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);

    &:hover {
      background : ${({ theme: { colors } }) => colors.primaryLight};
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        transform: translateY(-2px);
        transform: scale(1.02);
    }
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: 1rem; */
`
const TimeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* margin-top: 1rem; */
`

const InfoTitle = styled.div`
    color : ${props => props.primaryColour || (({ theme: { colors } }) => colors.primary)};
    /* background-color: #755c4e; */
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    font-size : 1.2rem;
    font-weight : 800;
    border-bottom : 2px solid lightgrey;
`
const InfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const PlaceBidContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`
const BidInputContainer = styled.div`
    margin-top: 0.5rem;
`

const BidInput = styled.input`
    width : 100%;
    text-align: center;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.bg};
    border-radius: 0.3em;
    font-weight: 800;
    font-size: 1.3rem;
    background-color: ${({ theme: { colors } }) => colors.bg};
    color: ${({ theme: { colors } }) => colors.darkText};

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`
const WarningText = styled.span`
    color: red;
`


export const ViewAuctionItem = () => {

    const [auctionItem, setAuctionitem] = useState("")

    const location = useLocation().pathname.split('/');
    const navigate = useNavigate()
    const itemId = location[location.length - 1]
    const currentEventId = location[2]
    const slug = location[3]
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [warning, setWarning] = useState('')
    const [modalMessage, setModalMessage] = useState('')
    const [webConfig, setWebConfig] = useState("")
    const { currentUser } = useSelector((state) => state.user)

    const schema = {
        userId: currentUser?.id,
        auctionItemId: Number(itemId),
        amount: 0,
        // bidDate: "2022-04-04T18:02:01.438Z",
        anonymous: true,
        bidType: 0,
        bidStatus: 0,
        stripeId: "string",
        tableNumber: 0
    }

    const [bidInfo, setBidInfo] = useState(schema)


    const callAuctionItem = (id) => {

        getAuctionItem(id).then((data) => {
            setAuctionitem(data);
            setBidInfo((prev) => ({
                ...prev,
                bidType: data.bidType
            }))
            console.log(data);

        })
    }

    const handleBidPlacement = () => {
        if (currentUser == null) {
            const navPath = `/event-landing-page/${currentEventId}/${slug}/unauthorized`
            navigate(navPath)
            return;
        }


        setLoading(true)
        placeAuctionItemBid(bidInfo).then((data) => {

            setLoading(false)

            if (data.response?.data) {
                console.log(data.response.data)
                setWarning(data.response.data)
                setModalMessage(data.response.data)
            }
            else {
                setWarning('')
                setModalMessage('Congratulations Bid Has Been Placed!')

            }
        })
    }

    const handleBidChange = (e) => {
        setBidInfo((prev) => ({
            ...prev,
            [e.target.name]: Number(e.target.value)
        }))
    }


    useEffect(() => {
        //if (!currentUser) {
        //    navigate(`/event-landing-page/${currentEventId}/${slug}/register`)
        //}
        //else {
            callAuctionItem(itemId)
        //}

        getWebConfig(currentEventId).then((data) => {
            setWebConfig(data);
        });

    }, [])

    const handleModalClose = () => {
        setOpenModal(false)
        setModalMessage('')
        window.location.reload(false)
    }

    return (
        <PageContainer>
            <Widget primaryColour={webConfig.primaryColour} title={auctionItem.title} content={
                <ElementsContainer>
                    <MainContainer>
                        <GridItem>
                            {auctionItem.images?.map((c) => (
                                <ItemImg src={c.url} key={c.id} />
                            ))}


                        </GridItem>

                        <GridItem>

                            {auctionItem?.bidType === 0 ?

                                <>
                                    <RaisedText>CURRENT BID</RaisedText>
                                    <RaisedAmount>£{
                                        auctionItem.highestBid < 1 ?
                                            auctionItem.startPrice :
                                            auctionItem.highestBid
                                    }</RaisedAmount>
                                </>
                                :
                                <>

                                    <RaisedText>SEALED BID</RaisedText>
                                    <RaisedAmount>Bid the highest you can!</RaisedAmount>
                                </>
                            }

                            <TimeContainer>
                                <TimeTitle>Time Left:</TimeTitle>
                                {auctionItem &&
                                    <Timer deadline={parseISO(auctionItem?.endDate)} timerType='big' />
                                }
                            </TimeContainer>


                        </GridItem>
                        <GridItem>
                            <InfoContainer>
                                <InfoTitle primaryColour={webConfig.primaryColour}>Description</InfoTitle>
                                <InfoTextContainer>
                                    {auctionItem.description}
                                </InfoTextContainer>
                            </InfoContainer>
                        </GridItem>
                        <GridItem>
                            <PlaceBidContainer>
                                <InfoTitle primaryColour={webConfig.primaryColour}>Bid Amount</InfoTitle>
                                <BidInputContainer>
                                    <BidInput
                                        type="number"
                                        name='amount'
                                        min={0}
                                        placeholder={`£${auctionItem.startPrice}`}
                                        onChange={handleBidChange}
                                    />

                                </BidInputContainer>
                                <WarningText>{warning}</WarningText>
                                <InfoTitle primaryColour={webConfig.primaryColour}>Table Number (Optional)</InfoTitle>
                                <BidInputContainer>
                                    <BidInput
                                        type="number"
                                        name='tableNumber'
                                        min={0}
                                        placeholder='0'
                                        onChange={handleBidChange}
                                    />

                                </BidInputContainer>


                                <PlaceBidBtn primaryColour={webConfig.primaryColour}
                                    onClick={() => setOpenModal(true)}
                                >Place Bid</PlaceBidBtn>
                                <Modal
                                    open={openModal}
                                    onClose={() => handleModalClose()}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={boxStyle}>
                                        {loading ?
                                            <CircularProgress size={80} />
                                            :
                                            modalMessage ?
                                                <>
                                                    <ModalText>
                                                        {modalMessage}
                                                    </ModalText>
                                                    <ButtonsContainer
                                                        style={{ justifyContent: 'center' }}
                                                    >
                                                        <ModalBtn
                                                            color='complementary'
                                                            onClick={() => handleModalClose()}
                                                        >Close</ModalBtn>
                                                    </ButtonsContainer>

                                                </>
                                                :
                                                <>
                                                    <ModalText>
                                                        Are you sure you want to place the bid?
                                                    </ModalText>
                                                    <ButtonsContainer>
                                                        <ModalBtn
                                                            onClick={() => handleBidPlacement()}
                                                        >Yes</ModalBtn>
                                                        <ModalBtn
                                                            color='complementary'
                                                            onClick={() => setOpenModal(false)}
                                                        >No</ModalBtn>
                                                    </ButtonsContainer>
                                                </>
                                        }
                                    </Box>
                                </Modal>
                            </PlaceBidContainer>
                        </GridItem>
                    </MainContainer>
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>
    );
}