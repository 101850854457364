import { FileUpload } from '@mui/icons-material';
import { Box, Checkbox, CircularProgress, Modal, TextField } from '@mui/material/';
// or const { useQuill } = require('react-quilljs');
import ImageResize from 'quill-image-resize-module-react';
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useCallback, useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import ReactQuill, { Quill } from 'react-quill';
import { useQuill } from "react-quilljs";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { postImage } from "../../api/imagesIndex";
import { editPage, getPage } from "../../api/pageIndex";
import { getWebConfig, updateWebConfig } from "../../api/webConfigIndex";
import { Widget } from "../../components/Widget";
import { lgDesktop, mobile, smDesktop, tablet } from "../../styles/responsive";
import { boxStyle, ButtonsContainer, ElementsContainer, ModalBtn, ModalText, PageContainer, TextSpan } from "../../styles/ThemeStyles";



const GridContainer = styled.div`
    display: grid;
    /* background-color: #97ba90; */
    gap : 4rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: ${(props) => props.imageSelect ? '2rem' : '0'};

    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: props.imageSelect ? '1fr ' : '1fr',
    gap: '1rem'
})};

${(props) => smDesktop({
    gridTemplateColumns: props.imageSelect ? '1fr 0.3fr' : '1fr 1fr',
})};

${(props) => lgDesktop({
    gridTemplateColumns: props.imageSelect ? '1fr 0.8fr' : '1fr 1fr',
    gap: props.imageSelect ? '1rem' : '4rem',

})};

`


const Item = styled.div`
    /* background-color: #97ba90; */
    display: grid;
    align-self: center;
    gap : 1.5rem;
    grid-template-columns: 1fr 1fr;
    ${(props) => mobile({
    gridTemplateColumns: props.sameLine ? '1fr 0.3fr' : '1fr',
    gap: props.sameLine ? '0.2rem' : '1.5rem',
})};

${(props) => tablet({
    gridTemplateColumns: props.sameLine ? '1fr 0.3fr' : '1fr',
    gap: props.sameLine ? '0.2rem' : '1.5rem',

})};

${(props) => smDesktop({
    gridTemplateColumns: props.sameLine ? '1fr 0.3fr' : '1fr 1fr',
})};

${(props) => lgDesktop({
    gridTemplateColumns: '1fr 1fr',
})};
`


const TitleText = styled.span`
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
    margin-right: 3rem;
    align-self: center;


`
const ColorBoxConatiner = styled.div`
    display: flex;
    max-width: 15rem;
    justify-content: space-between;
    align-items: center;
    border : 2px solid  ${({ theme: { colors } }) => colors.text};
    border-radius: .5rem;
    
`
const ColorHexContainer = styled.div`
    padding: 0 1rem;
    box-sizing: border-box;
`

const ColorHex = styled.span`
    font-size : 1.2rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const ColorSqrConatiner = styled.div`
    display: flex;
    border-left : 2px solid  ${({ theme: { colors } }) => colors.text};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: .3rem;
    cursor : pointer;
`
const ColorSqr = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 4px;
    background : ${(props) => props.color};
`
const PickerContainer = styled.div`
    position: absolute;
    /* left :0; */
`

const PreviewBtn = styled.button`
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.primary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`

const UploaderContainer = styled.div`
    max-width: 100%;
    max-height: 150px;
    border : 2px dashed grey;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    padding: 0.8rem;
    cursor: pointer;
    box-sizing: border-box;

`
const DropText = styled.p`

    font-size: 0.9rem;
    ${(props) => tablet({
    fontSize: '0.8rem'
})};
`

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

`

const ImagePreview = styled.img`
    object-fit: cover;
    align-self: center;
    max-width: 250px;
    justify-content: center;
    box-shadow : ${({ theme }) => theme.imgBoxShadow};

    ${(props) => mobile({
    maxWidth: '150px'
})};

    ${(props) => tablet({
    maxWidth: '200px'
})};

${(props) => smDesktop({
    maxWidth: '150px'
})};

${(props) => lgDesktop({
    maxWidth: '200px'
})};


`

const RTEContainer = styled.div`
    /* min-width: 500px; */
    min-height: 400px;
    height: auto;
    
`
const WidgetContainer = styled.div`
    width :${(props) => props.widgetType === "landingPage" ? "95%" : "90%"};
    padding: 0 2rem;
    background : white;
    box-sizing: border-box;
    /* border-radius : 1em; */
    border-radius : 8px;
    margin-bottom : 3rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`

const WidgetTitleCont = styled.div`
    color : ${({ theme: { colors } }) => colors.primary} ;
    /* background-color: #755c4e; */
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    font-size : 2rem;
    /* font-weight : 800; */
    font-weight : 700;
    border-bottom : 2px solid lightgrey;
    ${(props) => mobile({
    fontSize: '1.5rem',
    flexDirection: 'column'
})};

${(props) => tablet({
    flexDirection: 'column'
})};
${(props) => smDesktop({
    flexDirection: 'row'
})};

`
const SaveBtn = styled.button`
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
    ${(props) => mobile({
    marginTop: '1rem'
})};
${(props) => tablet({
    marginTop: '1rem'
})};

${(props) => smDesktop({
    marginTop: '0'
})};

`




export const WebConfig = () => {



    const defaultColor = "#7a7a7a"
    const [primaryColour, setPrimaryColor] = useState(defaultColor)
    const [textColour, setTextColour] = useState(defaultColor)
    const [showPicker, setShowPicker] = useState(false)
    const [showTextPicker, setShowTextPicker] = useState(false)


    const [imgHomePreveiw, setImgHomePreview] = useState('')
    const [imgLogoPreveiw, setImgLogoPreview] = useState('')
    const [webConfig, setWebConfig] = useState("")
    const [imgHomeWarning, setImgHomeWarning] = useState("")
    const [imgLogoWarning, setImgLogoWarning] = useState("")
    const [pageData, setPageData] = useState('')

    const [preAuthChecked, setPreAuthChecked] = useState(true);
    const [showRaisedChecked, setShowRaisedChecked] = useState(true);
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imgLoading, setImgLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState('')


    // Use id from search location

    const location = useLocation().pathname.split('/');
    const currentEventId = location[2]
    const baseURL = location[0]
    const { quill, quillRef } = useQuill();

    Quill.register('modules/imageResize', ImageResize);


    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
            ],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };


    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]


    useEffect(() => {
        getPage(currentEventId).then((data) => setPageData(data[0]))

    }, [])

    // Or use id stored in state
    // const currentEventId = useSelector((state) => state.events.currentEventId)
    const handlePageChange = (e) => {
        console.log(e)
        setPageData((prev) => ({
            ...prev,
            content: e
        }))
    }
    const {
        getRootProps: getRootLogoProps,
        getInputProps: getInputLogoProps,
        isDragActive: isLogoDragActive } = useDropzone({
            accept: 'image/jpeg,image/png', onDrop: useCallback(acceptedFiles => {
                setImgLogoWarning("Loading Image...")
                let reader = new FileReader()
                let formData = new FormData()
                const fileObjects = acceptedFiles.map(file => {
                    console.log(file)
                    formData.append('file', file, file.name)
                })
                reader.onload = async () => {
                    try {
                        // console.log(acceptedFiles)
                        const res = await postImage(formData)
                        setImgLogoPreview(res)
                        // const { images: prevImages } = webConfig
                        // console.log("Destructuring " + prevImages)

                        setWebConfig((prev) => ({
                            ...prev,
                            images: [...prev.images,
                            {
                                id: 0,
                                url: res,
                                imageType: 0,
                                linkId: currentEventId
                            }
                            ]
                        }))
                        setImgLogoWarning("Uploaded successfuly")
                    } catch (err) { console.log(err) }
                }
                reader.readAsDataURL(acceptedFiles[0])
            }, [])
        })

    const {
        getRootProps: getRootHomePageProps,
        getInputProps: getInputHomePageProps,
        isDragActive } = useDropzone({
            accept: 'image/jpeg,image/png', onDrop: useCallback(acceptedFiles => {
                setImgHomeWarning("Loading Image...")
                let reader = new FileReader()
                let formData = new FormData()
                const fileObjects = acceptedFiles.map(file => {
                    console.log(file)
                    formData.append('file', file, file.name)
                })
                reader.onload = async () => {
                    try {
                        // console.log(formData.getAll('assets'))
                        // const res = await postImage(reader.result)
                        console.log(acceptedFiles)
                        const res = await postImage(formData)
                        // setImgPreview(res)
                        setImgHomePreview(res)
                        setWebConfig((prev) => ({
                            ...prev,
                            images: [...prev.images,
                            {
                                id: 0,
                                url: res,
                                imageType: 1,
                                linkId: currentEventId
                            }
                            ]
                        }))
                        setImgHomeWarning("Uploaded successfuly")
                    } catch (err) { console.log(err) }
                }
                reader.readAsDataURL(acceptedFiles[0])
            }, [])
        })

    const handleChange = (e) => {
        setWebConfig((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const handleCheckboxChange = (e) => {
        switch (e.target.name) {
            case 'preAuth':
                setPreAuthChecked(e.target.checked);
                break;
            case 'showRaised':
                setShowRaisedChecked(e.target.checked);
                break;
            default:
        }
        setWebConfig((prev) => ({
            ...prev,
            [e.target.name]: e.target.checked
        }))
    }
    const callConfig = () => {
        getWebConfig(currentEventId).then((data) => {
            setWebConfig(data);
            setPreAuthChecked(data.preAuth);
            setShowRaisedChecked(data.showRaised);
            setPrimaryColor(data.primaryColour);
            setTextColour(data.primaryTextColour);
            if (data.images.length > 0) {
                console.log('IMAGES')
                for (let i = 0; i < data.images.length; i++) {
                    if (data.images[i].imageType === 0) {
                        console.log("type 0")
                        setImgLogoPreview(data.images[i].url)
                    }
                    else if (data.images[i].imageType === 1) {
                        console.log("type 1")
                        setImgHomePreview(data.images[i].url)
                    }
                }
            }
        })
    }

    useEffect(() => {
        callConfig()
    }, [])

    useEffect(() => {
        console.log(webConfig)
        console.log(webConfig.preAuth)
    }, [webConfig])

    const saveWebConfigChanges = async () => {
        setLoading(true)
        setOpenModal(true)
        const res = await updateWebConfig(webConfig?.id, webConfig)
        const pageRes = await editPage(pageData?.id, pageData)

        if (!res || !pageRes) {
            setModalMessage('Something went wrong...')
        }
        else if (res?.response?.data) {
            setModalMessage('Web Config did not save...')
        }
        else if (pageRes?.response?.data) {
            setModalMessage('Pages did not save...')
        }
        else {
            setModalMessage('Web Config Has Been Saved!')
        }
        setLoading(false)


    }

    return (
        <PageContainer>
            <WidgetContainer >
                <WidgetTitleCont>
                    Website Configuration
                    <SaveBtn onClick={() => saveWebConfigChanges()} >Save</SaveBtn>
                </WidgetTitleCont>
                <ElementsContainer>
                    <TextSpan>
                        Here you can customize your event website.
                    </TextSpan>
                    <PreviewBtn onClick={() => window.open(`${baseURL}/event-landing-page/${currentEventId}/${webConfig?.slug}`, '_blank')} >Preview Website</PreviewBtn>
                </ElementsContainer>
            </WidgetContainer>


            <Widget title={"Colours"} content={
                <ElementsContainer>
                    <GridContainer>
                        <Item>
                            <TitleText>Primary Colour :</TitleText>
                            <ColorBoxConatiner>
                                <ColorHexContainer>
                                    <ColorHex>{webConfig.primaryColour ?? defaultColor}</ColorHex>
                                </ColorHexContainer>
                                <ColorSqrConatiner onClick={() => setShowPicker(true)} >
                                    <ColorSqr color={webConfig.primaryColour ?? defaultColor} />
                                </ColorSqrConatiner>
                            </ColorBoxConatiner>
                            {showPicker &&
                                <PickerContainer onMouseLeave={() => setShowPicker(false)} >
                                    {/* <SketchPicker color={primaryColour ?? defaultColor} onChange={(color) => setPrimaryColor(color.hex)} /> */}
                                    {/* <SketchPicker color={webConfig.primaryColour ?? defaultColor} onChange={(color) => handleColorChange(color.hex, 'primaryColour')} /> */}
                                    <SketchPicker color={webConfig.primaryColour ?? defaultColor} onChange={(color) => setWebConfig((prev) =>
                                        ({ ...prev, primaryColour: color.hex }))} />
                                </PickerContainer>
                            }
                        </Item>
                        <Item>
                            <TitleText>Text Colour :</TitleText>
                            <ColorBoxConatiner>
                                <ColorHexContainer>
                                    <ColorHex>{webConfig.primaryTextColour ?? defaultColor}</ColorHex>
                                </ColorHexContainer>
                                <ColorSqrConatiner onClick={() => setShowTextPicker(true)} >
                                    <ColorSqr color={webConfig.primaryTextColour ?? defaultColor} />
                                </ColorSqrConatiner>
                            </ColorBoxConatiner>
                            {showTextPicker &&
                                <PickerContainer onMouseLeave={() => setShowTextPicker(false)}  >
                                    {/* <SketchPicker color={textColour ?? defaultColor} onChange={(color) => setTextColour(color.hex)} /> */}
                                    <SketchPicker color={webConfig.primaryTextColour ?? defaultColor} onChange={(color) => setWebConfig((prev) =>
                                        ({ ...prev, primaryTextColour: color.hex }))} />
                                </PickerContainer>
                            }
                        </Item>
                    </GridContainer>
                </ElementsContainer>
            } >
            </Widget>

            <Widget title={"General Settings"} content={
                <ElementsContainer>
                    <GridContainer>

                        <Item>
                            <TitleText>Website URL :</TitleText>
                            <TextField name="slug"
                                value={webConfig.slug ?? ''}
                                onChange={handleChange}
                            />
                        </Item>
                        <Item style={{ justifyContent: "flex-start" }} sameLine >
                            <TitleText>Show Rasied So Far</TitleText>
                            <div>
                                <Checkbox name="showRaised" checked={showRaisedChecked} onChange={handleCheckboxChange} />
                            </div>

                        </Item>
                        <Item style={{ justifyContent: "flex-start" }} sameLine >
                            <TitleText>Pre Auth {webConfig.preAuth}</TitleText>
                            <div>
                                <Checkbox name="preAuth" checked={preAuthChecked} onChange={handleCheckboxChange} />
                            </div>
                        </Item>
                    </GridContainer>
                </ElementsContainer>
            } >
            </Widget>

            <Widget title={"Images"} content={
                <ElementsContainer>
                    {/* LOGO */}
                    <GridContainer imageSelect >
                        <Item>
                            <TitleText>Logo Image :</TitleText>
                            <UploaderContainer>
                                <div {...getRootLogoProps()}>
                                    <input name='homePage' type='file' {...getInputLogoProps()} />
                                    {
                                        isLogoDragActive ?
                                            <p>Drop the image here ...</p> :
                                            <DropText>Drag 'n' drop an image here, or click to select one.</DropText>
                                    }
                                    <FileUpload />

                                </div>
                            </UploaderContainer>
                        </Item>
                        {imgLogoPreveiw &&
                            <Item>
                                {/* <button type='button' className='btn' onClick={() => handleImageUpload()} >Click To Upload Image</button> */}
                                <TitleText>{imgLogoWarning}</TitleText>

                                {/* <span>{imgWarning}</span> */}
                                <ImgContainer>
                                    <ImagePreview src={imgLogoPreveiw} alt="preview" />
                                </ImgContainer>

                            </Item>
                        }
                    </GridContainer>

                    {/* HOMEPAGE IMAGE */}
                    <GridContainer imageSelect>
                        <Item>
                            <TitleText>Home Page Image :</TitleText>
                            <UploaderContainer>
                                <div {...getRootHomePageProps()}>
                                    <input name='homePage' type='file' {...getInputHomePageProps()} />
                                    {
                                        isDragActive ?
                                            <p>Drop the image here ...</p> :
                                            <DropText>Drag 'n' drop an image here, or click to select one.</DropText>
                                    }
                                    <FileUpload />
                                </div>
                            </UploaderContainer>
                        </Item>
                        {imgHomePreveiw &&
                            <Item>
                                {/* <button type='button' className='btn' onClick={() => handleImageUpload()} >Click To Upload Image</button> */}
                                <TitleText>{imgHomeWarning}</TitleText>

                                {/* <span>{imgWarning}</span> */}
                                <ImgContainer>
                                    <ImagePreview src={imgHomePreveiw} alt="preview" />
                                </ImgContainer>
                            </Item>
                        }
                    </GridContainer>

                </ElementsContainer>
            } >
            </Widget >

            {/* RICH TEXT EDITOR */}
            < Widget title={"Home Page Editor"} content={
                < ElementsContainer >
                    {pageData &&
                        <RTEContainer >
                            <ReactQuill
                                style={{ minHeight: 300, minWidth: '0', maxWidth: '100%' }}
                                theme="snow"
                                value={pageData?.content}
                                onChange={handlePageChange}
                                modules={modules}
                                formats={formats}
                                preserveWhitespace={true}
                            />
                        </RTEContainer>
                    }

                </ElementsContainer >
            } >
            </Widget >


            <Modal
                open={openModal}
                onClose={() => { setOpenModal(false); setModalMessage(''); window.location.reload() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={boxStyle}>
                    {loading ?
                        <CircularProgress size={80} />
                        :
                        modalMessage &&
                        <>
                            <ModalText>
                                {modalMessage}
                            </ModalText>
                            <ButtonsContainer style={{ justifyContent: 'center' }} >
                                <ModalBtn
                                    single
                                    color='complementary'
                                    onClick={() => { setOpenModal(false); setModalMessage(''); window.location.reload() }}
                                >Close</ModalBtn>
                            </ButtonsContainer>
                        </>
                    }
                </Box>
            </Modal>


        </PageContainer >
    );
}

