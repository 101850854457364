import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { apiURL } from '.';
import { store } from '../redux/store';




const getEvents = async () => {
  const state = store.getState();
  const token = state.user.currentUser.token
  const decoded = jwtDecode(token)

  try {
    const res = await axios.get(`${apiURL}/api/Events`,
      {
        params: { clientId: decoded.nameid },
        headers: { authorization: `Bearer ${token}` }
      })
    // console.log(res.data)

    return res.data

  } catch (err) { console.log(err) }
}


const getAllEvents = async () => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Events`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    // console.log(res.data)

    return res.data

  } catch (err) { console.log(err) }
}




const getSingleEvent = async (id) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Events/${id}`,
      {
        params: { clientId: state.user.currentUser.clientId },
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    console.log(res.data)

    return res.data

  } catch (err) {
    console.log(err)
    return err
  }
}
const getEventBySlug = async (slug) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Events/slug/${slug}`,
      {
      })
    console.log(res.data)

    return res.data

  } catch (err) { console.log(err) }
}

const postEvent = async (eventInfo) => {
  const state = store.getState();

  try {
    const res = await axios.post(`${apiURL}/api/Events`, eventInfo,
      {
        // params: { clientId: state.user.currentUser.clientId },
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) {
    console.log(err)
    return err

  }
}


const updateEvent = async (eventId, eventInfo) => {
  const state = store.getState();

  try {
    const res = await axios.put(`${apiURL}/api/Events/${eventId}`, eventInfo,
      {
        params: { clientId: state.user.currentUser.clientId },
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) {
    console.log(err)
    return err
  }
}




export { getAllEvents, getEvents, getSingleEvent, postEvent, updateEvent, getEventBySlug };
