import CircularProgress from '@mui/material/CircularProgress';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { getAuctionItems } from "../../api/auctionItemsIndex";
import { Timer } from "../../components/Timer";
import { Widget } from "../../components/Widget";
import { lgDesktop, mobile, smDesktop, tablet } from "../../styles/responsive";
import { ElementsContainer, PageContainer, TextSpan } from "../../styles/ThemeStyles";
import { getWebConfig } from "../../api/webConfigIndex";





const GridContainer = styled.div`
width: 100%;
    display: grid;
    gap : 1.8rem;
    grid-template-columns: repeat(3, 1fr);
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};
${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};

${(props) => lgDesktop({
    gridTemplateColumns: '1fr 1fr 1fr',
})};

`
const BodyContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
flex-direction: column;
/* background-color: beige; */
box-sizing: border-box;
padding : 1rem 2rem;
`
const GalleryContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
/* background-color: beige; */
box-sizing: border-box;
`

const GridItem = styled.div`
    width: 100%;
    box-shadow: ${({ theme }) => theme.boxShadow} ;
    box-sizing: border-box;
    /* padding: 1em; */
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: white;
    transform: translateY(0);
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        transform: translateY(-2px);
        transform: scale(1.03);
    }
`
const Item = styled.div`
    /* background-color: #7e6363; */
    /* width : 100% ; */
    display: flex;
    flex-direction: column;
    position: relative;
`

const TitleItem = styled.span`
    font-size : 1.2rem;
    font-weight: 600;
    color : ${({ theme: { colors } }) => colors.darkText};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

`
const DescItem = styled.span`
    font-size : 0.9rem;
    font-weight: 500;
    margin-top: 0.4rem;
    color : ${({ theme: { colors } }) => colors.lightGray};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

`

const IdItem = styled.span`
    color : ${({ theme: { colors } }) => colors.complementary} ;
    padding: .6rem 0;
    font-size : 1rem;
`
const TextContainer = styled.div`
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    /* margin-top: 1.5rem; */
    
`

const ItemImg = styled.img`
    object-fit: contain;
    width: 100%;
    height: 300px;
`
const TopContainer = styled.div`
width: 100%;
position: relative;
display: flex;
cursor: pointer;
`

const InfoContainer = styled.div`
/* background-color: beige; */
position: absolute;
bottom: 0;
right: 0;
transform-origin: center;
display: flex;
justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -webkit-transform: translate3d(0, 50%, 0); 
    -moz-transform: translate3d(0, 50%, 0);  
    transform: translate3d(0, 50%, 0);
box-shadow: ${({ theme }) => theme.boxShadow};
border-radius : 8px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
background-color: white;
z-index: 2;
font-size: 0.8rem;
font-weight: 600;
${(props) => tablet({
    fontSize: '1rem',
})};

`
const TimerContainer = styled.div`
display: flex;
background-color: white;
padding: 0 1rem;
white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;

`

// const BidBtn = styled.button`
//     border : none;
//     font-size : 1rem;
//     padding : .6rem 2rem;
//     cursor : pointer;
//     /* border-radius : .5rem; */
//     border-radius : 8px;

//     box-sizing : border-box;
//     background : ${({ theme: { colors } }) => colors.primary};
//     color : white;
//     white-space: nowrap;
//     text-overflow:ellipsis;

//     &:hover {
//       background : ${({ theme: { colors } }) => colors.primaryLight};
//     }
// `



const BidAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border : none; */
    padding : .5rem 1rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementaryLight};
    color : white;
    

`
const BottomContainer = styled.div`
display: flex;
/* width: 100px; */
padding: 1rem 1.5rem;
flex-direction: column;

`

export const AuctionItemsGallery = () => {

    const [auctionItems, setAuctionItems] = useState('');
    const location = useLocation().pathname.split('/');
    const navigate = useNavigate()
    const { currentUser } = useSelector((state) => state.user)
    const currentEventId = location[2]
    const currentSlug = location[3]
    const placeholderImg = 'https://images.pexels.com/photos/4992652/pexels-photo-4992652.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'

    useEffect(() => {
        getAuctionItems(currentEventId).then((data) => { setAuctionItems(data); console.log(data) })


    }, [])

    const handleCardClick = (itemId) => {
        const navPath = `/event-landing-page/${currentEventId}/${currentSlug}/auction-item/${itemId}`
        navigate(navPath)
    }


    return (

        <PageContainer>
            {/* <TopBar /> */}
            <BodyContainer>
                


                <GalleryContainer >
                    <GridContainer>
                        {auctionItems ? auctionItems?.map((el) => (
                            <GridItem key={el.id} >
                                <Item>
                                    <TopContainer
                                        onClick={() => handleCardClick(el.id)}
                                    >
                                        {el.images.length > 0 ? el.images?.map((c) => (

                                            <ItemImg src={c.url} key={c.id} />
                                        ))
                                            :
                                            <ItemImg src={placeholderImg} />
                                        }
                                        <InfoContainer>
                                            <TimerContainer>
                                                <Timer deadline={parseISO(el?.endDate)} />
                                            </TimerContainer>
                                            <BidAmount>{el.bidType === 0 ?
                                                el.highestBid < 1 ?
                                                    `£${el.startPrice}` :
                                                    `£${el.highestBid}`
                                                : `Sealed Bid`}
                                            </BidAmount>
                                        </InfoContainer>
                                    </TopContainer>
                                    <BottomContainer>
                                        <IdItem>#{el.lotNumber}</IdItem>
                                        <TextContainer>
                                            <TitleItem>{el.title}</TitleItem>
                                            <DescItem>{el.description}</DescItem>

                                        </TextContainer>
                                    </BottomContainer>
                                </Item>

                            </GridItem>
                        ))
                            :
                            <CircularProgress size={80} />

                        }

                    </GridContainer>
                </GalleryContainer>

            </BodyContainer>
            {/* </ElementsContainer>
            } >
            </Widget> */}

        </PageContainer>
    );
}

