import { Checkbox, CircularProgress } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import * as Yup from 'yup';
import { getSetupIntent, register } from '../api/authIndex';
import { getWebConfig } from '../api/webConfigIndex';
import { Widget } from "../components/Widget";
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { ElementsContainer, PageContainer, TextSpan } from "../styles/ThemeStyles";


const GridContainer = styled.div`
    width : 100%;
    display: grid;
    gap : 2rem;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};
${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};

`
const GridItem = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
`
const Item = styled.div`
    
`

const TextInputContainer = styled.div`
    /* background: green; */
`
const TextInputError = styled.div`
    color : red;
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`


const RegisterBtn = styled.button`
    margin-top : 1rem;
    border : none;
    width: 100%;
    font-size : 1rem;
    font-weight: 800;
    padding : 1rem 2rem;
    cursor : pointer;
    border-radius : 2em;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
    &:disabled {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`
const WarningText = styled.div`
    color : red;
`
const SucessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
`
const SuccessTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 800;
  margin: 5% 0 10% 0;
  color: ${({ theme: { colors } }) => colors.complementary};
`


//FORM INPUTS

const FormTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <TextInputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <TextInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </TextInputContainer>
    );
};
const FormMobileInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <TextInputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <PhoneInput
                {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </TextInputContainer>
    );
};

export const Register = () => {
    const location = useLocation().pathname.split('/');
    const [webConfig, setWebConfig] = useState("")
    const schema = {
        firstName: "",
        lastName: "",
        mobile: "",
        userType: 2,
        address1: "",
        address2: "",
        city: "",
        county: "",
        postcode: "",
        stripeId: "",
        clientId: 0,
        eventId: location[2]
    }

    const [userInfo, setUserInfo] = useState(schema)
    const [mobile, setMobile] = useState("")
    const [termsChecked, setTermsChecked] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false)
    const navigate = useNavigate()
    const [disableBtn, setDisableBtn] = useState(false)
    const [warning, setWarning] = useState('')
    const currentEventId = location[2]
    const slug = location[3]
    useEffect(() => {
        getWebConfig(schema.eventId).then((data) => {
            setWebConfig(data);
        });
    }, [])
    const handleChange = (e) => {
        // Using 1 handle change for multiple inputs, the name field of each input has to be the same as the store object.
        setUserInfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
        console.log(userInfo)
    }

    const handleMobileChange = (e) => {
        // Using 1 handle change for multiple inputs, the name field of each input has to be the same as the store object.
        setUserInfo((prev) => ({
            ...prev,
            mobile: e
        }))
        console.log(userInfo)
    }
    const handleTermsChanged = (e) => {

        setTermsChecked(e.target.checked);

    }

    const elements = useElements();
    const stripe = useStripe();

    const handleSubmit = async (e) => {
        // e.preventDefault();
        setDisableBtn(true)

        if (!stripe || !elements) {
            return;
        }

        if (!termsChecked) {
            console.log("terms not accepted");
            setDisableBtn(false);
            return;
        }
        if (webConfig.preAuth) {
            const client_secret = await getSetupIntent();
            await stripe.confirmCardSetup(client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement)

                },
            })
                .then(function (result) {
                    // Handle result.error or result.setupIntent
                    if (result.error) {
                        setDisableBtn(false)
                    }
                    if (result.setupIntent) {

                        // This will be applied only on the next render
                        // setUserInfo((prev) => ({
                        //     ...prev,
                        //     stripeId: result.setupIntent.id
                        // }))

                        // Mutating the state directly is bad practice
                        // userInfo.stripeId = result.setupIntent.id

                        // Make a copy and mutate the value that you need
                        const userCopy = {
                            ...userInfo,
                            stripeId: result.setupIntent.id
                        }
                        register(userCopy).then((data) => {
                            if (data.response) {
                                setWarning(data.response.data)
                            }
                            else {
                                setIsRedirecting(true)
                                setTimeout(() => {
                                    navigate('/login', {
                                        state: {
                                            homeURL: `/event-landing-page/${currentEventId}/${slug}`
                                        }
                                    })
                                }, 2000);
                            }
                            // console.log(JSON.stringify(data))
                        })
                    }

                });
        }
        else {
            const userCopy = {
                ...userInfo
            }
            register(userCopy).then((data) => {
                if (data.response) {
                    setWarning(data.response.data)
                }
                else {
                    setIsRedirecting(true)
                    setTimeout(() => {
                        navigate('/login', {
                            state: {
                                homeURL: `/event-landing-page/${currentEventId}/${slug}`
                            }
                        })
                    }, 2000);
                }
                // console.log(JSON.stringify(data))
            })
            setDisableBtn(false)
        }
    }


    return (
        <Formik
            enableReinitialize
            initialValues={{
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                email: userInfo?.email,
                mobile: userInfo?.mobile,
                password: userInfo?.password,
                confirmPassword: userInfo?.confirmPassword,
                address1: userInfo?.address1,
                address2: userInfo?.address2,
                city: userInfo?.city,
                county: userInfo?.county,
                postcode: userInfo?.postcode
            }}
            validationSchema={Yup.object({
                firstName: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                lastName: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                address1: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                city: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                county: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                postcode: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                email: Yup.string()
                    .email()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                mobile: Yup.string()
                    // .max(11, 'Must be 10 characters or less')
                    .required('Required'),
                password: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                confirmPassword: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .oneOf([Yup.ref('password')], 'Passwords do not match')
                    .required('Required')
            })}
            onSubmit={(values, { setSubmitting }) => {
                console.log("values are " + values)
                if (disableBtn) {
                    return
                }
                else {
                    handleSubmit()
                }
            }}
        >

            <Form style={{ width: "100%" }} >
                <PageContainer>
                    {/* <TopBar /> */}

                    <Widget title={"Register"} type="registration" content={
                        <ElementsContainer>
                            {!isRedirecting ?
                                <GridContainer>
                                    <GridItem>
                                        <Item>
                                            <FormTextInput
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.firstName ?? ""}
                                            />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <FormTextInput
                                                placeholder="Last Name"
                                                name="lastName"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.lastName ?? ""} />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <FormTextInput
                                                placeholder="Email"
                                                name="email"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.email ?? ""} />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <FormMobileInput
                                                defaultCountry='GB'
                                                countryCallingCodeEditable={false}
                                                international
                                                placeholder="Enter phone number"
                                                onCountryChange={(e) => handleMobileChange(e)}
                                                value={userInfo?.mobile ?? ""}
                                                name='mobile'
                                                onChange={(e) => handleMobileChange(e)}
                                                inputComponent={TextInput}

                                            // inputStyle={{ padding: '5rem' }}
                                            // style={{ background: 'red' }}
                                            />

                                            {/*<FormTextInput*/}
                                            {/*   placeholder="Mobile"*/}
                                            {/*   name="mobile"*/}
                                            {/*   onChange={(e) => handleChange(e)}*/}
                                            {/*   value={userInfo?.mobile ?? ""} /> */}
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <FormTextInput
                                                placeholder="Address Line 1"
                                                name="address1"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.address1 ?? ""} />
                                        </Item>
                                    </GridItem>
                                    <GridItem>
                                        <Item>
                                            <FormTextInput
                                                placeholder="City"
                                                name="city"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.city ?? ""} />
                                        </Item>

                                    </GridItem>
                                    <GridItem >
                                        <Item>
                                            <FormTextInput
                                                placeholder="Address Line 2"
                                                name="address2"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.address2 ?? ""} />
                                        </Item>
                                    </GridItem>

                                    <GridItem style={{ gridTemplateColumns: "1fr 1fr" }}>
                                        <Item>
                                            <FormTextInput
                                                placeholder="County"
                                                name="county"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.county ?? ""} />
                                        </Item>
                                        <Item>
                                            <FormTextInput
                                                placeholder="Post Code"
                                                name="postcode"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.postcode ?? ""} />
                                        </Item>
                                    </GridItem>
                                    <GridItem >
                                        <Item>
                                            <FormTextInput
                                                placeholder="Password"
                                                name="password"
                                                type="password"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.password ?? ""} />
                                        </Item>
                                    </GridItem>
                                    <GridItem >
                                        <Item>
                                            <FormTextInput
                                                placeholder="Confirm Password"
                                                type="password"
                                                name="confirmPassword"
                                                onChange={(e) => handleChange(e)}
                                                value={userInfo?.confirmPassword ?? ""} />
                                        </Item>
                                    </GridItem>

                                    {webConfig.preAuth ? (
                                        <GridItem>
                                            <label htmlFor="card-element">Add Card Payment</label>
                                            <p>This card will be used to process any winning bids.</p>
                                            <CardElement id="card-element" />
                                        </GridItem>
                                    ) : <div />
                                    }
                                    <GridItem>
                                    </GridItem>

                                    <GridItem >
                                        <Item>
                                            <TextSpan><Checkbox name="terms" checked={termsChecked} onChange={handleTermsChanged} />I Agree to the <a href="/terms" target="_blank">Terms & Conditions</a></TextSpan>
                                            <RegisterBtn
                                                disabled={disableBtn}
                                            >
                                                {disableBtn ?
                                                    <CircularProgress
                                                        size={30}
                                                        color={'inherit'}
                                                    />
                                                    :
                                                    <span>REGISTER</span>
                                                }
                                            </RegisterBtn>
                                            <WarningText>{warning}</WarningText>
                                        </Item>
                                    </GridItem>
                                </GridContainer>

                                :
                                <SucessDiv>
                                    <SuccessTitle>REGISTRATION SUCCESSFUL!</SuccessTitle>
                                    You will now be redirected to the <b>Login Page</b>
                                </SucessDiv>
                            }
                        </ElementsContainer>
                    } >
                    </Widget>

                </PageContainer>
            </Form>
        </Formik >
    );
}

