import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { ClientsGrid } from "../../components/ClientsGrid";
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { useSelector } from 'react-redux';
import { getClients } from '../../api/clientIndex';
import { useEffect, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';


const CreateContainer = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled(MdAddCircle)`
    font-size : 3rem;
    margin-right: 1rem;
    color : ${({ theme: { colors } }) => colors.complementary};
    cursor : pointer;
`


export const ClientsPage = () => {

    const { currentUser } = useSelector((state) => state.user)

    const [clients, setClients] = useState("")

    const callclients = () => {
        getClients().then((data) => { setClients(data); console.log(data) })

    }

    useEffect(() => {
        callclients()
    }, [currentUser])

    return (
        <PageContainer>

            <Widget title={"Clients Manager"} content={
                <ElementsContainer>
                    <TextSpan>
                        Below is a list of your current and past clients.
                    </TextSpan>
                </ElementsContainer>
            } >
            </Widget>

            {currentUser.userType === 0 &&
                <Widget title={"Create New Client"} content={
                    <ElementsContainer>
                        <CreateContainer>
                            <Link to={'/clients/create'}>
                                <Icon />
                            </Link>
                            <TextSpan>
                                Click here to create a new Client !
                            </TextSpan>
                        </CreateContainer>

                    </ElementsContainer>
                } >
                </Widget>}

            <Widget title={"Your current Clients"} content={
                <ElementsContainer>
                    <ClientsGrid />
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>
    );
}

