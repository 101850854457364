import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  auctionItems: [
    {
      id: 1,
      title: "Signed Poster of Mr. Smith Limited Edition",
      quantity: 5,
      reserve: 560,
      start: 100,
      min: 25,
      bid: 0,
      profit: 120,
      bids: 4,
      pics: 2
    },
    {
      id: 2,
      title: "Signed Photo n.5 out of Spring Collection",
      quantity: 2,
      reserve: 180,
      start: 230,
      min: 125,
      bid: 0,
      profit: 450,
      bids: 8,
      pics: 3
    },
    {
      id: 3,
      title: "Signed Photo n.5 out of Spring Collection",
      quantity: 2,
      reserve: 180,
      start: 230,
      min: 125,
      bid: 0,
      profit: 450,
      bids: 8,
      pics: 3
    },
    {
      id: 4,
      title: "Signed Photo n.5 out of Spring Collection",
      quantity: 2,
      reserve: 180,
      start: 230,
      min: 125,
      bid: 0,
      profit: 450,
      bids: 8,
      pics: 3
    }
    ],
    galleryItems: []
}

export const auctionItemsSlice = createSlice({
  name: 'auctionItems',
  initialState,
  reducers: {
    setAuctionItems: (state, action) => {
      state.auctionItems = action.payload
    },
      setGalleryItems: (state, action) => {
          state.galleryItems = action.payload
      },
  },
})




//Action creators are generated for each case reducer function
export const { setAuctionItems, setGalleryItems } = auctionItemsSlice.actions

export default auctionItemsSlice.reducer