import styled from 'styled-components';
import { lgDesktop, smDesktop, tablet } from './responsive';



export const theme = {

    colors: {
        primary: "#ff6411",
        primaryLight: "#ff8a24",
        complementary: "#0C9BEA",
        complementaryLight: "#5bbaee",
        text: "#7a7a7a",
        darkText: "#616161",
        lightGray: "#9d9d9d",
        bg: "#f0f0f0",
        delete: "#c71b1b",
        deleteLight: "#eb4a4a",
        lightText: "#fff",
        inputBorder: "#999999",
        inputFocusOutline: "#0083da",

    },
    imgBoxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"

}


export const PageContainer = styled.div`
    width: 100%;
    min-height : 100vh;
    position: relative;
    display : flex;
    align-items : center;
    flex-direction : column;
    background : ${({ theme: { colors } }) => colors.bg} ;
`

export const ElementsContainer = styled.div`
    padding: 1.3rem 0;
    /* display: flex; */
    /* justify-content: center; */
`
export const TextSpan = styled.div`
    color : ${({ theme: { colors } }) => colors.text};
    padding: .7rem 0;
    font-size : 1rem;
    font-weight : 500;
`

export const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 7,
    borderRadius: '8px'

};

export const ModalBox = styled.div`
    max-width : 80%;
    min-width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    text-align: center;
    /* boxShadow: 24; */
    padding: 1.5rem;
    border-radius: 8px;

    ${(props) => tablet({
    padding: '3rem',
    minWidth: '0'
})};

`

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    /* background-color: aliceblue; */
    justify-content: space-between;
    /* flex-direction: column; */
    /* margin-top: 1rem; */
`
export const ModalBtn = styled.button`
    width: ${(props) => props.single ? '100%' : '45%'};
    margin : 2rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1rem;
    padding : 1rem 3rem;
    /* border-radius : 9999px; */
    border-radius : 8px;
    box-sizing : border-box;
    background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementary : colors.primary};
    color : white;
    cursor: pointer;
    &:hover {
      background : ${(props) => ({ theme: { colors } }) => props.color === 'complementary' ? colors.complementaryLight : colors.primaryLight};
    }

    ${(props) => tablet({
    // padding: '3rem',
    fontSize: '1.3rem'
})};
`

export const ModalText = styled.span`
    font-size: 1.2rem;
    color :  ${({ theme: { colors } }) => colors.darkText};
    /* background-color: #755c4e; */
    font-weight : 500;
    ${(props) => tablet({
    fontSize: '1.5rem',
})};
${(props) => smDesktop({
    padding: '1.5rem',
})};

${(props) => lgDesktop({
    padding: '1.5rem',
})};
`


// export { theme, PageContainer, ElementsContainer, TextSpan }