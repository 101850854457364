import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { AssignCatalogItemsGrid } from "../../components/AssignCatalogItemGrid";
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { Link, useLocation } from 'react-router-dom';
import { MdAddCircle } from 'react-icons/md';

const CreateContainer = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled(MdAddCircle)`
    font-size : 3rem;
    margin-right: 1rem;
    color : ${({ theme: { colors } }) => colors.complementary};
    cursor : pointer;
`


export const AssignCatalogItem = () => {

    const location = useLocation().pathname

    return (
        <PageContainer>
            <Widget title={"Catalog"} content={
                <ElementsContainer>
                    <TextSpan>
                        Add catalog items to current event.
                    </TextSpan>

                </ElementsContainer>
            } >
            </Widget>


           

            <Widget title={"Catalog Items List"} content={
                <ElementsContainer>
                    <AssignCatalogItemsGrid />
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>
    );
}

