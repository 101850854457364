import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { AuctionItemsGrid } from "../../components/AuctionItemsGrid";
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { Link, useLocation } from 'react-router-dom';
import { MdAddCircle } from 'react-icons/md';
import { useSelector } from 'react-redux';
const CreateContainer = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled(MdAddCircle)`
    font-size : 3rem;
    margin-right: 1rem;
    color : ${({ theme: { colors } }) => colors.complementary};
    cursor : pointer;
`


export const AuctionItems = () => {

    const location = useLocation().pathname
    const { currentUser } = useSelector((state) => state.user)
    return (
        <PageContainer>
            <Widget title={"Auction Items"} content={
                <ElementsContainer>
                    <TextSpan>
                        Add and edit auction items for your event.
                    </TextSpan>

                </ElementsContainer>
            } >
            </Widget>


            <Widget title={"Create new Auction Item"} content={
                <ElementsContainer>
                    <CreateContainer>
                        <Link to={`${location}/create`}>
                            <Icon />
                        </Link>
                        <TextSpan>
                            Click here to create a new Auction !
                        </TextSpan>
                    </CreateContainer>
                    {currentUser.userType === 0 &&
                        <CreateContainer>
                            <Link to={`${location}/assign-catalog`}>
                                <Icon />
                            </Link>
                            <TextSpan>
                                Add Catalog Items
                            </TextSpan>
                        </CreateContainer>
                    }
                </ElementsContainer>
            } >
            </Widget>

            <Widget title={"Auction Items List"} content={
                <ElementsContainer>
                    <AuctionItemsGrid />
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>
    );
}

