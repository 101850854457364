import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


/*START AUCTION ITEMS */

const getAuctionItems = async (eventId) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Auction?eventId=${eventId}`,

    )
    return res.data

  } catch (err) { console.log(err) }

}
const getAdminAuctionItems = async (eventId) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Auction/All?eventId=${eventId}`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      }
    )
    return res.data

  } catch (err) { console.log(err) }

}
const getAuctionItem = async (id) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Auction/${id}`)
    return res.data

  } catch (err) { console.log(err) }
}

const postAuctionItem = async (dispatch, auctionItem) => {
  const state = store.getState();

  try {
    const res = await axios.post(`${apiURL}/api/Auction/`, auctionItem, {
      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    return res.data

  } catch (err) { console.log(err) }
}

const updateAuctionItem = async (dispatch, auctionItem, id) => {
  const state = store.getState();
  console.log(id)

  try {
    const res = await axios.put(`${apiURL}/api/Auction/${id}`, auctionItem, {
      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    })
    return res.data

  } catch (err) { console.log(err) }
}

const deleteAuctionItem = async (id) => {
  const state = store.getState();

  try {
    const res = await axios.delete(`${apiURL}/api/Auction/${id}`, {
      headers: { authorization: `Bearer ${state.user.currentUser.token}` }
    }
    )
    console.log('deleted ' + id)
    return res.data

  } catch (err) { console.log(err) }
}



export { getAuctionItems, getAuctionItem, updateAuctionItem, deleteAuctionItem, postAuctionItem, getAdminAuctionItems };
