import { PageContainer, ElementsContainer, TextSpan } from "../styles/ThemeStyles";
import { Widget } from "../components/Widget"
export const Terms = () => {
    return (
        <PageContainer>
            <Widget title={"Terms & Conditions "} type="terms" content={
                <ElementsContainer>
            
            <p>Winning bidders will be contacted at their table to arrange delivery & payment will be taken via debit/credit card.</p>
            <br/>
            <p>Payment will be taken today after the auction closes. You can win more than one item and in some cases multiple items are available, in which the highest bidders win.</p>
                    <br />
            <p>Each auction bid is legally binding.</p>
                    <br />
            <p>FREE UK DELIVERY ON ALL ITEMS</p>
                    </ElementsContainer>
            }/>
        </PageContainer>

        );



}