import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEvents } from '../api/eventsIndex';
import { EventsGrid } from "../components/EventsGrid";
import { UserBidHistoryGrid } from '../components/UserBidHistoryGrid';
import { Widget } from "../components/Widget";
import { ElementsContainer, PageContainer, TextSpan } from "../styles/ThemeStyles";


export const Home = () => {

    const { currentUser } = useSelector((state) => state.user)

    const [events, setEvents] = useState("")
    const navigate = useNavigate()

    const callEvents = () => {
        getEvents().then((data) => setEvents(data))
    }

    const checkRedirect = () => {
        if (!currentUser) {
            navigate('/login')
        }
    }

    useEffect(() => {
        if (currentUser?.userType !== 2) {
            callEvents()
            checkRedirect()
        }

    }, [])



    return (
        <PageContainer>

            {currentUser?.userType !== 2 ?

                <>
                    <Widget title={"Welcome Back!!!"} content={
                        <ElementsContainer>
                            <TextSpan>
                                Below is a list of your current and past events.
                            </TextSpan>
                            <TextSpan>
                                Click on the relevant event to view and edit details.
                            </TextSpan>
                            <TextSpan>
                                You can also download your Stripe Payout reports to see the funds that have been transferred to your bank acount if you have used The Fan Cave's
                                payment collection service.
                            </TextSpan>
                        </ElementsContainer>
                    } >
                    </Widget>

                    <Widget title={"Your Current Events"} content={
                        <ElementsContainer>
                            <EventsGrid events={events} />
                        </ElementsContainer>
                    } >
                    </Widget>
                </>
                :
                // USER Home
                <>
                    <Widget title={"Welcome!"} content={
                        <ElementsContainer>
                            <TextSpan>
                                This is a user homepage.
                            </TextSpan>

                        </ElementsContainer>
                    } >
                    </Widget>

                    <Widget title={"Bid History"} content={
                        <ElementsContainer>
                            <UserBidHistoryGrid />

                        </ElementsContainer>
                    } >
                    </Widget>

                </>

            }
        </PageContainer>
    );
}

