// or const { useQuill } = require('react-quilljs');
import ImageResize from 'quill-image-resize-module-react';
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useEffect, useState } from 'react';
import { getWebConfig } from '../../api/webConfigIndex';
import { getEventBySlug } from '../../api/eventsIndex';
import ReactQuill, { Quill } from 'react-quill';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getPage } from '../../api/pageIndex';
import { Widget } from "../../components/Widget";
import { mobile } from '../../styles/responsive';
import { ElementsContainer, PageContainer } from "../../styles/ThemeStyles";
import './page.css';



const ImgContainer = styled.div`
    width : 100%;
    position: relative;
    /* ${mobile({ height: "auto" })}; */
    
`

const MainImage = styled.img`
    width : 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 1;

`

const BodyContainer = styled.div`
    margin: 2rem 0 3rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => mobile({
    margin: '1rem 0 3rem 0',
})};
`

const EventPageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    /* padding : 2rem; */

    /* margin-top: 5rem; */
    /* max-width: 100%; */
    /* box-sizing: border-box; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-start; */
    /* background-color: #ffffff; */
`

const RaisedAmount = styled.div`
    position: absolute;
    max-width: 80%;
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin : auto;

    -webkit-transform: translate3d(0, 50%, 0); 
    -moz-transform: translate3d(0, 50%, 0);  
    transform: translate3d(0, 50%, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border : none;
    font-size : 1rem;
    padding : 1rem 3rem;
    border-radius : 9999px;
    box-sizing : border-box;
    background : ${(props) => props.webConfig};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    color : white;
    /* z-index: 99; */
`
const RaisedText = styled.span`
    font-size: .9rem;
`

const RaisedNumber = styled.span`
    font-size: 2.5rem;
`
const HeaderTitle = styled.h1`
    font-size: 2rem;
    /* color: #171717; */
    /* color: ${({ theme: { colors } }) => colors.primary}; */
    color : ${(props) => props.webConfig.primaryColour};
`
const HeaderSubTitle = styled.h1`
    margin-top: 1rem;
    font-size: 1.5rem;
    color: #171717;
`
const TextDescription = styled.h1`
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
`
const HowToList = styled.div`
    margin-top: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    /* color: #262626; */
    color : ${(props) => props.webConfig.primaryTextColour};

`
const ListItem = styled.span`
    font-size: 1.1rem;
`


export const EventLandingPage = () => {

    const [webConfig, setWebConfig] = useState("")
    const [pageInfo, setPageInfo] = useState("")
    const [event, setEvent] = useState("")
    // Use id from search location
    const location = useLocation().pathname.split('/');
    const currentEventId = location[2]
    const slug = location[3]
    const callConfig = () => {
        getWebConfig(currentEventId).then((data) => {
            setWebConfig(data);
            console.log(data)

        })

        getPage(currentEventId).then((data) => {
            setPageInfo(data[0]);
            console.log(data)

        })

        getEventBySlug(slug).then((data) => {
            setEvent(data);
            console.log(data)

        })
    }

    useEffect(() => {
        callConfig()
    }, [])

    Quill.register('modules/imageResize', ImageResize);


    const modules = {
        toolbar: false,
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };


    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <PageContainer>
            {/* <TopBar /> */}
            <ImgContainer>
                {
                    webConfig.images?.filter((el) => el.imageType === 1).map((img) => (
                        <MainImage src={img.url} key={img.id} alt="event" />

                    ))
                }
                {webConfig.showRaised ? (
                    <RaisedAmount webConfig={webConfig.primaryColour} >
                        <RaisedText>Raised so far</RaisedText>
                        <RaisedNumber>£{event.totalRaised}</RaisedNumber>
                    </RaisedAmount>
                ) : <div />
                }

            </ImgContainer>

            <BodyContainer>
                <Widget primaryColour={webConfig.primaryColour} widgetType="landingPage" title={"Welcome!"} content={
                    <ElementsContainer>

                        <EventPageContainer>

                            <ReactQuill
                                style={{ minWidth: '0', maxWidth: '100%' }}
                                value={pageInfo?.content ?? ''}
                                modules={modules}
                                preserveWhitespace={true}
                                className="ql-readonly"
                                readOnly

                            />

                            {/* <div
                                className="ql-display"
                                dangerouslySetInnerHTML={{
                                    __html: pageInfo?.content,
                                }}
                            /> */}

                        </EventPageContainer>

                    </ElementsContainer>
                } >
                </Widget>
            </BodyContainer>
        </PageContainer>
    );
}

