import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { CatalogItemsGrid } from "../../components/CatalogItemsGrid";
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { Link, useLocation } from 'react-router-dom';
import { MdAddCircle } from 'react-icons/md';

const CreateContainer = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled(MdAddCircle)`
    font-size : 3rem;
    margin-right: 1rem;
    color : ${({ theme: { colors } }) => colors.complementary};
    cursor : pointer;
`


export const CatalogItems = () => {

    const location = useLocation().pathname

    return (
        <PageContainer>
            <Widget title={"Catalog"} content={
                <ElementsContainer>
                    <TextSpan>
                        Add and edit catalog items.
                    </TextSpan>

                </ElementsContainer>
            } >
            </Widget>


            <Widget title={"Create new Catalog Item"} content={
                <ElementsContainer>
                    <CreateContainer>
                        <Link to={`${location}/create`}>
                            <Icon />
                        </Link>
                        <TextSpan>
                            Click here to create a new Catalog Item !
                        </TextSpan>
                    </CreateContainer>

                </ElementsContainer>
            } >
            </Widget>

            <Widget title={"Catalog Items List"} content={
                <ElementsContainer>
                    <CatalogItemsGrid />
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>
    );
}

