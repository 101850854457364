import { NavMenu } from "../../components/nav-menu/NavMenu";
import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../redux/eventsSlice";
import { TopBar } from "../../components/nav-menu/TopBar";
import { TextField } from '@mui/material/';
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { getSingleEvent, postEvent, updateEvent } from "../../api/eventsIndex";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { AssignClientGrid } from "../../components/AssignClientGrid";
import { mobile, smDesktop, tablet } from "../../styles/responsive";
import { getClient } from "../../api/clientIndex";
import { CustomBidder } from "../../components/CustomBidder";
import ModalPopUp from "../../components/ModalPopUp";


const GridContainer = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};

`
const GridItem = styled.div`
    
`
const Item = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 0.5fr 1fr;
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};
`

const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const TextInputContainer = styled.div`
    /* background: green; */
`
const TextInputError = styled.div`
    color : red;
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`

//FORM INPUTS

const FormTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <TextInputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <TextInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </TextInputContainer>
    );
};



export const EventEdit = () => {

    let date = new Date()


    const schema = {
        id: 0,
        clientId: 0,
        name: "",
        aidOf: "",
        venue: "",
        campaignDate: date,
        startDate: date,
        endDate: date,
        tables: "12",
        additionalAmountRaised: "0"
    }

    const [eventInfo, setEventInfo] = useState(schema)
    const [clientInfo, setClientInfo] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const location = useLocation().pathname.split('/');
    const currentEventId = location[2]
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => state.user)

    const [openModal, setOpenModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const getInfo = async () => {
        const data = await getSingleEvent(currentEventId)
        const clientData = await getClient(data.clientId)
        setEventInfo(data)
        setClientInfo(clientData)
        console.log(clientData)
        console.log(data)
    }

    useEffect(() => {
        getInfo()
    }, [])

    useEffect(() => {
        console.log(eventInfo)
    }, [eventInfo])

    const handleChange = (e) => {
        // Using 1 handle change for multiple inputs, the name field of each input has to be the same as the store object.
        setEventInfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))

        // console.log(e.target.value)
        console.log(eventInfo)
    }
    const handleStartDate = (timeValues) => {
        setEventInfo((prev) => ({
            ...prev,
            startDate: timeValues
        }))
    }
    const handleEndDate = (timeValues) => {
        setEventInfo((prev) => ({
            ...prev,
            endDate: timeValues
        }))
    }

    return (
        // <>
        <PageContainer>

            <Formik
                enableReinitialize
                initialValues={{
                    name: eventInfo?.name,
                    aidOf: eventInfo?.aidOf,
                    venue: eventInfo?.venue,
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .max(50, 'Must be 50 characters or less')
                        .required('Required'),
                    aidOf: Yup.string()
                        .max(50, 'Must be 50 characters or less')
                        .required('Required'),
                    venue: Yup.string()
                        .max(50, 'Must be 50 characters or less')
                        .required('Required'),
                })}
                onSubmit={async (values) => {
                    // console.log("values are " + values)
                    setOpenModal(true)
                    setModalLoading(true)
                    try {
                        const res = await updateEvent(currentEventId, eventInfo)
                        if (!res) {
                            setModalMessage('Something went wrong...')
                        }
                        else if (res.response?.data) {
                            setModalMessage(res.response?.data)
                        }
                        else {
                            setModalMessage('Edit Successful!')
                        }
                        setModalLoading(false)

                    } catch (error) {
                        console.log(error)
                    }
                }}
            >


                <Form style={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >

                    <Widget title={"Edit Your Event"} content={
                        <ElementsContainer>
                            <TextSpan>
                                Edit your event information.
                            </TextSpan>

                        </ElementsContainer>
                    } >
                    </Widget>


                    <Widget title={"Event Details"} saveEventEdit content={
                        <ElementsContainer>
                            <GridContainer>
                                <GridItem>
                                    <Item>
                                        <TitleInput>Event Name:</TitleInput>
                                        <FormTextInput
                                            type="text"
                                            placeholder=""
                                            name="name"
                                            onChange={(e) => handleChange(e)}
                                            value={eventInfo?.name ?? ""}
                                        />
                                    </Item>
                                </GridItem>
                                <GridItem>
                                    <Item>
                                        <TitleInput>In Aid Of: </TitleInput>
                                        <FormTextInput name="aidOf" size="small" onChange={(e) => handleChange(e)} value={eventInfo?.aidOf ?? ""} />
                                    </Item>
                                </GridItem>
                                <GridItem>
                                    <Item>
                                        <TitleInput>Venue: </TitleInput>
                                        <FormTextInput name="venue" size="small" onChange={(e) => handleChange(e)} value={eventInfo?.venue ?? ""} />
                                    </Item>
                                </GridItem>
                            </GridContainer>
                        </ElementsContainer>
                    } >
                    </Widget>

                    {/* ONLY ADMIN CAN SET THE DATE AND ASSIGN CLIENT*/}
                    {currentUser.userType === 0 &&
                        <>
                            < Widget title={"Event Date"} eventInfo={eventInfo} content={
                                <ElementsContainer>
                                    <GridContainer>
                                        <Item>
                                            <TitleInput>Start Date:</TitleInput>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    // label="Date&Time picker"
                                                    value={eventInfo?.startDate ?? ""}
                                                    name='startDate'
                                                    onChange={handleStartDate}
                                                    inputFormat={"dd/MM/yyyy hh:mm a"}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>

                                        </Item>
                                        <Item>
                                            <TitleInput>End Date:</TitleInput>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    // label="Date&Time picker"
                                                    value={eventInfo?.endDate ?? ""}
                                                    name='endDate'
                                                    onChange={handleEndDate}
                                                    inputFormat={"dd/MM/yyyy hh:mm a"}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Item>
                                    </GridContainer>
                                </ElementsContainer>
                            } >
                            </Widget>
                        </>
                    }

                    <ModalPopUp
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        modalLoading={modalLoading}
                        reload
                    />

                </Form>
            </Formik >

            <Widget title={"Custom Bidder"} content={
                <ElementsContainer>
                    <CustomBidder />
                </ElementsContainer>
            } >
            </Widget>
        </PageContainer>

        // {/* </> */ }

    );
}

