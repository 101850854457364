import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { closeMenu } from '../../redux/mobileMenuSlice';
import { mobile, tablet } from '../../styles/responsive';
import { getWebConfig } from "../../api/webConfigIndex";

const SidebarLink = styled(Link)`
  display: flex;
  color: ${({ theme: { colors } }) => colors.text};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 55px;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 700;

  &:hover {
    background: ${props => props.primaryColour || (({ theme: { colors } }) => colors.primary)};
    color: ${({ theme: { colors } }) => colors.lightText};
    cursor: pointer;
  }
`;
const SidebarA = styled.a`
  
  display: flex;
  color: ${({ theme: { colors } }) => colors.text};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 55px;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 700;

  &:hover {
    background: ${props => props.primaryColour || (({ theme: { colors } }) => colors.primary)};
    color: ${({ theme: { colors } }) => colors.lightText};
    cursor: pointer;
  }
`;
const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const NavLinkContainer = styled.div`
  display : flex;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
`;

const SubIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
`;

const DropdownLink = styled(Link)`
  background: #4b515a;
  min-height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 1em;
  &:hover {
    background: ${props => props.primaryColour || (({ theme: { colors } }) => colors.primary)};
    color: ${({ theme: { colors } }) => colors.lightText};
    cursor: pointer;
  }
  ${(props) => mobile({
  paddingLeft: '2rem',
  paddingRight: '1rem'
})};
  ${(props) => tablet({
  paddingLeft: '2rem',
  paddingRight: '1rem'
})};

`;

const DeepDropdownLink = styled(Link)`
  background: #4b515a;
  min-height: 60px;
  padding-left: 4rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: .9em;
  &:hover {
    background: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.lightText};
    cursor: pointer;
  }
`;



export const SubMenu = ({ item }) => {
  // const [subnav, setSubnav] = useState(true);
  const [eventSubnav, setEventSubnav] = useState(true);
  const [subSubNav, setSubSubNav] = useState(false);
  const location = useLocation().pathname.split('/');
  const eventsLoc = location[1]
  const otherLoc = location[2]
  const dispatch = useDispatch()

  const currentEventId = useSelector((state) => state.events.currentEventId)
    const [webConfig, setWebConfig] = useState("")
  // const showSubnav = () => setSubnav(!subnav);
  const showSubSubNav = () => setSubSubNav(!subSubNav);

  useEffect(() => {
      
    const checkLocation = () => {
      if (eventsLoc === 'events' && Number(otherLoc)) {
        // console.log('showww')
        setEventSubnav(true)
      }
      else {
        setEventSubnav(false)
      }
    }
     
    checkLocation()

  }, [currentEventId, location])

    useEffect(() => {
        if (eventsLoc === 'event-landing-page' && Number(otherLoc)) {
            getWebConfig(otherLoc).then((data) => {
                setWebConfig(data);
            });
        }

    }, [])

  return (
      <>
          {item.path ?
              <SidebarLink to={item.path ? item.path : location} onClick={() => dispatch(closeMenu())} primaryColour={webConfig.primaryColour}>
                  <NavLinkContainer>
                      <IconContainer>
                          {item.icon}
                      </IconContainer>
                      <SidebarLabel>{item.title}</SidebarLabel>
                  </NavLinkContainer>
              </SidebarLink> 
              :
                <SidebarA href={item.externalPath ? item.externalPath : location} onClick={() => dispatch(closeMenu())} primaryColour={webConfig.primaryColour} target="_blank">
            <NavLinkContainer>
                <IconContainer>
                    {item.icon}
                </IconContainer>
                <SidebarLabel>{item.title}</SidebarLabel>
            </NavLinkContainer>
            </SidebarA> 
          }
      {eventSubnav &&
        item.eventSubNav?.map((item, index) => {
          return (
            <div key={index} >
              <DropdownLink to={item.path}
                      onClick={() => { dispatch(closeMenu()); if (item.subSubNav) { return showSubSubNav } }} primaryColour={webConfig.primaryColour}
              >
                <SubIconContainer>{item.icon}</SubIconContainer>
                <SidebarLabel>{item.title}</SidebarLabel>
                <div>
                  {item.subSubNav && subSubNav
                    ? item.iconOpened
                    : item.subSubNav
                      ? item.iconClosed
                      : null}
                </div>
              </DropdownLink>
              {subSubNav &&
                item.subSubNav?.map((item, index) => {
                  return (
                    <DeepDropdownLink to={item.path} key={index} >
                      <SubIconContainer>{item.icon}</SubIconContainer>
                      <SidebarLabel>{item.title}</SidebarLabel>
                    </DeepDropdownLink>
                  );
                })}
            </div>
          );
        })}
    </>
  );
};
