import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { EventsGrid } from "../../components/EventsGrid";
import { TopBar } from "../../components/nav-menu/TopBar";
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { useSelector } from 'react-redux';
import { getEvents } from '../../api/eventsIndex';
import { useEffect, useState } from 'react';
// import * as MdIcons from 'react-icons/md';
import { MdAddCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CustomBidder } from '../../components/CustomBidder';


const CreateContainer = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled(MdAddCircle)`
    font-size : 3rem;
    margin-right: 1rem;
    color : ${({ theme: { colors } }) => colors.complementary};
    cursor : pointer;
`


export const EventsPage = () => {

    const { currentUser } = useSelector((state) => state.user)

    const [events, setEvents] = useState("")

    const callEvents = () => {
        getEvents().then((data) => setEvents(data))
    }

    useEffect(() => {
        callEvents()
    }, [currentUser])

    return (
        <PageContainer>

            <Widget title={"Events"} content={
                <ElementsContainer>
                    <TextSpan>
                        Below is a list of your current and past events.
                    </TextSpan>
                </ElementsContainer>
            } >
            </Widget>

            {currentUser.userType === 0 &&
                <Widget title={"Create new Event"} content={
                    <ElementsContainer>
                        <CreateContainer>
                            <Link to={'/events/create-event'}>
                                <Icon />
                            </Link>
                            <TextSpan>
                                Click here to create a new Event !
                            </TextSpan>
                        </CreateContainer>

                    </ElementsContainer>
                } >
                </Widget>}

            <Widget title={"Your current Campaigns"} content={
                <ElementsContainer>
                    <EventsGrid />
                </ElementsContainer>
            } >
            </Widget>


        </PageContainer>
    );
}

