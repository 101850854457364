import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { getClients } from '../api/clientIndex';
import { getAllEvents, getEvents } from '../api/eventsIndex';
import { setCurrentEventId } from '../redux/eventsSlice';
import { theme } from '../styles/ThemeStyles';

const EditBtn = styled.button`
    border : none;
    font-size : 1rem;
    padding : .6rem 2rem;
    cursor : pointer;
    border-radius : .5rem;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`




export const EventsGrid = () => {

  const dispatch = useDispatch()
  const currentUserType = useSelector((state) => state?.user?.currentUser?.userType)

  const clientColumns = () => {
    return (
      [
        {
          field: '_id',
          hide: true,
        },
        {
          field: 'eventName',
          headerName: 'Event name',
          flex: 2,
          editable: false,
        },
        {
          field: 'aidOf',
          headerName: 'Aid Of',
          flex: 2,
          editable: false,
        },
        {
          field: 'eventDate',
          headerName: 'Event Date',
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 2,
          // sortable: true,
          editable: false,
        },
        {
          field: 'edit',
          headerName: 'Edit',
          headerAlign: 'center',
          align: 'center',
          editable: false,
          sortable: false,
          flex: 1,
          renderCell: (param) => {
            return (
              <Link
                to={`/events/${param.row._id}/edit`}
              >
                <EditBtn
                  onClick={() => dispatch(setCurrentEventId(param.row._id))}
                >Edit</EditBtn>
              </Link>
            )
          }
        },
      ]
    )
  }

  const adminColumns = () => {
    return (
      [
        {
          field: '_id',
          hide: true,
        },
        {
          field: 'eventName',
          headerName: 'Event name',
          flex: 2,
          editable: false,
        },
        {
          field: 'aidOf',
          headerName: 'Aid Of',
          flex: 2,
          editable: false,
        },
        {
          field: 'clientName',
          headerName: 'Client',
          flex: 2,
          editable: false,
        },
        {
          field: 'eventDate',
          headerName: 'Event Date',
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 2,
          // sortable: true,
          editable: false,
        },
        {
          field: 'edit',
          headerName: 'Edit',
          headerAlign: 'center',
          align: 'center',
          editable: false,
          sortable: false,
          flex: 1,
          renderCell: (param) => {
            return (
              <Link
                to={`/events/${param.row._id}/edit`}
              >
                <EditBtn
                  onClick={() => dispatch(setCurrentEventId(param.row._id))}
                >Edit</EditBtn>
              </Link>
            )
          }
        },
      ]
    )
  }

  const [rows, setRows] = useState([]);
  //redux
  // const events = useSelector((state) => state.events)
  const [events, setEvents] = useState('');
  const [clients, setClients] = useState('');

  useEffect(() => {
    if (currentUserType === 0) {
      getClients().then((data) => setClients(data))
      getAllEvents().then((data) => setEvents(data))
    }
    else {
      getEvents().then((data) => setEvents(data))
    }


  }, [])

  const getClientName = (clientId) => {
    // console.log(clients)
    if (clients) {
      const name = clients?.filter((el) => el.id === clientId)[0]?.name
      return name
    }

  }

  useEffect(() => {
    if (events) {
      if (currentUserType === 0) {
        setRows(
          events?.map((el) => ({
            _id: el.id, eventName: el.name, aidOf: el.aidOf, clientName: getClientName(el.clientId),
            eventDate: format(parseISO(el.startDate), "dd/MM/yyyy hh:mm a")
          }))
        )
      }
      else {
        setRows(
          events?.map((el) => ({
            _id: el.id, eventName: el.name, aidOf: el.aidOf,
            eventDate: format(parseISO(el.startDate), "dd/MM/yyyy hh:mm a")
          }))
        )
      }

    }
  }, [events])

  return (
    <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {events ?
        <DataGrid
          rows={rows}
          getRowId={(row) => row?._id}
          columns={
            currentUserType === 0 ?
              adminColumns()
              :
              clientColumns()
          }

          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnCliclk={true}
          disableColumnMenu
          sx={{
            color: theme.colors.text,
            border: 'none',


            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },

            '& .MuiDataGrid-columnHeader': {

            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
            },
          }}
        />
        :
        <CircularProgress size={80} />
      }
    </div>
  )
}
