import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


/*START AUCTION ITEMS */

const getCatalogItems = async (eventId) => {
    const state = store.getState();

    try {
        const res = await axios.get(`${apiURL}/api/Catalog`,
            {
                headers: { authorization: `Bearer ${state.user.currentUser.token}` }
            }
        )
        return res.data

    } catch (err) { console.log(err) }

}

const getCatalogItem = async (id) => {
    const state = store.getState();

    try {
        const res = await axios.get(`${apiURL}/api/Catalog/${id}`, {
            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        })
        return res.data

    } catch (err) { console.log(err.response) }
}

const postCatalogItem = async (dispatch, catalogItem) => {
    const state = store.getState();

    try {
        const res = await axios.post(`${apiURL}/api/Catalog/`, catalogItem, {
            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        })
        return res.data

    } catch (err) { console.log(err) }
}

const updateCatalogItem = async (dispatch, catalogItem, id) => {
    const state = store.getState();
    console.log(id)

    try {
        const res = await axios.put(`${apiURL}/api/Catalog/${id}`, catalogItem, {
            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        })
        return res.data

    } catch (err) { console.log(err) }
}

const deleteCatalogItem = async (id) => {
    const state = store.getState();

    try {
        const res = await axios.delete(`${apiURL}/api/Catalog/${id}`, {
            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        }
        )
        console.log('deleted ' + id)
        return res.data

    } catch (err) { console.log(err) }
}

const assignCatalogItem = async (dispatch, assignCatalogItem) => {
    const state = store.getState();

    try {
        const res = await axios.post(`${apiURL}/api/Auction/AddCatalogItem`, assignCatalogItem, {
            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        })
        return res.data

    } catch (err) { console.log(err) }
}

export { getCatalogItems, getCatalogItem, updateCatalogItem, deleteCatalogItem, postCatalogItem, assignCatalogItem };
