import { Box, CircularProgress, Modal } from '@mui/material/';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { getBidsReport } from '../api/bidIndex';
import { getUser } from '../api/usersIndex';
import { mobile, tablet } from '../styles/responsive';
import { boxStyle, ButtonsContainer, ModalBtn, ModalText, theme } from '../styles/ThemeStyles';



const GridContainer = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
    ${(props) => mobile({
  gridTemplateColumns: '1fr',
})};

${(props) => tablet({
  gridTemplateColumns: '1fr',
})};
`

const Item = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr 1.5fr;
    ${(props) => mobile({
  gridTemplateColumns: '1fr 1fr',
})};
`
const TitleInput = styled.span`
    /* background-color: aliceblue; */
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`


export const BidsFeedGrid = ({ filter }) => {

  const mostBidsColumns = [
    {
      field: '_id',
      headerName: '#',
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
      editable: false,
    },
    {
      field: 'count',
      headerName: 'Count',
      flex: 2,
      editable: false,
    },
  ];

  const bidsColumns = [
    {
      field: '_id',
      headerName: '#',
      flex: 1,
    },
    {
      field: 'auctionItemTitle',
      headerName: 'Item Title',
      flex: 3,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 2,
      editable: false,
    },
    {
      field: 'bidderName',
      headerName: 'Bidder',
      flex: 3,
      editable: false,
    },
    {
      field: 'bidStatus',
      headerName: 'Status',
      flex: 3,
      editable: false,
    },
    {
      field: 'tableNumber',
      headerName: 'Table #',
      flex: 1,
      editable: false,
    },
    {
      field: 'eventsTitle',
      headerName: 'Event',
      flex: 3,
      editable: false,
    },

  ];
    function getStatus(status) {
        switch (status) {
            case 0:
                return "Bid"
            case 1:
                return "Payment Failed"
            case 2:
                return "Paid"
            case 3:
                return "Processing"
            case 4:
                return "Link Sent"
            default:
        }
    }

  const [rows, setRows] = useState([]);
  const [bidsFeedInfo, setBidsFeedInfo] = useState('');
  const location = useLocation().pathname.split('/');
  const currentEventId = location[2]
  const currentUrl = location[0]


  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [userInfo, setUserInfo] = useState('')

  const handleReportRequest = async (filter) => {

    const data = await getBidsReport(currentEventId, filter)
    setBidsFeedInfo(data)
  }

  useEffect(() => {
    handleReportRequest(filter)
  }, [filter])



  useEffect(() => {
    if (bidsFeedInfo && filter === '0') {
      setRows(
        bidsFeedInfo?.map((el) => ({
          _id: el.auctionItemId,
          count: el.count,
          title: el.title,
        }))
      )
    }
    else if (bidsFeedInfo && filter !== '0') {
      setRows(
        bidsFeedInfo?.map((el) => ({
          _id: el.id,
          auctionItemTitle: el.auctionItemTitle,
          amount: el.amount,
          userId: el.userId,
          bidderName: el.bidderName,
          eventsTitle: el.eventsTitle,
          auctionItemId: el.auctionItemId,
          tableNumber: el.tableNumber,
          bidStatus: getStatus(el.bidStatus)
        }))
      )
    }
  }, [bidsFeedInfo, filter])


  const handleCellClick = async (param) => {

    switch (param.field) {

      case "auctionItemTitle":
        window.open(`${currentUrl}/events/${currentEventId}/auction-items/${param.row.auctionItemId}`, '_blank')

        break;

      case "bidderName":
        setLoading(true)
        setOpenModal(true)
        const userData = await getUser(param.row.userId)
        setUserInfo(userData)
        setLoading(false)

        break;

      default:
        return
    }

    console.log(param)
  }

  useEffect(() => {
    console.log(rows)
  }, [rows])



  return (
    <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {filter === '0' && bidsFeedInfo ?
        <DataGrid
          rows={rows}
          getRowId={(row) => row?._id}
          columns={mostBidsColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnCliclk={true}
          disableColumnMenu
          sx={{
            color: theme.colors.text,
            border: 'none',


            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
              cursor: 'pointer',
              background: '#f0f0f0'
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'transparent',
              background: '#f0f0f0'
            },

            '& .MuiDataGrid-columnHeader': {

            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
            },
            '& .MuiDataGrid-cell:hover': {
              // backgroundColor: 'aliceblue',

            }
          }}
        />
        :
        filter !== '0' && bidsFeedInfo ?

          <DataGrid
            rows={rows}
            getRowId={(row) => row?._id}
            columns={bidsColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            // disableSelectionOnCliclk={true}
            disableColumnMenu
            // onRowClick={handleRowClick}
            onCellClick={handleCellClick}
            sx={{
              color: theme.colors.text,
              border: 'none',


              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'transparent',
                cursor: 'pointer',
                background: '#f0f0f0'
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: 'transparent',
              },
              '& .MuiDataGrid-row.Mui-selected:hover': {
                backgroundColor: 'transparent',
                background: '#f0f0f0'
              },

              '& .MuiDataGrid-columnHeader': {

              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '700',
              },
              '& .MuiDataGrid-cell:hover': {
                // backgroundColor: 'aliceblue',

              }
            }}
          />

          :
          <CircularProgress size={80} />
      }

      <Modal
        open={openModal}
        onClose={() => { setOpenModal(false); setModalMessage('') }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            userInfo &&
            <>
              <ModalText>

                <GridContainer>
                  <Item>
                    <TitleInput> First Name:</TitleInput>
                    <TitleInput>{userInfo?.firstName}</TitleInput>
                  </Item>
                  <Item>
                    <TitleInput>Last Name :</TitleInput>
                    <TitleInput>{userInfo?.lastName}</TitleInput>

                  </Item>
                  <Item>
                    <TitleInput>Email :</TitleInput>
                    <TitleInput>{userInfo?.email}</TitleInput>
                  </Item>
                  <Item>
                    <TitleInput>Mobile :</TitleInput>
                    <TitleInput>{userInfo?.mobile}</TitleInput>
                  </Item>
                  <Item>
                    <TitleInput>Address 1 :</TitleInput>
                    <TitleInput>{userInfo?.address?.address1}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>Address 2 :</TitleInput>
                    <TitleInput>{userInfo?.address?.address2}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>City:</TitleInput>
                    <TitleInput>{userInfo?.address?.city}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>County:</TitleInput>
                    <TitleInput>{userInfo?.address?.county}</TitleInput>
                  </Item>

                  <Item>
                    <TitleInput>Postcode:</TitleInput>
                    <TitleInput>{userInfo?.address?.postcode}</TitleInput>
                  </Item>

                </GridContainer>
              </ModalText>

              <ButtonsContainer style={{ justifyContent: 'center' }} >
                <ModalBtn
                  single
                  color='complementary'
                  onClick={() => { setOpenModal(false); setModalMessage('') }}
                >Close</ModalBtn>
              </ButtonsContainer>
            </>
          }
        </Box>
      </Modal>

    </div>
  )
}
