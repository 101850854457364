import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from "react-router-dom";
import styled, { ThemeProvider } from 'styled-components';
import { NavMenu } from "./components/nav-menu/NavMenu";
import { TopBar } from "./components/nav-menu/TopBar";
import ScrollToTop from "./components/ScrollToTop";
import { PaymentForm } from "./components/Stripe/PaymentForm";
import { AuctionItemEdit } from "./pages/AuctionItems/AuctionItemEdit";
import { AuctionItems } from "./pages/AuctionItems/AuctionItems";
import { CreateAuctionItem } from "./pages/AuctionItems/CreateAuctionItem";
import { ClientsPage } from "./pages/Clients/ClientsPage";
import { CreateClient } from "./pages/Clients/CreateClient";
import { ViewClient } from "./pages/Clients/ViewClient";
import { BidsFeed } from "./pages/Events/BidsFeed";
import { CreateEvent } from "./pages/Events/CreateEvent";
import { EventEdit } from "./pages/Events/EventEdit";
import { EventsPage } from "./pages/Events/EventsPage";
import { WebConfig } from "./pages/Events/WebConfig";
import { HelpPage } from "./pages/HelpPage";
import { CatalogItems } from "./pages/Catalog/CatalogItems";
import { CatalogItemEdit } from "./pages/Catalog/CatalogItemEdit"
import { CreateCatalogItem } from "./pages/Catalog/CreateCatalogItem"
import { AssignCatalogItem } from "./pages/Catalog/AssignCatalogItem"
import { Home } from "./pages/Home";
import { AuctionItemsGallery } from "./pages/LandingPage/AuctionItemsGallery";
import { EventLandingPage } from "./pages/LandingPage/EventLandingPage";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { MakeADonationPage } from "./pages/MakeADonationPage";
import { Profile } from "./pages/Profile";
import { Register } from "./pages/Register";
import { UnauthorizedPage } from "./pages/UnauthorizedPage";
import { Terms } from "./pages/terms";
import { ProfileUser } from "./pages/ProfileUser";
import { ViewAuctionItem } from "./pages/ViewAuctionItem";
import { mobile } from "./styles/responsive";
import { PageContainer, theme } from './styles/ThemeStyles';
import { AuctionItemsPreview } from './pages/AuctionItemsPreview';


const Container = styled.div`
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    /* position:${(props) => props.isMenu ? 'fixed' : 'relative'}; */
;
`
const MenuOverlay = styled.div`
    display: none;
    position: fixed;
    left : 0;
    top : 0;
    background-color: rgb(0, 0, 0);
    height: 100vh;
    width: 100vw;
    opacity:${(props) => props.isOpen ? '0.9' : '0.0'};
    z-index: ${(props) => props.isOpen ? '20' : '-1'};
    transition: all 0.25s ease-in-out;
    
    ${(props) => mobile({
  display: 'block',
})};

`

function App() {

  const location = useLocation().pathname.split('/')
    const currentLocation = location[location.length - 1]
    console.log(location);
  const { currentUser } = useSelector((state) => state.user)
  const isOpen = useSelector((state) => state.mobileMenu.isOpen)

    return (
        location.includes('auction-preview') !== true ?
    <ThemeProvider theme={theme} >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container >
          <MenuOverlay isOpen={isOpen} />

          {
            currentLocation !== 'login' && currentLocation !== 'logout' && 
            <NavMenu />
          }
          <PageContainer>
            {
              currentLocation !== 'login' && currentLocation !== 'logout' && 
              <TopBar />
            }
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />

                <Route path="/events" element={<EventsPage />} />
                <Route path="/events/:id/edit" element={<EventEdit />} />
                <Route path="/events/create-event" element={<CreateEvent />} />
                <Route path="/events/:eventId/webconfig" element={<WebConfig />} />
                <Route path="/events/:eventId/bids-feed" element={<BidsFeed />} />


                <Route path="/events/:eventId/auction-items" element={<AuctionItems />} />
                <Route path="/events/:eventId/auction-items/create" element={<CreateAuctionItem />} />
                <Route path="/events/:eventId/auction-items/:id" element={<AuctionItemEdit />} />
                <Route path="/events/:eventId/auction-items/assign-catalog" element={<AssignCatalogItem />} />
                                    

                <Route path="/event-landing-page/:eventId/:slug" element={<EventLandingPage />} />
                <Route path="/event-landing-page/:eventId/:slug/auction-gallery" element={<AuctionItemsGallery />} />
                <Route path="/event-landing-page/:eventId/:slug/register" element={<Register />} />
                <Route path="/event-landing-page/:eventId/:slug/unauthorized" element={<UnauthorizedPage />} />

                <Route path="/event-landing-page/:eventId/:slug/auction-item/:id" element={<ViewAuctionItem />} />

                <Route path="/clients" element={<ClientsPage />} />
                <Route path="/clients/create" element={<CreateClient />} />
                <Route path="/clients/:clientId/view" element={<ViewClient />} />


                <Route path="/profile" element={<Profile />} />
                {/* <Route path="/event-landing-page/:eventId/:slug/profile" element={<ProfileUser />} /> */}
                <Route path="/event-landing-page/:eventId/:slug/profile" element={<Profile />} />
                <Route path="/event-landing-page/:eventId/:slug/donation" element={<MakeADonationPage />} />
                <Route path="/event-landing-page/:eventId/:slug/help" element={<HelpPage />} />
                <Route path="/event-landing-page/:eventId/:slug/logout" element={<Logout />} />
                <Route path="/event-landing-page/:eventId/:slug/login" element={<Login />} />

                <Route path="/catalog/catalog-items" element={<CatalogItems /> } />    
                <Route path="/catalog/catalog-items/:id" element={<CatalogItemEdit /> } />                      
                <Route path="/catalog/catalog-items/create" element={<CreateCatalogItem /> } />   

                <Route path="/register" element={<Register />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/card" element={<PaymentForm />} />

                
                </Routes>
            </ScrollToTop>
          </PageContainer>

        </Container>
      </LocalizationProvider>
    </ThemeProvider>
            :
            <Routes>
                <Route path="/auction-preview/:slug" element={<AuctionItemsPreview />} />
            </Routes>
  );
}

export default App;
