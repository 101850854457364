import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { useCallback, useState, useEffect } from 'react';
import { postCatalogItem } from "../../api/catalogItemsIndex";
import { postImage } from "../../api/imagesIndex";
import { getSingleEvent } from "../../api/eventsIndex"
import { Modal, TextField, CircularProgress, Box } from '@mui/material/';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { useDropzone } from 'react-dropzone';
import { PageContainer, ElementsContainer, ModalBtn, ModalText, ButtonsContainer, boxStyle } from "../../styles/ThemeStyles";
import { mdTablet, mobile, smDesktop, tablet } from '../../styles/responsive';



const GridContainer = styled.div`
    display: grid;
    gap : 4rem;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => smDesktop({
    gridTemplateColumns: '1fr 1fr',
})};

`
const GridHalf = styled.div`
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
`
const Item = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 0.7fr 1fr;
    ${(props) => mobile({
    gridTemplateColumns: '1fr',
})};

${(props) => tablet({
    gridTemplateColumns: '1fr',
})};

${(props) => mdTablet({
    gridTemplateColumns: '0.5fr 1fr',
})};


`

const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`
const InputContainer = styled.div`
    /* background: green; */
    align-self : center;
`

const TextInputError = styled.div`
    color : red;
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`
const SelectInput = styled.select`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`

const TextareaInput = styled.textarea`
    resize : vertical ;
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`

const TitleText = styled.span`
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`

const UploaderContainer = styled.div`
    max-width: 100%;
    height: 100px;
    border : 2px solid grey;
`
const ImagePreview = styled.img`
    object-fit: cover;
    max-width: 100%;
    /* height: 100px; */
`

//FORM INPUTS


const FormSelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <InputContainer>
            <SelectInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError className="error">{meta.error}</TextInputError>
            ) : null}
        </InputContainer>
    );
};

const FormTextareaInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <InputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <TextareaInput {...field} {...props} rows="4" />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </InputContainer>
    );
};

const FormCheckBoxInput = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <div>
            <label className="checkbox-input">
                <input type="checkbox" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};

const FormTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <InputContainer>
            <label htmlFor={props.id || props.name}>{label}</label>
            <TextInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <TextInputError >{meta.error}</TextInputError>
            ) : null}

        </InputContainer>
    );
};



export const CreateCatalogItem = () => {

    let date = new Date()

    const location = useLocation().pathname.split('/');
    const currentEventId = location[2]
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const schema = {
        id: 0,
        title: "",
        description: "",
        donorName: "",
        status: 0,
        startPrice: 0,
        qty: 0,
        supplyPrice: 0,
        bidType: 0,
        images: []
    }

    const [event, setEvent] = useState('')
    const [catalogItemInfo, setCatalogItemInfo] = useState(schema)
    const [imagesPreview, setImagesPreview] = useState('')
    const [imagesWarning, setImagesWarning] = useState('')

    

    const handleChange = (e) => {
        // Using 1 handle change for multiple inputs, the name field of each input has to be the same as the store object.
        setCatalogItemInfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))

        // console.log(e.target.value)
        //console.log(auctionItemInfo)
    }

    const handleIntChange = (e) => {
        // Using 1 handle change for multiple inputs, the name field of each input has to be the same as the store object.
        setCatalogItemInfo((prev) => ({
            ...prev,
            [e.target.name]: Number(e.target.value)
        }))
    }

    const handleStartDate = (timeValues) => {
        setCatalogItemInfo((prev) => ({
            ...prev,
            startDate: timeValues
        }))
    }
    const handleEndDate = (timeValues) => {
        setCatalogItemInfo((prev) => ({
            ...prev,
            endDate: timeValues
        }))
    }

    const {
        getRootProps: getRootImagesProps,
        getInputProps: getInputImagesProps,
        isDragActive: isDragActive } = useDropzone({
            accept: 'image/jpeg,image/png', onDrop: useCallback(acceptedFiles => {
                setImagesWarning("Loading Image...")
                let reader = new FileReader()
                let formData = new FormData()
                const fileObjects = acceptedFiles.map(file => {
                    console.log(file)
                    formData.append('file', file, file.name)
                })
                reader.onload = async () => {
                    try {
                        // console.log(acceptedFiles)
                        const res = await postImage(formData)
                        setImagesPreview(res)
                        // const { images: prevImages } = webConfig
                        // console.log("Destructuring " + prevImages)

                        setCatalogItemInfo((prev) => ({
                            ...prev,
                            images: [...prev.images,
                            {
                                id: 0,
                                url: res,
                                imageType: 3,
                                linkId: 0
                            }
                            ]
                        }))
                        console.log(catalogItemInfo)
                        setImagesWarning("Uploaded successfuly")
                    } catch (err) { console.log(err) }
                }
                reader.readAsDataURL(acceptedFiles[0])
            }, [])
        })


    return (
        <Formik
            enableReinitialize
            initialValues={{
                bidType: catalogItemInfo?.bidType,
                status: catalogItemInfo?.status,
                title: catalogItemInfo?.title,
                description: catalogItemInfo?.description,
                donorName: catalogItemInfo?.donorName,
                startDate: catalogItemInfo?.startDate,
                endDate: catalogItemInfo?.endDate,
                qty: catalogItemInfo?.qty,
                catalogue: catalogItemInfo?.catalogue,
                startPrice: catalogItemInfo?.startPrice,
                supplyPrice: catalogItemInfo?.supplyPrice,
                images: catalogItemInfo?.images
            }}
            validationSchema={Yup.object({
                status: Yup.number()
                    .required('Required'),
                bidType: Yup.number()
                    .oneOf(
                        [0, 1],
                        'Invalid Bid Mode'
                    )
                    .required(),
                donorName: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .required('Required'),
                // catalogue: Yup.boolean()



            })}
            onSubmit={async (values) => {
                // console.log("values are " + JSON.stringify(values))
                try {
                    setOpenModal(true)
                    setLoading(true)
                    const res = await postCatalogItem(dispatch, catalogItemInfo)
                    console.log(res)
                    setLoading(false)
                    if (!res) {
                        setModalMessage('Something went wrong...')
                    }
                    else if (res.response?.data) {
                        setModalMessage(res.response.data)
                    }
                    else {
                        setModalMessage('Auction Item Has Been Created!')
                    }

                } catch (error) {
                    console.log(error)
                }
            }}
        >

            <Form style={{ width: "100%" }} >
                <PageContainer>
                    <Widget title={"Auction Item Creation"} createOption content={
                        <ElementsContainer>
                            <GridContainer>
                                <GridHalf>
                                    <Item>
                                        <TitleInput>Bid Type:</TitleInput>
                                        <FormSelectInput
                                            name="bidType"
                                            onChange={(e) => handleIntChange(e)}
                                            value={catalogItemInfo?.bidType ?? ""}
                                        >
                                            <option value={0} >Auction</option>
                                            <option value={1} >Sealed Bidding</option>
                                        </FormSelectInput>
                                    </Item>
                                    <Item>
                                        <TitleInput>Status:</TitleInput>
                                        <FormSelectInput
                                            name="status"
                                            onChange={(e) => handleIntChange(e)}
                                            value={catalogItemInfo?.status ?? ""}
                                        >
                                            <option value={0} >Draft</option>
                                            <option value={1} >Active</option>
                                        </FormSelectInput>
                                    </Item>
                                    <Item>
                                        <TitleInput>Title:</TitleInput>
                                        <FormTextInput
                                            name="title"
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.title ?? ""}
                                        />
                                    </Item>

                                    <Item>
                                        <TitleInput>Description:</TitleInput>
                                        <FormTextareaInput
                                            name="description"
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.description ?? ""}
                                        />
                                    </Item>
                                    {/*<Item>*/}
                                    {/*    <TitleInput>Category:</TitleInput>*/}
                                    {/*    <FormSelectInput*/}
                                    {/*        name="category"*/}
                                    {/*        onChange={(e) => handleChange(e)}*/}
                                    {/*        value={auctionItemInfo?.category ?? ""}*/}
                                    {/*    >*/}
                                    {/*        <option value='' >Select</option>*/}
                                    {/*        <option value='Golf' >Golf</option>*/}
                                    {/*        <option value='Lux Holiday' >Lux Holiday</option>*/}
                                    {/*        <option value='Memerobilia' >Memerobilia</option>*/}
                                    {/*        <option value='Other' >Other</option>*/}
                                    {/*    </FormSelectInput>*/}
                                    {/*</Item>*/}
                                    <Item>
                                        <TitleInput>Donor Name:</TitleInput>
                                        <FormTextInput
                                            name="donorName"
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.donorName ?? ""}
                                        />
                                    </Item>
                                    
                                </GridHalf>


                                <GridHalf>
                                    
                                    <Item>
                                        <TitleInput>Start Price:</TitleInput>
                                        <FormTextInput
                                            name="startPrice"
                                            type={'number'}
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.startPrice ?? ""}
                                        />
                                    </Item>
                                    <Item>
                                        <TitleInput>Supply Price:</TitleInput>
                                        <FormTextInput
                                            name="supplyPrice"
                                            type={'number'}
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.supplyPrice ?? ""}
                                        />
                                    </Item>
                                    {/*<Item>*/}
                                    {/*    <TitleInput>Catalogue Item:</TitleInput>*/}
                                    {/*    <FormCheckBoxInput*/}
                                    {/*        name="catalogue"*/}
                                    {/*        // type={'checkbox'}*/}
                                    {/*        onChange={(e) => handleChange(e)}*/}
                                    {/*        // onChange={(e) => console.log(e.target.value)}*/}
                                    {/*        value={auctionItemInfo?.catalogue ?? ''}*/}
                                    {/*    />*/}
                                    {/*</Item>*/}
                                    <Item>
                                        <TitleInput>Quantity:</TitleInput>
                                        <FormTextInput
                                            name="qty"
                                            type={'number'}
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.qty ?? ""}
                                        />
                                    </Item>
                                </GridHalf>

                            </GridContainer>
                        </ElementsContainer>
                    } >
                    </Widget>


                    <Widget title={"Item Images"} content={
                        <ElementsContainer>
                            <GridContainer>
                                <GridHalf>
                                    <Item>
                                        <TitleText>Item Image</TitleText>
                                        <UploaderContainer>
                                            <div className='drop-img-zone' {...getRootImagesProps()}>
                                                <input name='homePage' type='file' {...getInputImagesProps()} />
                                                {
                                                    isDragActive ?
                                                        <p>Drop the files here ...</p> :
                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                }
                                            </div>
                                        </UploaderContainer>
                                    </Item>
                                </GridHalf>
                                <GridHalf>
                                    {imagesPreview &&
                                        <Item>
                                            {/* <button type='button' className='btn' onClick={() => handleImageUpload()} >Click To Upload Image</button> */}
                                            <TitleText>{imagesWarning}</TitleText>

                                            {/* <span>{imgWarning}</span> */}
                                            <ImagePreview src={imagesPreview} alt="preview" />
                                        </Item>
                                    }
                                </GridHalf>
                            </GridContainer>
                        </ElementsContainer>
                    } >
                    </Widget>


                    <Widget title={"Exceed"} content={
                        <ElementsContainer>
                            <GridContainer>
                                <GridHalf>
                                    <Item>
                                        <TitleInput>Exceed Item ID:</TitleInput>
                                        <FormTextInput
                                            name="reserve"
                                            onChange={(e) => handleChange(e)}
                                            value={catalogItemInfo?.reserve ?? ""}
                                        />
                                    </Item>
                                </GridHalf>
                            </GridContainer>
                        </ElementsContainer>
                    } >
                    </Widget>

                    <Modal
                        open={openModal}
                        onClose={() => { setOpenModal(false); setModalMessage(''); window.location.reload() }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={boxStyle}>
                            {loading ?
                                <CircularProgress size={80} />
                                :
                                modalMessage &&
                                <>
                                    <ModalText>
                                        {modalMessage}
                                    </ModalText>
                                    <ButtonsContainer style={{ justifyContent: 'center' }} >
                                        <ModalBtn
                                            color='complementary'
                                            onClick={() => { setOpenModal(false); setModalMessage(''); window.location.reload() }}
                                        >Close</ModalBtn>
                                    </ButtonsContainer>
                                </>
                            }
                        </Box>
                    </Modal>

                </PageContainer>
            </Form>
        </Formik >
    );
}

