import styled from 'styled-components';
import { Widget } from "../../components/Widget"
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { getClient } from "../../api/clientIndex";


const GridContainer = styled.div`
    display: grid;
    gap : 1.5rem 3rem;
    grid-template-columns: repeat(2, 1fr);

`
const GridItem = styled.div`
    
`
const Item = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 0.7fr 1fr;
    padding: .7em .6em;
`

const TitleInput = styled.span`
    align-self : center;
    font-size : 1rem;
    color : ${({ theme: { colors } }) => colors.text};
`


const TextValue = styled.div`
    width : 100%;
    box-sizing: border-box;
    /* border : 1px solid ${({ theme: { colors } }) => colors.inputBorder}; */
    border-radius: 0.3em;
    font-size: 1em;
`

export const ViewClient = () => {

    const schema = {
        name: "",
        address: {
            address1: "",
            address2: "",
            city: "",
            county: "",
            postcode: ""
        },
        leadUser: {
            firstName: "",
            lastName: "",
            mobile: "",
            email: "",
            password: "",
            confirmPassword: "",
            userType: 1,
            address: {
                address1: "",
                address2: "",
                city: "",
                county: "",
                postcode: ""
            },

        }
    }

    const [clientInfo, setClientInfo] = useState(schema)
    const location = useLocation().pathname.split('/');
    const clientId = location[2]

    useEffect(() => {
        console.log(clientInfo)
    }, [clientInfo])

    useEffect(() => {
        getClient(clientId).then((data) => setClientInfo(data))

    }, [])

    return (
        <>
            <PageContainer>
                <Widget title={"Client Info"} content={
                    <ElementsContainer>
                        <TextSpan>
                            Below is a list of your Client details.
                        </TextSpan>

                    </ElementsContainer>
                } >
                </Widget>


                <Widget title={"Client Details"} content={
                    <ElementsContainer>
                        <GridContainer>
                            <GridItem>
                                <Item>
                                    <TitleInput>Client Name:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.name ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>
                            <GridItem>
                                <Item>
                                    <TitleInput>Address 1: </TitleInput>
                                    <TextValue>
                                        {clientInfo?.address.address1 ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>
                            <GridItem>
                                <Item>
                                    <TitleInput>Address 2: </TitleInput>
                                    <TextValue>
                                        {clientInfo?.address.address2 ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>
                            <GridItem>
                                <Item>
                                    <TitleInput>County:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.address.county ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>City:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.address.city ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>Postcode:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.address.postcode ?? ''}
                                    </TextValue>

                                </Item>
                            </GridItem>
                        </GridContainer>
                    </ElementsContainer>
                } >
                </Widget>

                {/* LEAD USER DETAILS */}
                <Widget title={"Lead User Details"} content={
                    <ElementsContainer>
                        <GridContainer>
                            <GridItem>
                                <Item>
                                    <TitleInput>First Name:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.firstName ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>
                            <GridItem>
                                <Item>
                                    <TitleInput>Last Name:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.lastName ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>
                            <GridItem>
                                <Item>
                                    <TitleInput>Email:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.email ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>
                            <GridItem>
                                <Item>
                                    <TitleInput>Mobile:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.mobile ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>Address 1:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.address?.address1 ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>Address 2:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.address?.address2 ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>County:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.address?.county ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>City:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.address?.city ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                            <GridItem>
                                <Item>
                                    <TitleInput>Postcode:</TitleInput>
                                    <TextValue>
                                        {clientInfo?.leadUser.address?.postcode ?? ''}
                                    </TextValue>
                                </Item>
                            </GridItem>

                        </GridContainer>
                    </ElementsContainer>
                } >
                </Widget>
            </PageContainer>
        </>
    );
}

