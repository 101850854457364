import styled from 'styled-components';
import { PageContainer, ElementsContainer, TextSpan } from "../../styles/ThemeStyles";
import { BidsFeedGrid } from "../../components/BidsFeedGrid";
import { useState } from "react";


const WidgetContainer = styled.div`
    width :${(props) => props.widgetType === "landingPage" ? "95%" : "90%"};
    padding: 0 2rem;
    background : white;
    box-sizing: border-box;
    border-radius : 1em;
    margin-bottom : 3rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`

const WidgetTitleCont = styled.div`
    color : ${({ theme: { colors } }) => colors.primary} ;
    /* background-color: #755c4e; */
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    font-size : 2rem;
    font-weight : 800;
    border-bottom : 2px solid lightgrey;
`

export const BidsFeed = () => {

    const [filter, setFilter] = useState("2")

    return (
        <PageContainer>
            <WidgetContainer >
                <WidgetTitleCont>
                    Bids Feed.
                </WidgetTitleCont>
                <ElementsContainer>
                    <TextSpan>
                        Here you can view all the bids that were placed during the event.
                    </TextSpan>
                </ElementsContainer>
            </WidgetContainer>

            <WidgetContainer >
                <WidgetTitleCont>
                    Bids Feed
                </WidgetTitleCont>
                <ElementsContainer>
                    <span>Filter Feed</span>
                    <select
                        onChange={(e) =>
                            setFilter(e.target.value)
                            // console.log(e.target.value)
                        }
                        value={filter ?? ''}
                    >
                        <option value={'1'} >Highest Bids</option>
                        <option value={'2'} >Most Recent</option>
                    </select>

                    <BidsFeedGrid filter={filter} />

                </ElementsContainer>
            </WidgetContainer>


            <WidgetContainer >
                <WidgetTitleCont>
                    Most Bids
                </WidgetTitleCont>
                <ElementsContainer>
                    <BidsFeedGrid filter={"0"} />
                </ElementsContainer>
            </WidgetContainer>


        </PageContainer >
    );
}

