import { Box, CircularProgress, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { deleteAuctionItem, getAdminAuctionItems } from '../api/auctionItemsIndex';
import { boxStyle, ButtonsContainer, ModalBtn, ModalText, theme } from "../styles/ThemeStyles";



const EditBtn = styled.button`
    border : none;
    font-size : 1em;
    padding : .6em 2em;
    cursor : pointer;
    border-radius : .5em;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`
const DeleteBtn = styled.button`
    border : none;
    font-size : 1em;
    padding : .6em 1.5em;
    border-radius : .5em;
    box-sizing : border-box;
    color : white;
    cursor : pointer;
    background : ${({ theme: { colors } }) => colors.delete};
    &:hover {
      background : ${({ theme: { colors } }) => colors.deleteLight};
    }
`




export const AuctionItemsGrid = () => {

  const columns = [
    {
      field: '_id',
      headerName: '#',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 4,
      editable: false,
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 1,
      editable: false,
    },
    {
      field: 'startPrice',
      headerName: 'Start Price',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },
    {
      field: 'supplyPrice',
      headerName: 'Supply Price',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },

    {
      field: 'bidType',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      // sortable: true,
      editable: false,
    },

    {
      field: 'edit',
      headerName: 'Edit',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (param) => {
        const currentURL = window.location.pathname
        return (
          <Link to={`${currentURL}/${param.row._id}`} >
            <EditBtn >Edit</EditBtn>
          </Link>
        )
      }

    },
    {
      field: 'delete',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (param) => {

        return (
          <DeleteBtn
            onClick={() => { setItemToDelete(param.row._id); setOpenModal(true) }}
          >Delete</DeleteBtn>
        )
      }

    },
  ];





  const [rows, setRows] = useState([]);

  const [auctionItems, setAuctionItems] = useState([]);
  const location = useLocation().pathname.split('/')
  const currentEventId = location[2]

  const [itemToDelete, setItemToDelete] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [warning, setWarning] = useState('')

  const eventId = 10;

  useEffect(() => {

    getAdminAuctionItems(currentEventId).then((data) => setAuctionItems(data))

  }, [])

  const handleItemDelete = (itemId) => {

    setLoading(true)

    deleteAuctionItem(itemToDelete).then((data) => {
      if (data.response?.data) {
        setModalMessage('')
        setWarning(data.response.data)
      }
      else {
        setModalMessage('Item has been deleted!')
      }
      setLoading(false)
    }
    )

  }


  const schema = {
    id: 0,
    eventId: 'location[2]',
    title: "",
    description: "",
    donorName: "",
    status: 0,
    startDate: 'date',
    endDate: 'fullDate',
    startPrice: 0,
    qty: 0,
    supplyPrice: 0,
    catalogue: false,
    bidType: 0,
    images: []
  }

  useEffect(() => {
    if (auctionItems) {
      setRows(
        auctionItems?.map((el) => ({
          _id: el.id,
          title: el.title,
          qty: el.qty,
          startPrice: `£${el.startPrice}`,
          supplyPrice: `£${el.supplyPrice}`,
          bidType: el.bidType === 0 ? 'Auction' : 'Sealed',
          catalogue: el.catalogue ? 'Yes' : 'No',
          status: el.status === 0 ? 'Draft' : 'Active',
        }))
      )
    }
  }, [auctionItems])

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        currentEventId={currentEventId}
        rows={rows}
        getRowId={(row) => row?._id}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnCliclk={true}
        disableColumnMenu
        sx={{
          color: theme.colors.text,
          border: 'none',


          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'transparent',
          },

          '& .MuiDataGrid-columnHeader': {

          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '700',
          },
        }}
      />


      <Modal
        open={openModal}
        onClose={() => { setOpenModal(false); window.location.reload() }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          {loading ?
            <CircularProgress size={80} />
            :
            modalMessage ?
              <>
                <ModalText>
                  {modalMessage}
                </ModalText>
                <ButtonsContainer style={{ justifyContent: 'center' }} >
                  <ModalBtn
                    color='complementary'
                    onClick={() => { setOpenModal(false); setModalMessage(''); window.location.reload() }}
                  >Close</ModalBtn>
                </ButtonsContainer>
              </>

              :
              <>
                <ModalText>
                  Are you sure you want to delete this item?
                </ModalText>
                <ButtonsContainer>
                  <ModalBtn
                    onClick={() => handleItemDelete()}
                  >Yes</ModalBtn>
                  <ModalBtn
                    color='complementary'
                    onClick={() => { setOpenModal(false); setModalMessage('') }}
                  >No</ModalBtn>
                </ButtonsContainer>
              </>
          }
        </Box>
      </Modal>



    </div>
  )
}
