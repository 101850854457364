import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


const postTakePayment = async (bidId, key) => {
    const state = store.getState();

    try {
        const res = await axios.post(`${apiURL}/api/stripe/purchase/${bidId}/${key}`,null ,{

            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        })
        console.log(res.data)
        return res.data

    } catch (err) {
        console.log(err)
        return err
    }
}
const postSendPaymentLink = async (bidId) => {
    const state = store.getState();

    try {
        const res = await axios.post(`${apiURL}/api/stripe/purchaseLink/${bidId}`, null, {

            headers: { authorization: `Bearer ${state.user.currentUser.token}` }
        })
        console.log(res.data)
        return res.data

    } catch (err) {
        console.log(err)
        return err
    }
}

export { postTakePayment, postSendPaymentLink };