// import Swiper core and required modules
import { Autoplay, Pagination, Navigation, Parallax, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { getWebConfig } from '../api/webConfigIndex';
import { getAuctionItems } from "../api/auctionItemsIndex";
import { getEventBySlug } from "../api/eventsIndex";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/virtual';
import '../styles/CustomSwiper.css'

const Container = styled.div`
    
`

const DescItem = styled.p`
    font-size : 0.9rem;
    font-weight: 500;
    margin-top: 0.4rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

`
export const AuctionItemsPreview = () => {
    
    const location = useLocation().pathname.split('/');
    const slug = location[2]
    const [auctionItems, setAuctionItems] = useState('');
    const [event, setEvent] = useState('');
    useEffect(() => {
        getEventBySlug(slug).then((data) => {
            setEvent(data);
            getAuctionItems(data.id).then((data) => { setAuctionItems(data); })
        })
        
        

    }, [])

    return (
        <div className="carousel-slider">
            <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                grabCursor={true}
                watchSlidesProgress={true}
                loop={true}
                loopedSlides={5}
                slidesPerView='auto'
                centeredSlides={true}
                
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                pagination={{
                    el: '.swiper-pagination',
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                // eslint-disable-next-line
                onSetTransition={(swiper, duration) => {
                    for (let i = 0; i < swiper.slides.length; i += 1) {
                        const slideEl = swiper.slides[i];
                        const opacityEls = slideEl.querySelectorAll(
                            '.carousel-slider-animate-opacity',
                        );
                        slideEl.style.transitionDuration = `${duration}ms`;
                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.transitionDuration = `${duration}ms`;
                        });
                    }
                }}
                onProgress={(swiper, progress) => {
                    // eslint-disable-next-line
                    const scaleStep = 0.2;
                    const zIndexMax = swiper.slides.length;
                    for (let i = 0; i < swiper.slides.length; i += 1) {
                        const slideEl = swiper.slides[i];
                        const slideProgress = swiper.slides[i].progress;
                        const absProgress = Math.abs(slideProgress);
                        let modify = 1;
                        if (absProgress > 1) {
                            modify = (absProgress - 1) * 0.3 + 1;
                        }
                        const opacityEls = slideEl.querySelectorAll(
                            '.carousel-slider-animate-opacity',
                        );
                        const translate = `${slideProgress * modify * 50}%`;
                        const scale = 1 - absProgress * scaleStep;
                        const zIndex = zIndexMax - Math.abs(Math.round(slideProgress));
                        slideEl.style.transform = `translateX(${translate}) scale(${scale})`;
                        slideEl.style.zIndex = zIndex;
                        if (absProgress > 3) {
                            slideEl.style.opacity = 0;
                        } else {
                            slideEl.style.opacity = 1;
                        }

                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.opacity = 1 - absProgress / 3;
                        });
                    }
                }}
                
                
                
                className="triple-slider-main"
            >
                {auctionItems ? auctionItems?.map((el) => (
                    <SwiperSlide key={el.id}>
                        <div className="carousel-slider-animate-opacity">
                            {el.images.length > 0 ? el.images?.map((c) => (

                                <img src={c.url} key={c.id} />
                            )) :
                                <img src="" />
                            }
                            <div className="slide-content">
                                <h2>{el.title}</h2>
                                <DescItem>{el.description}</DescItem>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                )) :
                    <CircularProgress size={80} />

                }
               
            </Swiper>

            <h1>{event.name}</h1>
            <p>Slient Auction</p>
        </div>
    );
};