import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


// USERS

const getUsers = async () => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Users`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) { console.log(err) }
}

const getUser = async (userId) => {
  const state = store.getState();

  try {
    const res = await axios.get(`${apiURL}/api/Users/${userId}`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) { console.log(err) }
}
const getUserDetails = async () => {
    const state = store.getState();

    try {
        const res = await axios.get(`${apiURL}/api/Users`,
            {
                headers: { authorization: `Bearer ${state.user.currentUser.token}` }
            })
        return res.data

    } catch (err) { console.log(err) }
}

const updateUser = async () => {
  const state = store.getState();

  try {
    const res = await axios.put(`${apiURL}/api/Users`,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    return res.data

  } catch (err) { console.log(err) }
}




export { getUser, getUsers, updateUser, getUserDetails };