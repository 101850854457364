import { Box, CircularProgress, Modal } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import * as Yup from 'yup';
import { getSetupIntent, register } from '../api/authIndex';
import { placeCustomBid } from '../api/bidIndex';
import { mobile, smDesktop, tablet } from '../styles/responsive';
import { boxStyle, ButtonsContainer, ModalBtn, ModalText } from "../styles/ThemeStyles";
import { AuctionItemsGridSelection } from './AuctionItemsGridSelection';

const GridContainer = styled.div`
    width : 100%;
    display: grid;
    gap : 2rem;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => mobile({
  gridTemplateColumns: '1fr',
})};

${(props) => tablet({
  gridTemplateColumns: '1fr',
})};
${(props) => smDesktop({
  gridTemplateColumns: '1fr 1fr',
})};

`
const GridItem = styled.div`
    /* background-color: #7e6363; */
    display: grid;
    gap : 1.5rem;
    grid-template-columns: 1fr;
`
const Item = styled.div`
    
`

const TextInputContainer = styled.div`
    /* background: green; */
`
const TextInputError = styled.div`
    color : red;
`

const TextInput = styled.input`
    width : 100%;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.inputBorder};
    border-radius: 0.3em;
    font-size: 1em;

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`


const RegisterBtn = styled.button`
    margin-top : 2rem;
    border : none;
    width: 100%;
    font-size : 1rem;
    font-weight: 800;
    padding : 1rem 2rem;
    cursor : pointer;
    border-radius : 2em;
    box-sizing : border-box;
    background : ${({ theme: { colors } }) => colors.complementary};
    color : white;
    &:hover {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
    &:disabled {
      background : ${({ theme: { colors } }) => colors.complementaryLight};
    }
`
const WarningText = styled.div`
    color : red;
`
const PlaceBidContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`


const BidInput = styled.input`
    width : 100%;
    text-align: center;
    box-sizing: border-box;
    padding: .7em .6em;
    border : 1px solid ${({ theme: { colors } }) => colors.bg};
    border-radius: 0.3em;
    font-weight: 800;
    font-size: 1.3rem;
    background-color: ${({ theme: { colors } }) => colors.bg};
    color: ${({ theme: { colors } }) => colors.darkText};

    &:focus {
        border : 1px solid white;
        outline: 2px solid ${({ theme: { colors } }) => colors.inputFocusOutline} !important;
    }
`
const UnderlinedSectionTitle = styled.div`
    font-size: 1.2rem;
    box-sizing: border-box;
    padding :${(props) => props.topTitle ? '0 0 1.5rem 0' : '3rem 0 1.5rem 0'} ;
    font-weight: 800;
    color : ${({ theme: { colors } }) => colors.text};
    border-bottom : 2px solid lightgrey;
    margin-bottom: 2rem;

`

//FORM INPUTS

const FormTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextInputContainer>
      <label htmlFor={props.id || props.name}>{label}</label>
      <TextInput {...field} {...props} />
      {meta.touched && meta.error ? (
        <TextInputError >{meta.error}</TextInputError>
      ) : null}

    </TextInputContainer>
  );
};
const FormMobileInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextInputContainer>
      <label htmlFor={props.id || props.name}>{label}</label>
      <PhoneInput
        {...field} {...props} />
      {meta.touched && meta.error ? (
        <TextInputError >{meta.error}</TextInputError>
      ) : null}

    </TextInputContainer>
  );
};

export const CustomBidder = () => {


  const [auctionItem, setAuctionitem] = useState("")

  const [mobile, setMobile] = useState("")
  const location = useLocation().pathname.split('/');
  const [isRedirecting, setIsRedirecting] = useState(false)
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [warning, setWarning] = useState('')
  const currentEventId = location[2]
  const slug = location[3]

  const customBidSchema = {
    user: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      // password: "",
      userType: 0,
      address: {
        address1: "",
        address2: "",
        city: "",
        county: "",
        postcode: ""
      },
      // stripeId: "",
      clientId: 0,
      custom: true
    },
    bid: {
      userId: 0,
      bidderName: "",
      auctionItemId: 0,
      auctionItemTitle: "",
      eventsTitle: "",
      amount: 0,
      anonymous: true,
      bidType: 0,
      bidStatus: 0,
      tableNumber: 0,
      // stripeId: ""
    }
  }




  const [customBidInfo, setCustomBidInfo] = useState(customBidSchema)

  const handleUserChange = (e) => {

    setCustomBidInfo((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        [e.target.name]: e.target.value
      }
    }))
    console.log(customBidInfo)
  }

  const handleUserAddressChange = (e) => {

    setCustomBidInfo((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        address: {
          ...prev.user.address,
          [e.target.name]: e.target.value
        }
      }
    }))
    console.log(customBidInfo)
  }

  const handleBidChange = (e) => {
    setCustomBidInfo((prev) => ({
      ...prev,
      bid: {
        ...prev.bid,
        [e.target.name]: Number(e.target.value)
      }
    }))
  }

  useEffect(() => {
    console.log(customBidInfo)
  }, [customBidInfo])




  const elements = useElements();
  const stripe = useStripe();

  const handleBidPlacement = async (e) => {
    // e.preventDefault();
    setDisableBtn(true)

    if (!stripe || !elements) {
      return;
    }
    const client_secret = await getSetupIntent();
    await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement)

      },
    })
      .then(function (result) {
        // Handle result.error or result.setupIntent
        if (result.error) {

        }
        if (result.setupIntent) {
          // Make a copy and mutate the value that you need
          const userCopy = {
            ...customBidInfo.user,
            stripeId: result.setupIntent.id
          }
          register(userCopy).then((data) => {
            if (data.response) {
              setWarning(data.response.data)
            }
            else {
              setIsRedirecting(true)
              setTimeout(() => {
                navigate('/login', {
                  state: {
                    homeURL: `/event-landing-page/${currentEventId}/${slug}`
                  }
                })
              }, 2000);
            }
            // console.log(JSON.stringify(data))
          })
        }
        setDisableBtn(false)
      });
  }
  const handleModalClose = () => {
    setOpenModal(false)
    setModalMessage('')
    // window.location.reload(false)
  }

  const handleCustomBidPlacement = async () => {
    setLoading(true)
    const res = await placeCustomBid(customBidInfo)
    if (res?.response?.data) {
      setModalMessage(res?.response?.data)
    }
    else {
      console.log(res)
      setModalMessage('Custom Bid Has Been Placed!')
    }
    setLoading(false)

  }


  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: customBidInfo?.user?.firstName,
        lastName: customBidInfo?.user?.lastName,
        email: customBidInfo?.user?.email,
        mobile: customBidInfo?.user?.mobile,
        address1: customBidInfo?.user?.address?.address1,
        address2: customBidInfo?.user?.address?.address2,
        city: customBidInfo?.user?.address?.city,
        county: customBidInfo?.user?.address?.county,
        postcode: customBidInfo?.user?.address?.postcode,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        lastName: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        address1: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        city: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        county: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        postcode: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        email: Yup.string()
          .email()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        mobile: Yup.string()
          // .max(11, 'Must be 10 characters or less')
          .required('Required'),
        password: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        confirmPassword: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .oneOf([Yup.ref('password')], 'Passwords do not match')
          .required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        console.log("values are " + values)
        // if (disableBtn) {
        //   return
        // }
        // else {

        //   handleBidPlacement()
        // }
      }}
    >

      <Form style={{ width: "100%" }} >
        <UnderlinedSectionTitle
          topTitle={true}
        >Bidder Details</UnderlinedSectionTitle>

        <GridContainer>
          <GridItem>
            <Item>
              <FormTextInput
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={(e) => handleUserChange(e)}
                value={customBidInfo?.user?.firstName ?? ""}
              />
            </Item>
          </GridItem>
          <GridItem>
            <Item>
              <FormTextInput
                placeholder="Last Name"
                name="lastName"
                onChange={(e) => handleUserChange(e)}
                value={customBidInfo?.user?.lastName ?? ""} />
            </Item>
          </GridItem>
          <GridItem>
            <Item>
              <FormTextInput
                placeholder="Email"
                name="email"
                onChange={(e) => handleUserChange(e)}
                value={customBidInfo?.user?.email ?? ""} />
            </Item>
          </GridItem>
          <GridItem>
            <Item>
              <FormTextInput
                placeholder="Mobile"
                name="mobile"
                onChange={(e) => handleUserChange(e)}
                value={customBidInfo?.user?.mobile ?? ""} />
            </Item>
          </GridItem>
          <GridItem>
            <Item>
              <FormTextInput
                placeholder="Address Line 1"
                name="address1"
                onChange={(e) => handleUserAddressChange(e)}
                value={customBidInfo?.user?.address?.address1 ?? ""} />
            </Item>
          </GridItem>
          <GridItem>
            <Item>
              <FormTextInput
                placeholder="City"
                name="city"
                onChange={(e) => handleUserAddressChange(e)}
                value={customBidInfo?.user?.address?.city ?? ""} />
            </Item>

          </GridItem>
          <GridItem >
            <Item>
              <FormTextInput
                placeholder="Address Line 2"
                name="address2"
                onChange={(e) => handleUserAddressChange(e)}
                value={customBidInfo?.user?.address?.address2 ?? ""} />
            </Item>
          </GridItem>

          <GridItem style={{ gridTemplateColumns: "1fr 1fr" }}>
            <Item>
              <FormTextInput
                placeholder="County"
                name="county"
                onChange={(e) => handleUserAddressChange(e)}
                value={customBidInfo?.user?.address?.county ?? ""} />
            </Item>
            <Item>
              <FormTextInput
                placeholder="Post Code"
                name="postcode"
                onChange={(e) => handleUserAddressChange(e)}
                value={customBidInfo?.user?.address?.postcode ?? ""} />
            </Item>
          </GridItem>

        </GridContainer>
        <UnderlinedSectionTitle>Select Item</UnderlinedSectionTitle>

        <AuctionItemsGridSelection customBidInfo={customBidInfo} setCustomBidInfo={setCustomBidInfo} setAuctionitem={setAuctionitem} />

        {
          customBidInfo.bid.auctionItemId ?
            <>
              <UnderlinedSectionTitle>Bid Amount</UnderlinedSectionTitle>

              <PlaceBidContainer>
                <BidInput
                  type="number"
                  name='amount'
                  min={0}
                  placeholder={`£${auctionItem?.startPrice}`}
                  onChange={handleBidChange}
                />

                <WarningText>{warning}</WarningText>
                <RegisterBtn
                  type='button'
                  onClick={() => setOpenModal(true)}
                >Place Bid</RegisterBtn>
              </PlaceBidContainer>
            </>
            :
            <UnderlinedSectionTitle>Select An Item To Bid</UnderlinedSectionTitle>
        }

        <Modal
          open={openModal}
          onClose={() => handleModalClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            {loading ?
              <CircularProgress size={80} />
              :
              modalMessage ?
                <>
                  <ModalText>
                    {modalMessage}
                  </ModalText>
                  <ButtonsContainer
                    style={{ justifyContent: 'center' }}
                  >
                    <ModalBtn
                      color='complementary'
                      onClick={() => handleModalClose()}
                    >Close</ModalBtn>
                  </ButtonsContainer>

                </>
                :
                <>
                  <ModalText>
                    Are you sure you want to place this custom bid?
                  </ModalText>
                  <ButtonsContainer>
                    <ModalBtn
                      type='button'
                      onClick={() => handleCustomBidPlacement()}
                    >Yes</ModalBtn>
                    <ModalBtn
                      color='complementary'
                      onClick={() => setOpenModal(false)}
                    >No</ModalBtn>
                  </ButtonsContainer>
                </>
            }
          </Box>
        </Modal>

      </Form>
    </Formik >
  );
}

