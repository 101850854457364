import axios from 'axios';
import { apiURL } from '.';
import { store } from '../redux/store';


// WEB CONFIG

const getWebConfig = async (eventId) => {

  try {
    const res = await axios.get(`${apiURL}/api/Events/WebsiteConfig/${eventId}`,
    )
    return res.data

  } catch (err) { console.log(err) }
}

const updateWebConfig = async (webConfigId, webConfigInfo) => {
  const state = store.getState();


  try {
    const res = await axios.put(`${apiURL}/api/Events/WebsiteConfig/${webConfigId}`, webConfigInfo,
      {
        headers: { authorization: `Bearer ${state.user.currentUser.token}` }
      })
    console.log(res.data)
    return res.data

  } catch (err) { console.log(err) }
}


export { getWebConfig, updateWebConfig };
